(function () {
  'use strict';
  // acts as a mock container until the real one is ready.
  module.exports = {
    getDataQueue: [],
    processEventQueue: [],
    getData: function (msg) {
      this.getDataQueue.push(msg);
    },
    processEvent: function (msg) {
      this.processEventQueue.push(msg);
    }
  };
})();