(function () {
  'use strict';
  var logger = require('./UtilLogger');
  var _JET = require('./JETCore');
  var dfd = require('./shared/deferred');
  var router = require('./router');
  var mixin = require('./shared/mixin');

  var _userinfo = {
        /**
         * An object that contains user information
         * @typedef {object} JET~UserInfo
         * @property {string} FullUserName - e.g. "Peter Parker"
         * @property {string} JobRole - e.g. "DEV"
         * @property {string} AccountName - e.g. "Thomson Reuters"
         * @property {string} Address - e.g. "Floor 21,23,29,30,35 21,23,29,30,35 Fl. U Chu Liang Bluilding, 968 Rama IV Road, Silom, Bangrak"
         * @property {string} City - e.g. "Bangkok"
         * @property {string} Country - e.g. "Thailand"
         * @property {string} Email - e.g. "peter.parker@thomsonreuters.com"
         * @property {string} PreferredLanguage - e.g. "en-US"
         * @property {string} LocationID - e.g. "SL1+JPBX+36499"
         * @property {string} UUID - e.g. "SL1-12A3BC4"
         */

        /**
         * The getUserInfo function returns all the "User Details" section found in Eikon’s About box, using a
         * promise.
         * @function getUserInfo
         * @memberof JET
         * @return {Promise} resolves with User's Information or error message.
         *
         * @example
         * var userInfo;
         * JET.getUserInfo().then(function (data) {
                 *  userInfo = data;
                 * }, function (error) {
                 *  console.log('could not get user info!');
                 * });
         */
        getUserInfo: function () {
          logger.debug('JET.getUserInfo called.');

          return dfd(
            function () {
              return router.getContainerData({
                name: 'UserInfo'
              });
            },
            function (data) {
              return data;
            },
            function (data) {
              console.log('could not get userinfo');
            }
          );
        }
    };
    mixin(_JET, _userinfo);
})();