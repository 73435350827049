(function () {
  'use strict';

  require('./3rdparty/EikonNow-1.3.4');
  window.EikonNow.init({ignoreSecondInit: true});
  require('jet-app-containershim/src/main');

  require('./Util');
  var JET = require('./JETCore');
  require('./JETPlugins');
  require('./JETPubSub');
  require('./FullAPI');
  require('./FullAPIViewRequests');
  require('./FullAPIArchive');
  require('./FullAPILogger');
  require('./FullAPIDragAndDrop');
  require('./UserInfo');
  require('./Window');

  // call hasJET, lets app know that that JET is ready (all plugins loaded)
  if (window._hasJET) {
    window._hasJET();
  }

  module.exports = JET;
})();