(function() {
  'use strict';
// stores bound events
  var _vents = [];
  var _loadHooks = [];
  var logger = require('./UtilLogger');
  var mixin = require('./shared/mixin');
  var router = require('./router');
  var properties = require('./JETProperties');
  var boundObjects = require('./shared/boundObjects');
  var toolbarStub = require('./stubs/commandBar');

  var api = {
    addEvent: function (source, key, func) {
      _vents.push({
        source: source,
        key: key,
        func: func
      });

      if (source.addEventListener) source.addEventListener(key, func);
      if (source.attachEvent) source.attachEvent('on' + key, func);

      return func;
    },

    _doRemoveEvent: function (source, key, func) {
      if (source.removeEventListener) source.removeEventListener(key, func);
      if (source.detachEvent) source.detachEvent('on' + key, func);
    },

    removeEvent: function (source, key, func) {
      var _found = false;
      for (var i = 0; i < _vents.length; i++) {
        var _entry = _vents[i];
        if (_entry.source === source && _entry.key === key && _entry.func === func) {
          _vents.splice(i, 1);
          _found = true;
          break;
        }
      }
      if (_found === false) {
        throw 'Unsubscribing from an unknown event!';
      }

      this._doRemoveEvent(source, key, func);
    },

    unsubscribeAll: function () {
      for (var i = 0; i < _vents.length; i++) {
        var _event = _vents[i];
        this._doRemoveEvent(_event.source, _event.key, _event.func);
      }
      _vents.length = 0;
    },

    stopBubbling: function () {
      var event;
      //??? do we need to do this...
      if (window.event) {
        event = window.event;
      } else if (window.frames[0] && window.frames[0].event) {
        // When running on AppServer, we have to get the event object in the child frame
        event = window.frames[0].event;
      }

      if (event) {
        if (event.preventDefault) {
          event.preventDefault();
          event.stopPropagation();
        } else { // IE8-
          event.cancelBubble = true;
          event.returnValue = false;
        }
      }
    },

    addOnUnload: function (func) {
      this.addEvent(window, 'unload', function () {
        func.apply(window, arguments);
      });
    },

    addHandlers: function (events, api) {
      //add in container events...
      events.forEach(function (e) {
        api[e] = function (handler) {
          return router.addEventHandler(e, handler, true);
        };
      });
    },

    //get description - used in onLoad event
    getComponentDescription: function (jet) {
      var data = {
        properties: this.properties(),
        entities: jet.Context || []
      };

      if (this.commandline()) {
        data.toolbar = toolbarStub;
      }

      // tell the ACS to update its dataProvider description
      boundObjects.description = data;
      return data;
    },


    // properties for util
    properties: function (props) {
      if (!props) {
        return boundObjects.properties;
      }
    },

    commandline: function (cmd) {
      if (!arguments.length) {
        return properties.commandline;
      }
    },

    apptitlebar: function (cmd) {
      if (!arguments.length) {
        return properties.apptitlebar;
      }
    },

    appmenu: function (appm) {
      if (!appm) {
        return properties.appmenu;
      }
    },

    addInternalLoadHook: function(hook) {
      _loadHooks.push(hook);
    },

    getInternalLoadHooks: function() {
      return _loadHooks;
    }
  };

  module.exports = api;
  mixin(api, logger);
})();