(function () {
  'use strict';

  // objects that are bound to JET Apps.
  // if the object changes on the app will emit an event and this object will reflect the change

  // todo some of this values will be called by the container they will error out if they are undefined
  // need to rearchitect
  var cache = {
    properties: {},
    persistdata: []
  };
  var dataProviders = {};

  function getter(v) {
    return function () {
      return function () {
        return cache[v];
      };
    };
  }

  function setter(key) {
    return function (value) {
      cache[key] = value;
    };
  }

// todo refactor the function returning functions into something more generic
  Object.defineProperties(dataProviders, {
    helpurl: {
      get: getter('helpurl'),
      set: setter('helpurl')
    },
    context: {
      get: getter('context'),
      set: setter('context')
    },
    properties: {
      get: getter('properties'),
      set: setter('properties')
    },
    description: {
      get: getter('description'),
      set: setter('description')
    },
    persistdata: {
      get: getter('persistdata'),
      set: setter('persistdata')
    },
    inputowners: {
      get: getter('inputowners'),
      set: setter('inputowners')
    }
  });


  module.exports = function () {
    return {
      dataProviders: dataProviders
    };
  };
})();