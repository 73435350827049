(function () {
  'use strict';
  var dfd = require('./shared/deferred');
  var _router = require('./router');
  var logger = require('./UtilLogger');
  var mixin = require('./shared/mixin');
  var JET = require('./JETCore');

  // could move to a module but this is only used here
  function isString(it) {
    return (typeof it == "string" || it instanceof String); // Boolean
  }

  function getDataSync(name) {
    return _router.getContainerData({
      name: name,
      xmlData: ''
    });
  }


  // helper function that gets data and returns a dfd resolve with the data
  function getDataSyncDeferred(name) {
    return dfd(function () {
      return getDataSync(name);
    }, function (data) {
      return data;
    });
  }

  function extractActiveEntity(entities) {
    if (!entities || entities.length === undefined || entities.length <= 0) {
      return;
    }

    if (entities.length === 1 && isString(entities[0].RIC)) {
      return entities[0];
    } else {
      var firstRicEntity;
      for (var k in entities) {
        var entity = entities[k];
        if (isString(entity.RIC)) {
          if (firstRicEntity === undefined) {
            firstRicEntity = entity;
          }
          if (entity.hasOwnProperty('NavigationNS')) {
            return entity;
          }
        }
      }
      return firstRicEntity;
    }
  }

  var api = {
    // get properties from container by sending sync request
    /**
     * @private
     */
    getProperties: function () {
      logger.debug('JET.getProperties called.');
      return getDataSyncDeferred('description');
    },
    /**
     * @private
     */
    // get persist data from container by sending sync request
    getSavedState: function () {
      logger.debug('JET.getSavedState called.');
      return getDataSyncDeferred('persistdata');
    },
    /**
     * @private
     */
    // get paste data from container by sending sync request
    getPasteData: function () {
      logger.debug('JET.getPasteData called.');
      return getDataSyncDeferred('getPasteData');
    },
    /**
     * @private
     */
    // get a sing from container by sending sync request that the current view is active
    isActive: function () {
      logger.debug('JET.isActive called.');
      return getDataSyncDeferred('isActive');
    },

    // get a sing from container by sending sync request that the current view has focus
    /**
     * @private
     */
    hasFocus: function () {
      logger.debug('JET.hasFocus called.');
      return getDataSyncDeferred('hasFocus');
    },

    /**
     * Get container's active symbol. An active symbol is the current selection of a financial entity in active context.
     * @function getActiveSymbol
     * @memberof JET
     * @return {Object} return Active symbol.
     *
     * @see getActiveContext
     *
     * @example
     * var currentSymbol = JET.getActiveSymbol();
     * var ric = currentSymbol.RIC;
     */
    getActiveSymbol: function () {
      logger.debug('JET.getActiveSymbol called.');
      return dfd(this.getActiveContext, extractActiveEntity, function (data) {
        console.log('could not retrieve ActiveSymbol');
      });
    },


    /**
     * Get container's active context.
     * In its simplest, and most common, form the context describes a single financial entity i.e. a company, a stock, a bond, etc.
     * that is currently a primary focus of the container. Some component can provide a list of entities.
     * @function getActiveContext
     * @memberof JET
     * @return {Array.<Object>} return Active context, an array of `Entity` defined in this [schema](https://sami.cdt.int.thomsonreuters.com/svn/jsfw_ccf/trunk/Docs/schema/XSD/ContextData.xsd).
     *
     * @see {@link getActiveSymbol}
     *
     * @example
     * var currentContext = JET.getActiveContext();
     */
    getActiveContext: function () {
      logger.debug('JET.getActiveContext called.');
      return getDataSyncDeferred('ActiveSymbol');
    },

    /**
     * @function getClipboardData
     * @memberof JET
     * @return {string} return Clipboard data
     *
     * @example
     * // Data will be a string in this format
     * // &lt;Entries&gt;
     * //  &lt;Entry&gt;
     * //   &lt;Name&gt;UNICODE TEXT&lt;/Name&gt;
     * //   &lt;Value&gt;JET~ClipboardEntry&lt;/Value&gt;
     * //  &lt;/Entry&gt;
     * //  &lt;Entry&gt;
     * //   &lt;Name&gt;TEXT&lt;/Name&gt;
     * //   &lt;Value&gt;JET~ClipboardEntry&lt;/Value&gt;
     * //  &lt;/Entry&gt;
     * // &lt;/Entries&gt;
     *
     * var data = JET.getClipboardData();
     */
    getClipboardData: function () {
      logger.debug('JET.getClipboardData called.');
      return getDataSyncDeferred('ClipboardData');
    }
  };

  mixin(JET, api);
})();