(function () {
  'use strict';
  /*
   Logs to console or Eikon depending on the environment.
   Be sure to set the right configuration to see the logs
   in your Eikon Environment:
   Start > Thomson Reuters > Thomson Reuters Eikon Tools > Configuration Manager
   Set the Trace Level to 4.

   Exports a function, which takes in the ACS as a parameter.
   This will attach logging functionality to the ACS.
  */

  var _level = 2;
  var _levels = [
    'trace',
    'debug',
    'info',
    'warn',
    'error'
  ];
// levels available when using Eikon Desktop
  var _logSource = '';
  var _desktopLevels = [
    'debug',
    'information',
    'warning',
    'high',
    'critical'
  ];

  function setup(acs, isDesktop) {
    // binds log methods to ACS
    acs.setLogOptions = function (level) {
      _level = level === void 0 || level === null ? 10 : level;

      _levels.forEach(function (level, index) {
        acs[level] = setLog(index);
      });
    };

    acs.setLogOptions(_level);

    if (isDesktop) {

      acs.log = function (msg) {
        acs._processEvent(window, {
          name: 'onLog',
          data: msg
        });
      };

      _desktopLevels.forEach(function (name) {
        acs[name] = function (message) {
          // the log function is injected by api later in this file
          acs.log({
            messages: [{
              severity: name,
              text: message,
              source: _logSource
            }]
          });
        };
      });

      acs.loggingSource = function (source) {
        _logSource = source;
      };

    } else {
      acs.log = function () {
        log(arguments);
      };
    }
  }


  function log() {
    var params = [];
    var len = arguments.length;

    //copy arguments to array first - V8 compilation issue
    for (var i = 0; i < len; i++) {
      params.push(arguments[i]);
    }
    //resolve any args that are a function or object/array
    params = params.map(function (p) {
      return (typeof(p) === 'function' ?
        p() : typeof(p) === 'string' ? p : !p ? '' : Array.isArray(p) ? p.join(' ') : JSON.stringify(p));
    });
    console.log(params.join(''));
  }

  function setLog(level) {
    if (_level <= level) return function () {
      log.apply(this, arguments);
    };
    return function () {
    };
  }

  module.exports = function (acs, isDesktop) {
    if (typeof acs === 'undefined') throw new Error('tried to initialize a logger with undefined.');
    setup(acs, isDesktop);
  };
})();