module.exports={
  "name": "jet-api",
  "version": "2.3.8",
  "author": {
    "name": "Alban Gicquel",
    "email": "alban.gicquel@thomsonreuters.com"
  },
  "contributors": [
    {
      "name": "Li Cui",
      "email": "li.cui@thomsonreuters.com"
    },
    {
      "name": "Maksim Muinast",
      "email": "maksim.muinast@thomsonreuters.com"
    }
  ],
  "scripts": {
    "test": "nyc --reporter=html jasmine JASMINE_CONFIG_PATH=spec/support/jasmine.json",
    "test:debug": "node %NODE_DEBUG_OPTION% node_modules/jasmine/bin/jasmine JASMINE_CONFIG_PATH=spec/support/jasmine.json",
    "test:watch": "npm test -- -watch",
    "test:bundle": "browserify spec/spec/specs.js -o spec/browser/test.bundle.js -t [ babelify --presets [ es2015 ] ]",
    "build": "grunt build",
    "clean": "grunt clean",
    "release:patch": "grunt release:patch",
    "release:minor": "grunt release:minor",
    "release:major": "grunt release:major"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/thomsonreuters/jet-api.git"
  },
  "bugs": {
    "url": "https://github.com/thomsonreuters/jet-api/issues"
  },
  "homepage": "https://github.com/thomsonreuters/jet-api#readme",
  "devDependencies": {
    "browserify": "^13.0.0",
    "ghooks": "1.3.2",
    "grunt": "^1.0.1",
    "grunt-browserify": "^5.0.0",
    "grunt-changelog": "^0.3.2",
    "grunt-contrib-clean": "^1.0.0",
    "grunt-contrib-connect": "^1.0.2",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-jshint": "^1.1.0",
    "grunt-contrib-uglify": "^2.0.0",
    "grunt-contrib-watch": "^1.0.0",
    "grunt-exec": "^1.0.1",
    "grunt-exorcise": "^2.1.1",
    "grunt-git": "^1.0.3",
    "grunt-http": "^2.2.0",
    "grunt-mkdir": "^1.0.0",
    "grunt-prompt": "^1.3.3",
    "grunt-version": "^1.1.1",
    "istanbul": "0.4.5",
    "jasmine": "2.5.2",
    "jasmine-jquery": "2.1.1",
    "jet-app-containershim": "^2.1.2",
    "jit-grunt": "^0.10.0",
    "jquery": "3.1.1",
    "jsdom": "9.6.0",
    "jshint": "*",
    "jsonschema": "^1.0.2",
    "nyc": "8.3.0",
    "prepend-file-cli": "^1.0.2",
    "sinon": "1.17.6",
    "time-grunt": "^1.4.0",
    "tough-cookie": "^2.3.2",
    "uglify-js": "^2.6.1"
  },
  "config": {
    "ghooks": {
      "pre-commit": "npm run build"
    }
  },
  "babel": {
    "presets": [
      "es2015"
    ],
    "env": {
      "test": {
        "plugins": [
          "istanbul"
        ]
      }
    }
  },
  "nyc": {
    "exclude": [
      "node_modules",
      "spec/setup.js",
      "spec/babel_spec_runner.js",
      "spec/mocks/**/*.js",
      "spec/spec/**/*spec.js"
    ],
    "sourceMap": false,
    "instrument": true
  },
  "files": [
    "package.json",
    "bower.json",
    "README.md",
    "CHANGELOG.md",
    "src",
    "dist"
  ],
  "main": "dist/JET.js",
  "license": "UNLICENSED"
}
