(function () {
  'use strict';

  // Registers with EikonWeb
  // Overwrites the ACS processEvent functions

  var ARCHIVE_RESPONSE_CHANNEL = '/navigation/archive_response';
  var ARCHIVE_REQUEST_CHANNEL = '/navigation/archive_request';
  var container;
  var CCFId = void 0;
  var resolved_data = {};
  var JETAppData;
  var _persist;
  var acs;
  var registrationDfd;

  module.exports = function (options) {

    options = options || {};
    JETAppData = options.JETAppData || '';
    registrationDfd = options.dfd;
    var self = acs = this; // jshint ignore:line

    container = registerWithCCFContainer({
      getData: self._onGetData,
      processEvent: processEvent.bind(self)
    }, JETAppData.xmlData);

    CCFId = container.$$id;
    resolved_data._topLevel = true;
    resolved_data.Initialized = container !== null;
    resolved_data.ContainerType = 'Sync';

    // overwrites the getData/ProcessEvents on the original AppContainerShim
    acs.getData = (typeof container.getDataAsync === "function") ? getDataAsync : getDataSync;
    acs._processEvent = function (src, e) {
      var msg;
      if (src && src instanceof Window) {
        msg = self._toContainer(e);
      } else if (src && e === void 0) {
        // this might not work if the JET app has not registered with this ACS
        msg = self._toContainer(src);
      } else {
        // this might not work if the JET app has not registered with this ACS
        msg = self._toContainer(e);
      }
      container.processEvent(msg);
    };

    var data = container.getData({
      name: 'description',
      xmlData: ''
    });

    if (data !== null) {
      resolved_data.containerDescription = acs._fromContainerData('Description', data);
    }

    // no guid test
    resolved_data.GUID = getGUID(container);

    container.processEvent({
      name: 'Subscribe',
      data: '',
      channel: ARCHIVE_RESPONSE_CHANNEL
    });

    container.processEvent(acs._toContainer({
      name: 'Publish',
      data: JSON.stringify({
        id: JETAppData.ID,
        url: window.location.href
      }),
      channel: ARCHIVE_REQUEST_CHANNEL
    }));
  };

  function getGUID(container) {
    var info = container.getData({
      name: 'ContainerInformation',
      xmlData: null
    }) || {};
    return info.GUID;
  }

  function setPersistData(xmlData) {
    _persist = acs._toContainer('persistdata', xmlData.persist).xmlData;
  }

  function sendPersistData(data) {
    if (registrationDfd !== 'resolved') {
      if (data.xmlData) resolved_data.persistData = JSON.parse(data.xmlData).persist;
      registrationDfd.resolve(resolved_data);
      registrationDfd = 'resolved'; // saves some memory
    } else {
      setPersistData.call(acs, data);
    }
  }

  function getDataSync(request) {
    var name = void 0;
    var response = null;
    if (request) name = request.name;

    return new Promise(function (resolve, reject) {
      try {
        if (name === 'persistdata') {
          response = _persist;
        } else {
          response = container.getData(request);
          if (response) response = acs._fromContainerData(name, response);
        }
      } catch (e) {
        // u error
        acs.error('getData({"name":"' + request.name + '"} throws Exception : "' + e.message + '"');
      }

      resolve(response);
    });
  }

  function getDataAsync(request) {
    var name = void 0;
    var response = null;
    if (request) name = request.name;

    return new Promise(function (resolve, reject) {
      container.getDataAsync(name, resolve, reject);
    }).then(function(res) {
      return res; // EikonWeb contract: getDataAsync result is preformatted
    }).catch(function(e) {
      acs.error("getDataAsync failed: " + e);
    });
  }

  function processEvent(e) {
    if (Object.prototype.toString.call(e) === '[object String]') {
      e = JSON.parse(e);
    }

    if (e.channel === ARCHIVE_RESPONSE_CHANNEL) {
      sendPersistData.call(acs, e, JETAppData.ID);
    } else {
      acs._onProcessEvent(e);
    }
  }
})();