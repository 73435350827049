(function () {
  'use strict';
  var __handlerId = 0;
  var __channelHandlers = {};
  var __eventHandlers = {};
  var registrationConfiguration = require('./stubs/registerConfiguration');
  var parseKey = 'jet:msg';
  var queuedSyncMessages = [];
  var queuedPromises = [];
  var ready = false;
  var useTop = require('./shared/_top');
  var frame = (useTop) ? top : window;
  var __bus;

  function fireQueuedMessages(router) {
    if (queuedSyncMessages.length) {
      queuedSyncMessages.forEach(function (msg) {
        router.sendMessageToBus(msg);
      });
    }
    queuedSyncMessages = [];
    if (queuedPromises.length) {
      queuedPromises.forEach(function (data) {
        router.sendPromiseToBus(data.promise)
          .then(function(response) {data.dfd.resolve(response);})
          .catch(function(error) {data.dfd.reject(error);});
      });
    }
    queuedPromises = [];
  }

  module.exports = {

    // message management
    sendMessageToBus: function (data) {
      if (!__bus) queuedSyncMessages.push(data);
      else {
        __bus.sendMessage(frame, data);
      }
    },

    sendPromiseToBus: function (data) {
      if (!__bus) {
        var dfd = {
          rs: null,
          rj: null
        };

        var promise = new Promise(function(resolve, reject) {
          dfd.resolve = resolve;
          dfd.reject = reject;
        });

        queuedPromises.push({ promise: data, dfd: dfd });
        return promise;
      }
      else return __bus.sendPromise(frame, data);
    },

    // bus is the message manager
    setBus: function (bus) {
      __bus = bus;
    },

    // generic functions used event handlers and channel handlers
    getChannelHandlers: function () {
      return __channelHandlers;
    },

    getChannelHandler: function (channel) {
      if (channel) return __channelHandlers[channel];
      else return void 0;
    },

    getEventHandlers: function () {
      return __eventHandlers;
    },

    getEventHandler: function (name) {
      if (name) return __eventHandlers[name];
      else return void 0;
    },

    _addHandlerHelper: function (options, bucket) {
      var subscriptionId = __handlerId++;
      var ctx = this;
      var key = options.name || options.channel;
      var subscription;

      subscription = {
        id: subscriptionId,
        handler: options.handler,
        context: options.context || window,
        unsubscribe: function () {
          return ctx.__unsubscribe({ id: subscriptionId, key: key }, bucket);
        }
      };

      this.__addSubscriptionToBucket(options, subscription, bucket);

      return {
        unsubscribe: function () {
          return ctx.__unsubscribe({ id: subscriptionId, key: key }, bucket);
        }
      };
    },

    __addSubscriptionToBucket: function (options, subscription, bucket) {
      var key = options.name || options.channel;
      var subscriptions;

      if (key) {
        subscriptions = bucket[key];
        if (subscriptions) subscriptions.push(subscription);
        else bucket[key] = [subscription];
        return bucket[key];
      }
    },

    // Registration Events
    // dataProviders is a function provided by the top level JET App
    // this will bind the JET App's data providers to the message manager
    ACShandshake: function () {
      // object that will be passed to other container
      return this.sendPromiseToBus({
        parseKey: 'jet:msg',
        funcName: 'handshake',
        params: []
      });
    },

    ACSpollForInitData: function () {
      return this.sendPromiseToBus({
        funcName: 'getRegisterData',
        parseKey: 'jet:msg',
        attachSource: true
      });
    },


    // sends a request to acs, to register its JET App
    // stores metadata for sub app which may need it
    JETRegisterRequest: function (initObj) {
      var data = registrationConfiguration(initObj);
      return this.sendPromiseToBus({
        parseKey: 'jet:msg',
        funcName: '_registerJETApp',
        params: [data]
      });
    },

    // ACS sends a response back with container registration information if this is the tla

    _setRegisterData: function (data, jet) {
      ready = true;
      fireQueuedMessages(this);
      // handle container
      if (!data.subApp) {

        // send data back to ACS for other sub apps to register.
        this.sendMessageToBus({
          funcName: 'setRegisterData',
          parseKey: 'jet:msg',
          params: [data]
        });

      } else {
        // subapp case.
        // wait for data to come back
        this.ACSpollForInitData();
      }
    },

    // Event Processing
    // call when the acs sends an event to a router with data
    callEventHandler: function (event) {
      return this.doEvent(event);
    },

    doEvent: function (event) {
      var data;
      if (typeof event === 'string') data = JSON.parse(event);
      else data = event;

      var name = data.name;
      var subscriptions;
      var response;

      if (name) subscriptions = this.getEventHandler(name);

      if (subscriptions) subscriptions.forEach(function (subscription) {
        if (subscription.handler) response = subscription.handler.apply(subscription.context, [data]);
      });

      if (response) return response;
    },

    // dont return otherwise we will have to consider case of unsubscribing for events... accidental unsubbing
    addEventHandler: function (name, handler, context) {
      this._addHandlerHelper({
        name: name,
        handler: context ? function (event) {
          return handler.call(window, event.data);
        } : handler,
        context: void 0
      }, __eventHandlers);
    },

    processEvent: function (data) {
      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_processEvent',
        params: [data],
        attachSource: true
      });
    },

    // EventListeners

    /**
     * Publishes data to the container.
     * @param channel - The channel to publish data on.
     * @param data - The data.
     */
    publish: function (channel, data) {
      var publish = {
        channel: channel,
        data: data
      };

      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_publish',
        params: [publish]
      });
    },

    /**
     * Publishes data to the container.
     * @param {string} channel - The channel to publish data on.
     * @param {string} data - The data.
     * @param {string} [target] - A predefined name or the GUID of the target app
     */
    publishWithPipeTarget: function (channel, data, target) {
      var publish = {
        channel: channel,
        data: data,
        pipe: true
      };

      if (target) {
        publish.target = target;
      }

      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_publishWithPipeTarget',
        params: [publish]
      });
    },


    // subscriber management
    _emitSubscribe: function (options) {
      var subscription = {
        type: options.type,
        data: {
          channel: options.channel
        }
      };

      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_subscribe',
        params: [subscription.data],
        attachSource: true
      });
    },

    //(todo) subscription management

    // Subscribe Management
    // bucket is event map or channels map

    // Always emit subscribe event to ACS, ACS will determine when to fire the response
    subscribe: function (options) {
      options = options || {};
      var subscription = this.addSubscriptionHandler(options);
      this._emitSubscribe(options);
      return subscription;
    },

    _emitSubscribeWithPipeTarget: function (options) {
      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_subscribeWithPipeTarget',
        params: [options],
        attachSource: true
      });
    },

    subscriptionWithPipeTarget: function (options) {
      options = options || {};
      var subscription = this.addSubscriptionHandler(options);
      this._emitSubscribeWithPipeTarget(options);
      return subscription;
    },

    addSubscriptionHandler: function (options) {
      return this._addHandlerHelper(options, this.getChannelHandlers());
    },

    // todo refactor
    _callSubscriptionsHelper: function (channel, args) {
      var subscriptions;
      var response;

      if (channel) subscriptions = this.getChannelHandlers(channel);

      if (subscriptions) subscriptions.forEach(function (subscription) {
        var handler = subscription.handler;

        if (handler) response = handler.apply(subscription.context, args);
      });

      if (response) return response;

    },

    // called by JET to unsub a particular channel
    unsubscribeChannel: function (options) {
      options = options || {};
      options.key = options.channel;
      return this.__unsubscribe(options, this.getChannelHandlers());
    },

    // Only emit unsubscribe event if the subscribers is empty
    // is supposed to unsubscribe
    __unsubscribe: function (options, handlers) {
      options = options || {};
      var key = options.key;
      var subscribers = handlers[key];
      var id = options.id;
      var shouldRemoveSubscription = false;

      if (id && typeof (id) === 'number' && Array.isArray(subscribers)) {
        for (var i = 0; i < subscribers.length; i++) {
          if (subscribers[i].id === id) {
            subscribers.splice(i, 1);
            break;
          }
        }
        if (subscribers.length === 0) {
          shouldRemoveSubscription = true;
        }
      }
      else {
        shouldRemoveSubscription = true;
      }

      // if this is now empty as a result of unsubscribing emit an event
      if (shouldRemoveSubscription && subscribers !== undefined) {
        this.__emitUnsubscribe({ name: 'Unsubscribe', channel: key });
        delete handlers[key];
      }
    },

    __emitUnsubscribe: function (data) {
      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_unsubscribe',
        params: [data],
        attachSource: true
      });
    },

    // unsubscribes from all channels on this router
    unsubscribeAll: function () {
      var subscriptions = this.getChannelHandlers();
      var keys = Object.keys(subscriptions);
      var numKeys = keys.length;

      for (var i = 0; i < numKeys; i++) {
        var subscribers = subscriptions[keys[i]];
        if (subscribers) subscribers.forEach(function (subscriber) {
          if (subscriber.unsubscribe) subscriber.unsubscribe();
        });

        // if there are no more subcriptions delete the map
        if (subscriptions[keys[i]] === null) {
          this.__emitUnsubscribe({ channel: keys[i] });
          delete subscriptions[keys[i]];
        }
      }
    },

    callSubscriptions: function (event, args) {
      var channel = event.channel;
      var subscriptions;
      var response;

      if (channel) subscriptions = this.getChannelHandler(channel);
      if (subscriptions) subscriptions.forEach(function (subscription) {
        var handler = subscription.handler;

        if (handler) response = handler.apply(subscription.context, args);
      });

      if (response) return response;

    },

    // Getting data from Container
    getContainerData: function (msg) {
      return this.sendPromiseToBus({
        parseKey: 'jet:msg',
        funcName: 'getData',
        params: [msg]
      });
    },

    getFromContainer: function (event, data) {
      return this.sendPromiseToBus({
        parseKey: 'jet:msg',
        funcName: '_fromContainerAsync',
        params: [event, data]
      });
    },

    toContainerAsync: function (event, data) {
      return this.sendPromiseToBus({
        parseKey: 'jet:msg',
        funcName: '_toContainerAsync',
        params: [event, data]
      });
    },

    setLogLevel: function (level) {
      this.sendMessageToBus({
        parseKey: 'jet:msg',
        funcName: '_setLoggingLevel',
        params: [level]
      });
    },

    emitBoundProp: function (objectName, keyName, newValue) {
      this.sendMessageToBus({
        parseKey: parseKey,
        funcName: '_setBoundedProp',
        params: [{
          name: objectName,
          key: keyName,
          value: newValue
        }]
      });
    }
  };
})();