(function () {
  'use strict';
  // Handles messages between frames
  // source is the top most available window or top...

  var _top;
  var frameManager;
  var PromiseHandler = require('./PromiseHandler');

  // used in EikonWeb to filter messages for JET
  function messageForJET(message) {
    if (message) {
      if (message.lastIndexOf) {
        if (message.lastIndexOf('acs--jet--', 0) === 0) return true;
      }
    }
    return false;
  }

  function MessageManager(source, options) {
    options = options || {};

    this._parsers = [];
    this.name = Math.floor(Math.random() * (1000)) + 1 + '(acs_master)';
    this.promiseHandler = PromiseHandler(this.name);
    this._isACS = true;

    frameManager = options.frameManager;
    var aWindow = frameManager.getWindow();
    _top = options.frameManager.getFrame();

    if (options.sameWindowStrategy) {

      // allows JET app to directly call ACS
      aWindow.__ACSMessageShim__ = this._messageHandlerSameWindow.bind(this);

      this.sendMessage = function (where, options) {
        options = options || {};
        if (aWindow.__JETMessageShim__) aWindow.__JETMessageShim__(where, options);
        else throw new Error('ACS could not find the JETMessageShim.');
      };

    } else {

      this.init(source);

      // public api, appended here to ensure that each MM instance gets a unique sendMessage/sendPromise instance so that
      // ACS can correctly route messages to it
      this.sendMessage = function (where, options) {
        options = options || {};
        where.postMessage(this._makeMessage(options), '*');
      };

    }

    this.sendPromise = function (where, options) {
      options = options || {};
      var promiseIndex = this.promiseHandler.makePromise();
      options.promiseIndex = promiseIndex;
      this.sendMessage(where, options);
      return this.promiseHandler.getDeferred(promiseIndex);
    };
  }

  MessageManager.prototype = {

    // initialization
    init: function (source) {
      // someone else initialized a message manager on this window
      this.source = source;
      this.boundOnMessage = this._messageHandler.bind(this);

      if (this.source.addEventListener) this.source.addEventListener('message', this.boundOnMessage, false);
      if (this.source.attachEvent) this.source.attachEvent('onmessage', this.boundOnMessage, false);

      return this;
    },

    dispose: function () {
      if (this.source && this.source.removeEventListener) this.source.removeEventListener('message', this.boundOnMessage, false);
      if (this.source && this.source.detachEvent) this.source.detachEvent('onmessage', this.boundOnMessage, false);

      this.promiseHandler.dispose();
    },

    // parsing & sanitizing
    addStringToParser: function (parser) {
      this._parsers.push(parser);
    },

    _parseData: function (dataString) {
      var parsers = this._parsers;
      var numberOfParsers = parsers.length;
      if (numberOfParsers > 0) {
        // if there are registered parsers, for each parser try parsing the string
        for (var i = 0; i < numberOfParsers; i++) {
          var parserString = parsers[i];

          if (Object.prototype.toString.call(dataString) === '[object String]') {
            // data to parse is a string of information
            if (dataString.match(parserString)) {
              dataString = dataString.replace(parserString, '');
              try {
                return JSON.parse(dataString);
              } catch (e) {
                // could not parse for some reason
                return;
              }
            }
          } else if (typeof dataString === 'object') {
            // data to parse is an object, most likely JSON
            // make sure the object is from a sane origin
            return dataString;
          }

        }
      } else {
        // no parsers i.e. everything gets through the message manager
        try {
          return JSON.parse(dataString);
        } catch (e) {
          // could not parse it, just return the data string
          return dataString;
        }
      }
    },

    // message handling
    _makeMessage: function (options) {
      options = options || {};
      var key = '';
      if (options.parseKey) key += options.parseKey;
      return key + JSON.stringify({
          params: options.params,
          promiseIndex: options.promiseIndex,
          funcName: options.funcName,
          parseKey: options.parseKey,
          senderId: 'acs--jet--' + this.name,
          attachSource: options.attachSource,
          name: this.name
        });
    },

    _messageHandler: function (event) {
      var source = event.source;
      var origin = event.origin;
      var data = this._parseData(event.data);

      // bypass if in EikonWeb
      if (data && !data.targetId) {
        if (data.funcName) this._handleMessage(event.source, data);
      } else if (data && messageForJET(data.senderId)) {
        this._handleEikonMessage(data);
      }
    },


    _messageHandlerSameWindow: function (window, data) {
      var source = window;
      var origin = '';

      // bypass if in EikonWeb
      if (!data.targetId) {
        if (data.funcName) this._handleMessage(window, data);
      } else if (messageForJET(data.senderId)) {
        this._handleEikonMessage(data);
      }
    },

    _handleMessage: function (source, data) {
      var context = this;
      var funcName = data.funcName;
      var params = data.params;
      var promiseIndex = data.promiseIndex;
      var attachSource = data.attachSource;

      switch (funcName) {
        case 'handshake':
          this.sendMessage(source, {promiseIndex: promiseIndex, parseKey: data.parseKey, funcName: 'replyHandshake'});
          break;
        case 'replyHandshake':
          this.promiseHandler.resolvePromise(promiseIndex, true);
          break;
        case 'replyPromiseResolve':
          this.promiseHandler.resolvePromise(promiseIndex, params);
          break;
        case 'replyPromiseReject':
          this.promiseHandler.rejectPromise(promiseIndex, params);
          break;
        case 'setRegisterData':
          this.registerData = data.params;
          this.topAppInstanceId = this.registerData.instanceId;
          this.topAppAppId = this.registerData.appId;
          delete this.registerData.instanceId;
          delete this.registerData.appId;
          break;
        case 'getRegisterData':
          if (this.registerData && promiseIndex) {
            this.sendMessage(source, {
              promiseIndex: promiseIndex,
              parseKey: data.parseKey,
              funcName: 'replyRegisterData',
              params: this.registerData
            });
          } else {
            this.sendMessage(source, {
              parseKey: data.parseKey,
              funcName: 'replyRegisterData'
            });
          }
          break;
        case 'replyRegisterData':
          if (params) {
            this.promiseHandler.resolvePromise(promiseIndex, params);
          } else {
            this.promiseHandler.rejectPromise(promiseIndex, false);
          }
          break;
        default:
          //attach source pushes the origin source to the front of the arguments
          if (attachSource) {
            params.unshift(source);
          }

          var scope;
          var fn;
          if (promiseIndex) {
            // calling another functions promise
            scope = this.__getFunc(funcName);
            if (scope) {
              fn = scope[funcName];
              if (typeof fn === 'function') {

                // todo remove and refactor inner function call
                var returnPromise = function (options) {
                  options = options || {};
                  options.parseKey = 'jet:msg'; // todo put this inside of the callers
                  context.sendMessage(source, options);
                };

                fn.apply(scope, params)
                  .then(
                    function onsuccess(data) {
                      var options = {
                        funcName: 'replyPromiseResolve',
                        source: source,
                        params: data,
                        promiseIndex: promiseIndex
                      };
                      returnPromise(options);
                    },
                    function onerror(error) {
                      var options = {
                        funcName: 'replyPromiseReject',
                        params: [error.toString(), error.stack],
                        promiseIndex: promiseIndex
                      };
                      returnPromise(options);
                    });
              }
            }
            // Message Manager could not find a func name
          } else {
            scope = this.__getFunc(funcName);
            if (scope) {
              fn = scope[funcName];
              if (typeof fn === 'function') fn.apply(scope, params);
            }
            // Message Manager could not find a func name
          }
      }
    },

    _handleEikonMessage: function (data) {
      if (this._isACS) {
        // TODO need to check the container id for an extra layer of security
        // todo encapsulate on processevent
        this._isACS._onProcessEvent(data);
      } else {
        // app should send message to ACS
        this.sendMessage(_top, {
          parseKey: 'jet:msg',
          params: [data.data],
          attachSource: true,
          funcName: '_onProcessEventWithSource'
        });
      }
    },

    // register namespace
    __namespaces__: [],
    registerNamespace: function (namespace) {
      this.__namespaces__.push(namespace);
    },

    // searches the name space for the existence of the function
    __getFunc: function (funcName) {
      var scope = void 0;
      var namespaces = this.__namespaces__;
      var numberNamespaces = namespaces.length;

      for (var i = 0; i < numberNamespaces; i++) {
        var namespace = namespaces[i];

        if (namespace[funcName]) {
          scope = namespace;
          break;
        }
      }

      return scope;
    }
  };

  module.exports = MessageManager;
})();
