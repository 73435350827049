/**
 * Exposes a factory for initializing an AppContainerShim (ACS)
 * Usage: __initACS__(resolve, reject);
 * The function will detect how to handle initialization of the ACS.
 */

(function () {
  'use strict';
  
  // todo be careful of memory leaks due to message manager not being properly destroyed
  // context is a reference to the window that is calling initACS, this is to help with identifying the
  // top level container and ensuring that each window get its own message manager interface

  var called = 0;
  var aWindow;
  var eikonNow;
  var frameManager;
  var MessageManager;
  var ACS;
  var forcedSameWindowStrategy;
  var _top;

  function initACS(resolve, reject) {
    if (!resolve || !reject) throw new Error('AppContainerShim needs a resolve & reject to load');
    else {
      if (!called) {
        called++;
        _load(resolve, reject);
      } else {
        // avoids race condition between multiple JET apps attempting to load at the same time
        setTimeout(function () {
          _load(resolve, reject);
        }, 50);
      }
    }
  }

  function unRegisterACS() {
    if (_top.__ACS__) {
      called = 0;
      _top.__ACS__.unRegister();
      frameManager.setOnFrame('__initACS__', undefined, true);
      frameManager.setOnFrame('__unRegisterACS__', undefined, true);
    }
  }

  function _load(resolve, reject) {
    var results = {};

    if (called === 1) {
      called++;

      results = {
        register: true
      };

      createACSMessageManager();
    }

    resolve(results);
  }

  function createACSMessageManager() {
    var ACSBus;
    var isWeb = !frameManager.isDesktop();

    _top.__ACS__ = new ACS({
      isWeb: isWeb,
      frameManager: frameManager,
      aWindow: aWindow,
      eikonNow: eikonNow
    });

    ACSBus = new MessageManager(_top, {
      sameWindowStrategy: forcedSameWindowStrategy || isWeb,
      frameManager: frameManager
    });

    _top.__ACS__.setBus(ACSBus);
    ACSBus.addStringToParser('jet:msg');
    ACSBus.registerNamespace(_top.__ACS__);
  }

  module.exports = function (options) {
    ACS = options.ACS;
    forcedSameWindowStrategy = options.forcedSameWindowStrategy;
    eikonNow = options.eikonNow;
    aWindow = options.aWindow;
    MessageManager = options.MessageManager;
    frameManager = options.frameManager;

    _top = frameManager.getFrame();
    frameManager.setOnFrame('__initACS__', initACS);
    frameManager.setOnFrame('__unRegisterACS__', unRegisterACS);
  };
})();
