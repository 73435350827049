(function () {
  'use strict';
  // Handles messages between frames
  var _top = require('./shared/_top');
  var frame = (_top) ? top : window;
  var _parsers = ['jet:msg'];

  // used in EikonWeb to filter messages for JET
  function messageForJET(message) {
    if (message) {
      if (message.lastIndexOf) {
        if (message.lastIndexOf('acs--jet--', 0) === 0) return true;
      }
    }
    return false;
  }

  function MessageManager(options) {
    options = options || {};
    this._promiseIndex = 1;
    this._promises = {};
    this._deferreds = {};
    this._sanitaryFunctions = void 0; // functions that are ok to call
    this.name = Math.floor(Math.random() * (1000)) + 1 + 'j_mm';

    if (options.sameWindowStrategy) {
      // strategy where acs is on the same window as jet mostly used in eikonweb
      this.sameWindowStrategy = true;

      // expose messaging protocol
      // todo check for memory leaks
      window.__JETMessageShim__ = this._messageHandlerSameWindow.bind(this);
      if (!window.__ACSMessageShim__) throw new Error('Could not find ACSMessageShim.');
      else {
        this.__ACSMessageShim__ = window.__ACSMessageShim__;
      }

      this.sendMessage = function (where, options) {
        options = options || {};
        this.__ACSMessageShim__(where, options);
      };

      this.sendPromise = function (where, options) {
        options = options || {};
        var promiseIndex = this._makePromise();
        options.promiseIndex = promiseIndex;
        this.sendMessage(where, options);
        return this._promises[promiseIndex];
      };

      this.removeListener = function () {
      }; // no op because not using postMessage

    } else {
      // strategy where postMessage is used
      this.init();

      // public api, appended here to ensure that each MM instance gets a unique sendMessage/sendPromise instance so that
      // ACS can correctly route messages to it
      this.sendMessage = function (where, options) {
        options = options || {};
        where.postMessage(this._makeMessage(options), '*');
      };

      this.sendPromise = function (where, options) {
        options = options || {};
        var promiseIndex = this._makePromise();
        options.promiseIndex = promiseIndex;
        this.sendMessage(where, options);
        return this._promises[promiseIndex];
      };
    }
  }

  MessageManager.prototype = {

    unload: function () {
      this.removeListener();
      this.__namespaces__ = [];
    },

    // initialization
    init: function () {
      // someone else initialized a message manager on this window
      this.source = window;
      this.boundOnMessage = this._messageHandler.bind(this);

      if (this.source.addEventListener) this.source.addEventListener('message', this.boundOnMessage, false);
      else if (this.source.attachEvent) this.source.attachEvent('onmessage', this.boundOnMessage, false);

      return this;
    },

    removeListener: function () {
      if (this.source && this.source.removeEventListener) this.source.removeEventListener('message', this.boundOnMessage, false);
      else if (this.source && this.source.detachEvent) this.source.detachEvent('onmessage', this.boundOnMessage, false);
    },

    // promise functions
    _makePromise: function () {
      var context = this;
      var index = this._promiseIndex++ + '_' + this.name;
      this._promises[index] = new Promise(function (resolve, reject) {
        context._deferreds[index] = {
          resolve: resolve,
          reject: reject
        };
      });
      return index;
    },

    _getPromise: function (id) {
      return this._deferreds[id];
    },

    // saves on memory consumption
    _cleanPromise: function (id, type) {
      this._deferreds[id] = type;
      this._promises[id] = type;
    },

    _resolvePromise: function (id, params) {
      var promise = this._getPromise(id);
      // promise may have already been resolved
      if (promise && promise.resolve) {
        promise.resolve(params);
        if (!this.sameWindowStrategy) this._cleanPromise(id, 'resolved');
        else {
          // in same window because sync clean the promise after a second
          var self = this;
          setTimeout(function () {
            self._cleanPromise(id, 'resolved');
          }, 1000);
        }
      }
    },

    _rejectPromise: function (id, params) {
      var promise = this._getPromise(id);
      // promise may have already been rejected
      if (promise && promise.reject) {
        promise.reject(params);
        this._cleanPromise(id, 'rejected');
      }
    },


    _parseData: function (dataString) {
      var numberOfParsers = _parsers.length;
      if (numberOfParsers > 0) {
        for (var i = 0; i < numberOfParsers; i++) {
          var parserString = _parsers[i];

          if (Object.prototype.toString.call(dataString) == '[object String]') {
            // a string
            if (dataString.match(parserString)) {
              dataString = dataString.replace(parserString, '');
              try {
                return JSON.parse(dataString);
              } catch (e) {
                // could not parse
              }
            }
          }

          else if (typeof dataString === 'object') {
            // make sure the object is from a sane origin
            return dataString;
          }

        }
      } else {
        // no parsers i.e. everything gets through the message manager
        try {
          return JSON.parse(dataString);
        } catch (e) {
        }
      }
    },

    addSanitizedFuncName: function (funcNames) {
      if (this._sanitaryFunctions) this._sanitaryFunctions = new RegExp(this._sanitaryFunctions.source + '|' + funcNames);
      else this._sanitaryFunctions = new RegExp(funcNames);
    },

    // if there are allowed func names sanitize function, otherwise everything gets through
    _isSanitaryFunction: function (funcName) {
      if (this._sanitaryFunctions) {
        if (funcName.match(this._sanitaryFunctions)) return funcName;
      } else {
        // everything gets through
        return funcName;
      }
    },


    // message handling
    _makeMessage: function (options) {
      options = options || {};
      var key = '';
      if (options.parseKey) key += options.parseKey;
      return key + JSON.stringify({
          params: options.params,
          promiseIndex: options.promiseIndex,
          funcName: options.funcName,
          parseKey: options.parseKey,
          attachSource: options.attachSource,
          senderId: 'acs--jet--' + this.name,
          name: this.name
        });
    },

    _messageHandler: function (event) {
      var source = event.source;
      var origin = event.origin;
      var data = this._parseData(event.data);

      // bypass if in EikonWeb
      if (data && !data.targetId) {
        if (data.funcName) this._handleMessage(event.source, data);
      } else if (data && messageForJET(data.senderId)) {
        this._handleEikonMessage(data);
      }
    },


    _messageHandlerSameWindow: function (window, data) {
      if (data && !data.targetId) {
        if (data.funcName) this._handleMessage(window, data);
      } else {
        this._handleEikonMessage(data);
      }
    },

    _handleMessage: function (source, data) {
      var context = this;
      var funcName = data.funcName;
      var params = data.params;
      var promiseIndex = data.promiseIndex;
      var attachSource = data.attachSource;

      switch (funcName) {
        case 'handshake':
          this.sendMessage(source, {promiseIndex: promiseIndex, parseKey: data.parseKey, funcName: 'replyHandshake'});
          break;
        case 'replyHandshake':
          this._resolvePromise(promiseIndex, true);
          break;
        case 'replyPromiseResolve':
          this._resolvePromise(promiseIndex, params);
          break;
        case 'replyPromiseReject':
          this._rejectPromise(promiseIndex, params);
          break;
        case 'getRegisterData':
          if (this.registerData && promiseIndex) {
            this.sendMessage(source, {
              promiseIndex: promiseIndex,
              parseKey: data.parseKey,
              funcName: 'replyRegisterData',
              params: this.registerData
            });
          } else {
            this.sendMessage(source, {
              promiseIndex: promiseIndex,
              parseKey: data.parseKey,
              funcName: 'replyRegisterData'
            });
          }
          break;
        case 'replyRegisterData':
          if (params) {
            this._resolvePromise(promiseIndex, params);
          } else {
            this._rejectPromise(promiseIndex, false);
          }
          break;
        default:
          //attach source pushes the origin source to the front of the arguments
          if (attachSource) {
            params.unshift(source);
          }

          var fn;
          var scope;
          if (promiseIndex) {
            // calling another functions promise
            //if (this._isSanitaryFunction(funcName)) {

            scope = this.__getFunc(funcName);
            if (scope) {
              fn = scope[funcName];
              if (typeof fn === 'function') {
                var returnPromise = function(options) {
                  options = options || {};
                  options.parseKey = 'jet:msg'; // todo put this inside of the callers
                  context.sendMessage(source, options);
                };

                fn.apply(scope, params)
                  .then(function (resolvedData) {
                      var options = {
                        funcName: 'replyPromiseResolve',
                        source: source,
                        params: resolvedData,
                        promiseIndex: promiseIndex
                      };
                      returnPromise(options);
                    },
                    function (error) {
                      var options = {
                        funcName: 'replyPromiseReject',
                        params: [error],
                        promiseIndex: promiseIndex
                      };
                      returnPromise(options);
                    });

                //}
              }
            } else {
              // drop
            }
          } else {
            //if (this._isSanitaryFunction(funcName)) {
            scope = this.__getFunc(funcName);
            if (scope) {
              fn = scope[funcName];
              if (typeof fn === 'function') fn.apply(scope, params);
            } else {
              // drop
            }
          }
      }
    },

    _handleEikonMessage: function (data) {
      // app should send message to ACS
      this.sendMessage(frame, {
        parseKey: 'jet:msg',
        params: [data.data],
        attachSource: true,
        funcName: '_onProcessEventWithSource'
      });
    },

    // register namespace
    __namespaces__: [],
    registerNamespace: function (namespace) {
      this.__namespaces__.push(namespace);
    },

    // searches the name space for the existence of the function
    __getFunc: function (funcName) {
      var scope = void 0;
      var namespaces = this.__namespaces__;
      var numberNamespaces = namespaces.length;

      for (var i = 0; i < numberNamespaces; i++) {
        var namespace = namespaces[i];

        if (namespace[funcName]) {
          scope = namespace;
          break;
        }
      }

      return scope;
    }
  };

  module.exports = MessageManager;
})();