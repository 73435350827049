(function () {
  'use strict';

  var router = require('./router');
  var _logLevel;

  var logLevels = [
    "trace",
    "debug",
    "info",
    "warn",
    "error"
  ];

  function log() {
    var params = [];
    var len = arguments.length;
    //copy arguments to array first - V8 compilation issue
    for (var i = 0; i < len; i++) {
      params.push(arguments[i]);
    }
    //resolve any args that are a function or object/array
    params = params.map(function (p) {
      return (typeof(p) === "function" ? p() : typeof(p) === "string" ? p : !p ? "" : Array.isArray(p) ? p.join(" ") : JSON.stringify(p));
    });
    console.log(params.join(""));
  }

  function setLog(level) {
    if (_logLevel <= level) {
      return function () {
        log.apply(this, arguments);
      };
    } else {
      // return empty function
      return function () {
      };
    }
  }

  var api = {
    setLogLevel: function (logName) {
      var level = logLevels.indexOf(logName);
      if (level === -1) level = 2;  // default is info level
      this.setLogOptions(level);

      // tell the ACS to be of the same log level
      router.setLogLevel(level);
    },

    // set log level
    setLogOptions: function (logLevel) {
      _logLevel = logLevel === undefined || logLevel === null ? 10 : logLevel;

      this.trace = setLog(0);
      this.debug = setLog(1);
      this.info = setLog(2);
      this.warn = setLog(3);
      this.error = setLog(4);
    }
  };

  module.exports = api;
})();