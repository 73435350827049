(function () {
  'use strict';
  module.exports = function (initObj) {
    var xmlDataObj = {
      version: '3.3.0',
      id: initObj.ID
    };

    if (initObj.AppId) {
      xmlDataObj.appId = initObj.AppId;
    }

    return {
      domain: 'EikonJET',
      xmlData: xmlDataObj,
      method: 'registerWithCCF'
    };
  };
})();