(function () {
  'use strict';

  var _JET = require('./JETCore');
  var router = require('./router');
  var mixin = require('./shared/mixin');
  var utils = require('./Util');

    utils.addInternalLoadHook(function () {
      _extractWindowId();
      _subscribeAppContainerEvents();
      _getLinkChannelStatus();
    });

    var _windowId = null;

    function _extractWindowId() {
      if (_JET.ContainerDescription &&
        _JET.ContainerDescription.windowInfo &&
        _JET.ContainerDescription.windowInfo.windowId) {
        _windowId = _JET.ContainerDescription.windowInfo.windowId;
      }
    }

    var _onLinkedCallbacks = [];
    var _onUnlinkedCallbacks = [];

    function _onLinkChannelEvents(message) {
      if (_windowId && message.data) {
        var data = message.data;

        if (data.windowId && data.windowId === _windowId && data.event) {
          switch (data.event) {
            case "linked":
              _onLinkedCallbacks.forEach(function (callback) {
                callback.call(window, {
                  groupId: data.groupId
                });
              });
              break;
            case "unlinked":
              _onUnlinkedCallbacks.forEach(function (callback) {
                callback.call(window, {
                  groupId: data.groupId
                });
              });
              break;
            case "linkStatus":
              if (data.isLinked) {
                _onLinkedCallbacks.forEach(function (callback) {
                  callback.call(window, {
                    groupId: data.groupId
                  });
                });
              }
              break;
          }
        }
      }
    }

    function _subscribeAppContainerEvents() {
      _JET.subscribe("/container_events", _onAppContainerEvent);
    }


    function _onAppContainerEvent(message) {
      message = JSON.parse(message);

      if (message && message.name) {
        switch (message.name) {
          case "LinkChannel":
            _onLinkChannelEvents(message);
            break;
        }
      }
    }

    function _getLinkChannelStatus() {
      if (_windowId) {
        var message = {
          "name": "LinkChannel",
          "data": {
            "action": "GetLinkStatus",
            "windowId": _windowId
          }
        };

        // A value will be returned to "_onLinkChannelEvents" method
        _JET.publish("/container_actions", JSON.stringify(message));
      }
    }

    function findWindowWithPatchedHistory() {
      var currentWindow = window;
      while(currentWindow !== currentWindow.parent) {
        try {
          if (currentWindow.parent.name === 'EikonNowMarker') {
            return currentWindow;
          }
        } catch(e) {
          break;
        }
    
        currentWindow = currentWindow.parent;
      }

      return window;
    }

    var _window = {
        getHref: function () {
          var location = JET.containerType() === 'desktop' ? window.parent.location: window.location;

          return location.href;
        },
        pushState: function () {
          var history = JET.containerType() === 'desktop' ? window.top.history : findWindowWithPatchedHistory().history;

          history.pushState.apply(history, arguments);
        },
        onPopState: function (handler) {
          var windowWithHistory = JET.containerType() === 'desktop' ? window.top : findWindowWithPatchedHistory();

          windowWithHistory.addEventListener('popstate', handler);
        },
        resizeTo: function (width, height, inner) {
          if (inner === true) {
            width += (window.outerWidth - window.innerWidth);
            height += (window.outerHeight - window.innerHeight);
          }
          router.processEvent({
            name: "onResize",
            data: {
              width: width,
              height: height
            }
          });
        },
        updateTitle: function (title) {
          try {
            if (JET.containerType() === 'desktop') {
                window.top.document.title = title;
            } else {
                document.title = title;
            }
          } catch (e) { }
        },
        /**
         * Resizes the window by specific delta
         * @function resizeBy
         * @memberof JET
         * @param {number} wDelta Pixel-width to resize by.
         * @param {number} hDelta Pixel-height to resize by.
         */
        resizeBy: function (wDelta, hDelta) {
          router.processEvent({
            name: "onResize",
            data: {
              width: window.outerWidth + wDelta,
              height: window.outerHeight + hDelta
            }
          });
        },


        // Directs Eikon to hide its window
        hide: function () {
          router.processEvent({
            name: "onHide",
            data: null
          });
        },

        // Directs Eikon to show its window
        show: function () {
          router.processEvent({
            name: "onShow",
            data: null
          });
        },

        // Directs Eikon to flash its button in the system taskbar
        flash: function (data) {
          router.processEvent({
            name: "onFlash",
            xmlData: data
          });
        },

        // Directs Eikon to stop flashing its button in the system taskbar
        stopFlash: function () {
          router.processEvent({
            name: "onStopFlash",
            data: null
          });
        },

        /**
         * The onLinked method is used to register a callback to be notified when the linked event occur (Two or more apps are linked together via LinkChannel)
         * @function onLinked
         * @memberof JET
         * @param {function} handler - A callback function to be called when the linked event occur
         *
         * @example
         * JET.onLinked(function (data) {
             *   var groupId = data.groupId;
             * });
         */
        onLinked: function (handler) {
          _onLinkedCallbacks.push(handler);
        },

        /**
         * The onUnlinked method is used to register a callback to be notified when the unlinked event occur (It occurs when you detach your app from the group via LinkChannel)
         * @function onUnlinked
         * @memberof JET
         * @param {function} handler - A callback function to be called when the unlinked event occur
         *
         * @example
         * JET.onUnlinked(function (data) {
             *   var groupId = data.groupId;
             * });
         */
        onUnlinked: function (handler) {
          _onUnlinkedCallbacks.push(handler);
        }
    };

    mixin(_JET, _window);
})();