(function () {
  'use strict';
  var EmittedProp = require('./emittedProp');
  var router = require('../router');

  module.exports = function (obj, objectName, key, initialValue) {
    // emit an initial value
    if (initialValue) router.emitBoundProp(objectName, key, initialValue);
    return EmittedProp(obj, key, objectName, function (name, key, value) {
      router.emitBoundProp(name, key, value);
    });
  };
})();