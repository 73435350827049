module.exports={
  "_args": [
    [
      "jet-app-containershim@2.1.2",
      "C:\\projects\\jet2\\jet-api"
    ]
  ],
  "_development": true,
  "_from": "jet-app-containershim@2.1.2",
  "_id": "jet-app-containershim@2.1.2",
  "_inBundle": false,
  "_integrity": "sha1-9ru3wghncFuagfIqhyVdJLcnYlw=",
  "_location": "/jet-app-containershim",
  "_phantomChildren": {},
  "_requested": {
    "type": "version",
    "registry": true,
    "raw": "jet-app-containershim@2.1.2",
    "name": "jet-app-containershim",
    "escapedName": "jet-app-containershim",
    "rawSpec": "2.1.2",
    "saveSpec": null,
    "fetchSpec": "2.1.2"
  },
  "_requiredBy": [
    "#DEV:/"
  ],
  "_resolved": "https://bams-aws.refinitiv.com/artifactory/api/npm/default.npm.global/jet/jet-app-containershim-2.1.2.tgz",
  "_spec": "2.1.2",
  "_where": "C:\\projects\\jet2\\jet-api",
  "author": {
    "name": "Alban Gicquel",
    "email": "alban.gicquel@thomsonreuters.com"
  },
  "bugs": {
    "url": "https://github.com/thomsonreuters/jet-app-containershim/issues"
  },
  "contributors": [
    {
      "name": "Li Cui",
      "email": "li.cui@thomsonreuters.com"
    },
    {
      "name": "Maksim Muinast",
      "email": "maksim.muinast@thomsonreuters.com"
    }
  ],
  "dependencies": {
    "promise-polyfill": "^6.0.2"
  },
  "description": "Handles messaging & subscriptions to EikonJET.",
  "devDependencies": {
    "babel-cli": "6.11.4",
    "babel-plugin-istanbul": "1.0.3",
    "babel-preset-es2015": "6.9.0",
    "babelify": "7.3.0",
    "browserify": "^13.1.1",
    "grunt": "^1.0.1",
    "grunt-browserify": "^5.0.0",
    "grunt-changelog": "^0.3.2",
    "grunt-contrib-clean": "^1.0.0",
    "grunt-contrib-connect": "^1.0.2",
    "grunt-contrib-copy": "^1.0.0",
    "grunt-contrib-jshint": "^1.1.0",
    "grunt-contrib-uglify": "^2.0.0",
    "grunt-contrib-watch": "^1.0.0",
    "grunt-exec": "^1.0.1",
    "grunt-exorcise": "^2.1.1",
    "grunt-git": "^1.0.3",
    "grunt-http": "^2.2.0",
    "grunt-mkdir": "^1.0.0",
    "grunt-prompt": "^1.3.3",
    "grunt-version": "^1.1.1",
    "istanbul": "0.4.4",
    "jasmine": "2.4.1",
    "jit-grunt": "^0.10.0",
    "jsdom": "8.2.0",
    "jshint": "^2.9.4",
    "nyc": "8.1.0",
    "serve-static": "^1.11.1",
    "sinon": "1.17.5",
    "time-grunt": "^1.4.0",
    "tough-cookie": "^2.3.2",
    "uglify-js": "^2.7.4",
    "xmldom": "0.1.22"
  },
  "files": [
    "package.json",
    "README.md",
    "CHANGELOG.md",
    "src",
    "dist",
    "spec/mocks"
  ],
  "homepage": "https://github.com/thomsonreuters/jet-app-containershim#readme",
  "license": "UNLICENSED",
  "main": "dist/acs.js",
  "name": "jet-app-containershim",
  "nyc": {
    "exclude": [
      "spec/setup.js",
      "spec/babel_spec_runner.js",
      "spec/mocks/**/*.js",
      "spec/spec/*spec.js"
    ],
    "sourceMap": false,
    "instrument": false
  },
  "repository": {
    "type": "git",
    "url": "git+https://github.com/thomsonreuters/jet-app-containershim.git"
  },
  "scripts": {
    "build": "grunt build",
    "clean": "grunt clean",
    "release:major": "grunt release:major",
    "release:minor": "grunt release:minor",
    "release:patch": "grunt release:patch",
    "test": "nyc --reporter=html babel-node spec/babel_spec_runner.js",
    "test:bundle": "browserify spec/spec/specs.js -o spec/browser/test.bundle.js -t [ babelify --presets [ es2015 ] ]",
    "test:debug": "node %NODE_DEBUG_OPTION% node_modules/jasmine/bin/jasmine JASMINE_CONFIG_PATH=spec/support/jasmine.json"
  },
  "version": "2.1.2"
}
