(function () {
  'use strict';
  function mix(target, source) {
    for (var propName in source) {
      target[propName] = source[propName];
    }
    return target;
  }

  module.exports = function (obj) {
    for (var i = 1, l = arguments.length; i < l; i++) {
      mix(obj || {}, arguments[i]);
    }

    return obj;
  };
})();