(function () {
  'use strict';
  // utilities for message manager
  module.exports = {
    _events: [],

    _doRemoveEvent: function (wind, key, func) {
      if (wind.removeEventListener) wind.removeEventListener(key, func, false);
      if (wind.detachEvent) wind.detachEvent('on' + key, func, false);
    },

    removeEvent: function (wind, key, func) {
      var _found = false;
      for (var i = 0; i < this._events.length; i++) {
        var _entry = this._events[i];
        if (_entry.source === wind && _entry.key === key && _entry.func === func) {
          this._events.splice(i, 1);
          _found = true;
          break;
        }
      }
      if (_found === false) {
        throw 'Unsubscribing from an unregistered event!';
      }

      this._doRemoveEvent(wind, key, func);

    },

    unsubscribeAll: function () {
      for (var i = 0; i < this._events.length; i++) {
        var _event = this._events[i];
        this._doRemoveEvent(_event.source, _event.key, _event.func);
      }
      this._events.length = 0;
    },

    addEvent: function (wind, key, func) {
      if (wind.addEventListener) wind.addEventListener(key, func, false);
      if (wind.attachEvent) wind.attachEvent('on' + key, func, false);

      this._events.push({
        source: wind,
        key: key,
        func: func
      });

      return func;
    },

    addEventToSource: function (wind, event, func) {
      return this.addEvent(wind, 'message', func);
    },

    removeEventFromSource: function (wind, event, func) {
      return this.removeEvent(wind, 'message', func);
    }
  };
})();
