(function () {
  'use strict';
  var boundObjects = require('./shared/boundObjects');
  var mergeProps = require('./shared/mergeprops');
  var commandline = false;
  var apptitlebar = null;
  var props = [
    'Title',
    'Summary',
    'HelpURL',
    'TrainingURL'
  ];
  var api = {
    init: function (initObj) {
      var _properties = {};
      //set commandLine flag, used to build component description
      commandline = initObj.commandline || (!!initObj.Toolbar);
      this.commandline = commandline;
      this.apptitlebar = initObj.AppTitleBar || apptitlebar;
      this.appmenu = initObj.AppMenu || {};

      // Sets the app window title in Eikon-4 including individual apps in a Layout configuration.
      // Sets the document.title property in Eikon-web/Eikon-5/ & apps in a Layout configurations.
      if (initObj.Title) {
        JET.updateTitle(initObj.Title);
      }

      // Set properties from initObj
      _properties.NavigationSupport = {
        value: initObj.NavigationSupport || '98',
        type: 'xs:string',
        access: 'rw'
      };

      props.forEach(function (p) {
        _properties[p] = {
          value: initObj[p] || '',
          type: 'xs:string',
          access: 'ro' //support rw
        };

        if (initObj[p]) {
          delete initObj[p];
        }
      });

      // add custom properties
      if (initObj.Properties) {
        _properties = mergeProps(_properties, initObj.Properties);
        delete initObj.Properties;
      }

      if (_properties.HelpURL) boundObjects.helpurl = _properties.HelpURL;
      if (_properties.TrainingURL) boundObjects.trainingurl = _properties.TrainingURL;
      if (initObj.Context) boundObjects.context = initObj.Context;
      // finally set properties, the properties would be emitted each time the loop goes over the array
      boundObjects.properties = _properties;
    }
  };

  module.exports = api;
})();