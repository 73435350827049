(function () {
  'use strict';

  /**
   * AppContainerShim - Handles messages to and from EikonJET instance.
   */
  var version = require('../package.json').version;
  var DesktopXML = require('./DesktopXML');
  var LogMixin = require('./Logger');
  var windowEvents = require('./windowEvents');
  var AppContainerThinMixin = require('./AppContainerThin');
  var BoundObjects = require('./boundObjects');
  var boundObjects;
  var frameManager;
  var unsubscribeAndUnload;
  var bus = void 0;
  var registered = false;
  var _instanceId = 'unknown' + Math.random() * 500;
  var _appId = null;
  var isWeb = false;
  var eikonNow; // reference to EikonNow instance, can be undefined for the lifetime of the program
  var aWindow; // reference to window object, it is not explicitly reference to window so ACS can be tested
  var frameId = 1;

  function AppContainerShim(options) {
    // try putting this into the init call...
    options = options || {};
    isWeb = options.isWeb || false;
    eikonNow = options.eikonNow;
    frameManager = options.frameManager;
    aWindow = options.aWindow;
    this.frames = {};
    this.version = version;
    this.eventMap = {}; // todo rename
    this._pipeSubscriptions = {};

    // basic mapping for desktop translation
    this.jetToMap = {
      'onLog': {},
      'onLoad': {},
      'onPropertyChange': {},
      'onNavigate': {},
      'onContextChange': {},
      'onUnload': {},
      'Session': {},
      'onSaveToStore': {},
      'persistdata': {}
    };

    this.jetFromMap = {
      'onContextChange': {},
      'onPropertyChange': {},
      'Description': {},
      'UserInfo': {},
      'ContainerInformation': {},
      'fromTransferData': {}
    };
    LogMixin(this, frameManager.isDesktop());
    this.init(options);
  }

  AppContainerShim.prototype = {

    // get frames mapped to channel
    getIdsOnChannel: function (channel) {
      if (channel) return this.eventMap[channel];
      return void 0;
    },

    addIdToChannel: function (id, channel) {
      if (id && channel) {
        var connectedRouters = this.getIdsOnChannel(channel);
        if (connectedRouters) {
          return connectedRouters.push(id);
        }
        else {
          var _event = [id];
          this.eventMap[channel] = [id];
          return _event;
        }
      }
    },

    setTopACSRegistered: function () {
      registered = true;
      this.__registering = false;
    },

    init: function () {
      var ctx = this;
      ctx.getData = ctx.getDataSync;

      this._bindDesktopXML(DesktopXML(aWindow));
      boundObjects = BoundObjects();

      // try to access top, could throw an error
      try {
        // top will be persisted across sub window reloads
        // so this has to be stored as a variable to avoid memory leak
        if (!unsubscribeAndUnload) {
          unsubscribeAndUnload = function () {
            windowEvents.removeEvent(frameManager.getFrame(), 'unload', unsubscribeAndUnload);
            ctx.unRegister();
          };
          windowEvents.addEvent(frameManager.getFrame(), 'unload', unsubscribeAndUnload);
        }
      } catch (e) {
        // could not access top, because maybe on a different origin
        var _handler = function () {
          windowEvents.removeEvent(frameManager.getWindow(), 'unload', _handler);
          ctx.unRegister();
        };
        windowEvents.addEvent(frameManager.getWindow(), 'unload', _handler);
      }

    },

    setBus: function (aBus) {
      bus = aBus;
    },

    findContainer: function (w) {
      function checkForDesktop(w) {
        try {
          return (w.registerWithCCFContainer || w.registerWithAsyncCCFContainer || w.registerWithJET || w.eikonLinkReady) && typeof (w.registerWithCCFContainer || w.registerWithAsyncCCFContainer || w.registerWithJET || w.eikonLinkReady) === "function";
        } catch (ex) {
          return false;
        }
      }

      return (checkForDesktop(w) ? w : w.parent && w.parent !== w ? this.findContainer(w.parent) : window);
    },

    _registerJETApp: function (JETAppData) {
      var context = this;
      var params = { jet_data: JETAppData };
      var dfd = {};
      var promise = new Promise(function (resolve, reject) {
        dfd.resolve = resolve;
        dfd.reject = reject;
      });
      var options;
      var localhostAppId = JETAppData.xmlData && JETAppData.xmlData.appId ? JETAppData.xmlData.appId : null;

      // if there is another registered app unregister it.

      // init registration
      // most likely in desktop if eikonNow is disabled
      if (!eikonNow || (eikonNow && !eikonNow.isEnabled())) {

        var registerWithJET = window.registerWithJET;
        try {
          registerWithJET = aWindow.top.registerWithJET;
        } catch (e) {
        }

        JETAppData.xmlData = this._toContainer('Session', JETAppData.xmlData).xmlData;

        if (registerWithJET) {
          var tw = this.findContainer(aWindow);

          // called when registerWithJET is successfully called
          tw.onCEFChannel = function (data) {
            try {
              // top might not be available
              context._eikonJET = aWindow.top.EikonJET;
              if (typeof context._eikonJET.getDataAsync === 'function') { context.getData = context.getDataAsync; }
            } catch (e) {
            }

            // tells frame we registered & send persisted data if there some
            context._initJETApp(data).then(function (containerDescription) {
              params.containerDescription = containerDescription;

              if (context.loggingSource) context.loggingSource('JET_APP_CONTAINER');

              context.getPersistedJETData()
                .then(function (persistedData) {
                  params._topLevel = true;
                  params.persistData = persistedData;

                  // instance id
                  try {
                    _instanceId = params.containerDescription.windowInfo.windowId;
                  } catch (e) {
                    context.debug('no windowId, stability may suffer');
                    _instanceId = null;
                  }

                  // app id
                  try {
                    var appInfo = JSON.parse(params.containerDescription.properties[1].AppInfo);
                    _appId = appInfo.AppId;
                  } catch (e) {
                    _appId = null;
                    if (localhostAppId && (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1")) {
                      // overrite it if appId is set in JET.init function
                      _appId = localhostAppId;
                    }
                    context.debug('appId is ', _appId ? _appId : "null");
                  }

                  context._eikonJET.onMessage(context._onProcessEvent.bind(context));
                  context._eikonJET.onRequest(context._onGetData.bind(context));
                  // now JET Apps may register
                  context.debug('_registerJETApp: ', params);
                  dfd.resolve(params);

                }, function (reject) {
                  dfd.reject(reject);
                });
            });
          };

          var initializeData = JSON.stringify(JETAppData).replace(/\\/g, '\\\\\\');
          registerWithJET(initializeData);
          this.setTopACSRegistered(true);
        } else {
          dfd.reject('could not find registerWithJET function');
        }
      } else if (eikonNow && eikonNow.isEnabled()) {

        // EikonWeb
        options = {
          JETAppData: JETAppData,
          dfd: dfd
        };

        AppContainerThinMixin.call(context, options);
        this.setTopACSRegistered(true);

      } else {
        // something wrong... this happens when two apps attempt to register at the same time.
        dfd.reject('could not register with JET (no registerWithJETCall and no EikonWeb)');
      }

      return promise;
    },

    /**
     * Registers a JET Application with EikonJET. Gets container information and persisted data.
     * This should only fire once and only fire in desktop mode.
     * @param JETApp
     * @private
     */
    _initJETApp: function (data) {
      var context = this;
      var containerDescription = this._fromContainerData('Description', data);

      if (containerDescription) {

        // fall back todo: investigate if this is still needed
        if (!this._eikonJET) {
          var plugin;
          plugin = document.createElement('embed');
          plugin.type = 'application/x-jetPlugin';
          plugin.width = 0;
          plugin.height = 0;
          plugin.id = 'jetPlugin';

          // To make sure that the <embed> tag will not show on the page
          plugin.style.position = 'absolute';
          plugin.style.opacity = '0';

          document.body.appendChild(plugin);
          this._eikonJET = plugin.jetPlugin();
        }

        // register this ACS instance with EikonJET
        this._eikonJET.init(containerDescription.plugin.channel);
        this.debug('App Container registered with EikonJET!');
      }

      // Send the data to the top level JET App's router and will pass this data to the onRegister function
      return new Promise(function (resolve, reject) {
        if (containerDescription) resolve(containerDescription);
        else reject('could not get container description.');
      });
    },

    //
    getPersistedJETData: function () {
      var context = this;
      return context.getData({
        name: 'persistdata',
        xmlData: null
      });
    },

    // binds desktop xml mapping to the app container shim.
    _bindDesktopXML: function (DesktopXML) {
      this.jetFromMap = DesktopXML.fromMap;
      this.jetToMap = DesktopXML.toMap;
    },

    // desktop translation
    _fromContainer: function (event, data) {
      var jetFromMap = this.jetFromMap;
      var ev = data ? {
        name: event,
        data: data
      } : event;

      return {
        name: ev.name,
        data: jetFromMap[ev.name] && jetFromMap[ev.name].format ?
          jetFromMap[ev.name].format(ev.data || ev.xmlData) : (ev.data || ev.xmlData),
        channel: ev.channel
      };
    },

    _fromContainerData: function (event, data) {
      return this._fromContainer(event, data).data;
    },

    _fromContainerAsync: function (event, data, resolve) {
      var context = this;
      return new Promise(function (resolve, reject) {
        resolve(context._fromContainer(event, data));
      });
    },

    _toContainer: function (event, data) {
      var jetToMap = this.jetToMap;
      var ev = data ? {
        name: event,
        data: data
      } : event;

      return {
        name: ev.name,
        xmlData: (jetToMap[ev.name] && jetToMap[ev.name].format) ? jetToMap[ev.name].format(ev.data) : ev.data,
        channel: ev.channel,
        target: ev.target || '', // todo: may not be needed
        pipe: ev.pipe || false,
        instanceId: ev.instance || '' //todo: may not be needed, if so delete
      };
    },

    _toContainerAsync: function (event, data) {
      var context = this;
      return new Promise(function (resolve, reject) {
        resolve(context._toContainer(event, data));
      });
    },

    _publish: function (publish) {
      publish = publish || {};
      this.debug('_publish: ' + JSON.stringify(publish));

      this._processEvent(void 0, {
        name: 'Publish',
        data: publish.data,
        channel: publish.channel
      });
    },

    _publishWithPipeTarget: function (publish) {
      publish = publish || {};
      // todo optimize this... stringify is always called.
      this.debug('_publishWithPipeTarget: ', +JSON.stringify(publish));

      var data = {
        name: 'Publish',
        data: publish.data,
        channel: publish.channel,
        target: publish.target,
        pipe: publish.pipe || false
      };

      // Legacy JET does not know how to process targeted messages and will break.
      if (publish.target) {
        try {
          var newData = {};
          newData = JSON.stringify({
            targetData: data.data,
            targetId: publish.target
          });
          data.data = newData;
        } catch (e) {
        }
      }

      this._processEvent(void 0, data);
    },

    // send a message to a given channel
    _send: function (send) {
      send = send || {};
      this._processEvent(void 0, {
        name: 'Publish',
        data: send.data,
        channel: send.channel,
        target: send.target,
        pipe: true
      });
    },


    // todo will we have to handle to unregister case?
    // todo (security) assert origin is valid
    _registerFrame: function (source) {
      var frameWindow = source;
      var frameId = makeFrameId();

      return this._setFrame(frameId, frameWindow);
    },


    // todo improve ... assumming same origin for now (http://stackoverflow.com/questions/15329710/postmessage-source-iframe)
    _getFrame: function (source) {
      var keys = Object.keys(this.frames);
      var numKeys = keys.length;
      var frame = void 0;
      for (var i = 0; i < numKeys; i++) {
        if (source === this.frames[keys[i]].window) {
          frame = this.frames[keys[i]];
          break;
        }
      }

      return frame;
    },

    _setFrame: function (frameId, frameWindow) {
      this.frames[frameId] = { id: frameId, window: frameWindow };
      return this.frames[frameId];
    },

    _removeFrame: function (frameId) {
      delete this.frames[frameId];
      return this;
    },

    // maps pub/sub with a channel or a simple event such as 'onContextChange', returns an array of subscribed frames
    // only subscribe if not other routers have been subscribed to a specific channel
    _mapEventToFrame: function (frameId, channel) {
      var connectedRouters;
      if (channel) {
        connectedRouters = this.getIdsOnChannel(channel);
        if (!connectedRouters) connectedRouters = this.addIdToChannel(frameId, channel);
        else {
          var alreadyInConnected = false;
          var numberConnected = connectedRouters.length;

          for (var i = 0; i < numberConnected; i++) {
            var id = connectedRouters[i];
            if (frameId === id) {
              alreadyInConnected = true;
              break;
            }
          }

          if (!alreadyInConnected) connectedRouters.push(frameId);
        }
      }

      return connectedRouters;
    },

    _subscribeWithPipe: function (source, subscription) {
      var frame = this._getOrRegisterSource(source);
      var subscriptions = this._mapEventToFrame(frame.id, subscription.channel);
      this._emitSubscribeWithPipe(subscriptions, subscription);
    },

    _emitSubscribeWithPipe: function (subscriptions, subscription) {
      if (subscriptions) {
        if (!subscriptions.isProcessed) {

          var vent = {
            name: 'Subscribe',
            channel: subscription.channel,
            pipe: true
          };
          this._processEvent(void 0, vent);
          subscriptions.isProcessed = true;

        }
      }
    },

    _subscribeWithPipeTarget: function (source, subscription) {
      var frame = this._getOrRegisterSource(source);
      var subscriptions = this._mapEventToFrame(frame.id, subscription.channel);
      this._emitSubscribeWithPipeTarget(subscriptions, subscription);
    },

    _emitSubscribeWithPipeTarget: function (subscriptions, subscription) {
      if (subscriptions) {
        if (!subscriptions.isProcessed) {

          var vent = {
            name: 'Subscribe',
            channel: subscription.channel,
            target: subscription.target,
            pipe: true
          };
          this._processEvent(void 0, vent);

          if (subscription.requestSubscribeWithPipe) {
            var pipedata = subscription.requestSubscribeWithPipe;
            var pubData = {
              channel: subscription.channel,
              target: subscription.target,
              data: subscription.data
            };

            var matches = /(.*)(\/onSubscribe)$/g.exec(pipedata.channel);
            if (matches && matches.length) {
              this._pipeSubscriptions[subscription.channel] = {
                channel: matches[1],
                target: subscription.target,
                data: pubData
              };
            }
            this._processEvent(void 0, {
              name: 'Publish',
              data: JSON.stringify(pubData),
              target: subscription.target,
              channel: pipedata.channel,
              pipe: true
            });
          }

          subscriptions.isProcessed = true;

        }
      }
    },


    // frame is a router obj with id and ref. to its window
    // subscription is an obj with a channel
    _subscribe: function (source, subscription) {
      this.debug('_subscribe: ' + JSON.stringify(subscription));

      var frame = this._getOrRegisterSource(source);
      var channel = subscription.channel;

      var subscriptions = this._mapEventToFrame(frame.id, channel);
      this._emitSubscribe(subscriptions, channel);
    },

    // emits a processevent for subscriptions/publishing
    _emitSubscribe: function (subscriptions, channel) {
      if (subscriptions) {
        if (!subscriptions.isProcessed) {

          var vent = {
            name: 'Subscribe',
            data: '',
            channel: channel
          };

          this._processEvent(void 0, vent);
          subscriptions.isProcessed = true;
        }
      }
    },

    // remove a frame from a subscription channel
    // if the channel has length zero emit an unsubscription event
    _unsubscribe: function (source, event) {
      var frame = this._getFrame(source);

      if (frame) {

        // removes only one frame by id
        var channel = event.channel;

        var subscribedFrames = this.getIdsOnChannel(channel);
        if (subscribedFrames) {
          var index = subscribedFrames.indexOf(frame.id);
          if (index >= 0) subscribedFrames.splice(index, 1);
          this._emitUnsubscribe(event);
        }

      }
    },

    // the router should take care of calling unsub. callbacks
    _emitUnsubscribe: function (event) {
      var channel = event.channel;

      if (this.getIdsOnChannel(channel).length === 0) {
        var _subData = this._pipeSubscriptions[channel];
        if (_subData) {
          this._processEvent(void 0, {
            name: 'Publish',
            data: JSON.stringify(_subData.data),
            target: _subData.target,
            channel: _subData.channel + '/onUnsubscribe',
            pipe: true
          });
          delete this._pipeSubscriptions[channel];
        }

        this._processEvent(void 0, event);

        // finally delete it
        delete this.eventMap[channel];
      }
    },

    // event delegation

    _wrapAsync: function (msg) {
      if (msg && msg.async) {
        var oldAsync = msg.async;
        var context = this;

        msg.async = function (res) {
          oldAsync(res ? context._fromContainer(msg.name, res) : res);
        };
      }
    },

    // to eikonjet

    // todo rename this argument to event instead of message
    _processEvent: function (source, msg) {
      // warning: Do not put debug statements here in Desktop, or program will become stuck in an infinite loop.
      this._wrapAsync(msg);
      msg = this._toContainer(msg);

      // service targeting
      if (_stringStartsWith(msg.target, '!')) {
        if (msg.xmlData) {
          try {
            // sets the out going msg with the window id which will be processed by the service and used to return data to it
            var data = JSON.parse(msg.xmlData);
            data.instanceId = _instanceId;
            data.appId = _appId;
            msg.xmlData = JSON.stringify(data);
          } catch (e) {
          }
        }
      }

      // unregister acs - top level JET App is unregistering, intercept the final call to ACS and remove the frame
      // from memory
      if (msg.name === 'onUnload') {
        this.setTopACSRegistered(false);

        // remove the frame from the sources, assume that the sub app has done unsubscribeAll()
        if (source) {
          var frame = this._getFrame(source);
          if (frame) this._removeFrame(frame.id);
        }

      }

      msg = JSON.stringify({
        name: msg.name,
        data: msg.xmlData,
        method: 'processEvent',
        channel: msg.channel,
        pipe: msg.pipe || false
      });

      this._eikonJET.post(msg);
    },

    // from eikonjet
    // transforms the event into an object then delegates it to the appropriate frame(s)
    _onProcessEvent: function (event) {
      var targetApp;

      this.debug('_onProcessEvent: ' + JSON.stringify(event));

      if (typeof event === 'string') event = JSON.parse(event);

      event = this._fromContainer(event);

      // targeting
      try {
        var data = JSON.parse(event.data);
        var target = data.target;
        var targetId = data.targetId;

        if (target || targetId) {
          // a response from a service module
          if (targetId) {
            // attempt to route it
            if (targetId === _instanceId || _stringStartsWith(targetId, '!')) {
              // get the targeted data off of the message
              event.data = data.targetData;
              this._sendProcessEventMessage(event);
            }
            // send or drop message
            return;
          } else {
            // a message sent to a service module from an app
            if (_stringStartsWith(target, '!')) {
              targetApp = data.instanceId;
              if (targetApp === _instanceId || _stringStartsWith(target, '!')) {
                this._sendProcessEventMessage(event);
              }
              return;
            } else {
              // drop message
              return;
            }
          }

        }
      } catch (e) {
        // not targeted continue
      }

      // generic message
      this._sendProcessEventMessage(event);
    },

    _sendProcessEventMessage: function (event) {
      var ids = this.getIdsOnChannel(event.channel);
      var context = this;

      if (ids) {
        ids.forEach(function (id) {
          var destination = context.frames[id].window;
          bus.sendMessage(destination, {
            parseKey: 'jet:msg',
            params: [event],
            funcName: 'doEvent'
          });
        });
      } else if (event.channel === '') {
        // broadcast
        var frames = this.frames;
        var keys = Object.keys(frames);
        var numKeys = keys.length;
        // examine the case where we received a processevent from an unregistered
        for (var i = 0; i < numKeys; i++) {
          var frame = frames[keys[i]];
          bus.sendMessage(frame.window, {
            parseKey: 'jet:msg',
            params: [event],
            funcName: 'doEvent'
          });
        }
      } else {
        // acs could not find a message for the frame ... noop
        // or message was intended to broadcast in eweb
        // detect unregistered frames
        try {
          if (isWeb) {

            bus.sendMessage(aWindow, {
              funcName: 'doEvent',
              params: [event]
            });

          } else if (aWindow.top.frames.length) {
            var top = aWindow.top;

            var length = top.frames.length;
            for (var j = 0; j < length; j++) {
              var currentFrame = top.frames[j];
              // todo cache/memoize
              var frameKeys = Object.keys(this.frames);
              var numberRegisteredFrames = frameKeys.length;
              var knownFrame = false;

              for (var k = 0; k < numberRegisteredFrames; k++) {
                if (currentFrame === this.frames[frameKeys[k]].window) {
                  // it is a registered frame, do not send a message to it
                  knownFrame = true;
                  break;
                }
              }

              // this is a frame we don't know about just send the message
              // there, most likekly a JET1 app or something else...
              if (!knownFrame) {
                if (currentFrame.JET && currentFrame.JET.callEventHandler) {
                  try {
                    currentFrame.JET.callEventHandler(event);
                  } catch (e) {
                    // truly drop the frame
                  }
                }
              }
            }
          }
        } catch (ex) {
        }

      }
    },

    _onProcessEventWithSource: function (source, event) {
      var _event = this._fromContainer(event);
      bus.sendMessage(source, {
        parseKey: 'jet:msg',
        params: [_event],
        funcName: 'doEvent'
      });
    },

    getDataAsync: function (request) {
      request = request || {};
      var context = this;

      var name = request.name;
      var response;

      if (typeof name !== 'undefined') {
        return new Promise(function (resolve, reject) {
          context._eikonJET.getDataAsync(name, resolve, reject);
        }).then(function(res){
          if(res){
             return context._fromContainerData(request.name, res);
          } else return res;
        }).catch(function(e) {
          context.error("getDataAsync failed: " + JSON.stringify(e));
        });
      } else {
        context.error('There was no name provided in the getData request.');
        return Promise.resolve(null);
      }
    },

    getDataSync: function (r) {
      var context = this;

      return new Promise(function (resolve, reject) {
        context._wrapAsync(r);
        var res = null;
        var msg = JSON.stringify({
          name: r.name,
          data: r.xmlData,
          method: 'getData'
        });

        try {
          res = context._eikonJET.send(msg);
        } catch (e) {
          context.error(e);
        }

        if (res) res = context._fromContainerData(r.name, res);

        resolve(res);
      });
    },

    // todo ?
    _onGetData: function (r) {
      var _parsed = {};
      if (typeof r === 'string') {
        _parsed = JSON.parse(r);
      }
      if (!_parsed.name) {
        return null;
      }

      var h = boundObjects.dataProviders[_parsed.name];

      if (h !== null) {
        var _data = h.call(this, _parsed.xmlData);
        return this._toContainer(_parsed.name, _data).xmlData;

      } else return null;
    },

    // updates the logging level
    // can be updated via postMessage
    _setLoggingLevel: function (level) {
      this.setLogOptions(level);
    },

    _setBoundedProp: function (prop) {
      var boundObject = boundObjects[prop.name];
      if (boundObject && boundObject.hasOwnProperty(prop.key)) boundObject[prop.key] = prop.value;
    },

    _getBoundedProp: function (name, key) {
      return boundObjects[name][key];
    },

    _getOrRegisterSource: function (frame) {
      var source = this._getFrame(frame);
      if (!source) source = this._registerFrame(frame);
      return source;
    },

    _unload: function () {
      // we should not use getPersistedJETData here as it calls Container to get its persistdata
      // and it leads to performance issues for News Monitor + process affinity in Eikon 4.0.31 (http://www.iajira.amers.ime.reuters.com/browse/NEWS-12283)
      // we can just use JET archive data available and synchronized through boundObjects
      this._processEvent(void 0, {
          data: this._getBoundedProp('dataProviders', 'persistdata')(),
          name: 'onUnload'
      });
    },

    unRegister: function () {
      if (registered) {
        var context = this;
        this.unsubscribeAll();
        this._unload();
        // remove event listeners on the window to prevent memory leaks
        if (typeof bus !== 'undefined' && bus.dispose) {
          bus.dispose();
        }
        windowEvents.unsubscribeAll();
        registered = false;
      }
    },

    unsubscribeAll: function () {
      var channels = Object.keys(this.eventMap);
      var numberOfChannels = channels.length;

      for (var i = 0; i < numberOfChannels; i++) {
        var channel = channels[i];
        this.eventMap[channel] = [];
        this._emitUnsubscribe({
          name: 'Unsubscribe',
          data: '',
          channel: channel
        });
      }
      this.eventMap = {};

    }
  };

  function makeFrameId() {
    return frameId++;
  }

  function _stringStartsWith(string, prefix) {
    if (String.prototype.startsWith) {
      return string.startsWith(prefix);
    }
    else {
      return string.slice(0, prefix.length) === prefix;
    }
  }

  module.exports = AppContainerShim;
})();
