(function () {
  'use strict';
  // a property that emits a change event to the ACS when it is changed
  // beforeSet is a function that is fired with the new value before it is
  // stored on the object
  module.exports = function EmittedProp(obj, key, objectName, beforeSet) {
    var value = void 0;
    Object.defineProperty(obj, key, {
      get: function () {
        return value;
      },
      set: function (newValue) {
        beforeSet(objectName, key, newValue);
        value = newValue;
      }
    });
  };
})();