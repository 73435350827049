/**
 * Handles JET Desktop XML Mappings
 * @constructor
 */
(function () {
  'use strict';

  function DesktopXML(aWindow) {

    function mixin(obj) {
      function mix(target, source) {
        //var name, s;
        for (var propName in source) {
          target[propName] = source[propName];
        }
        return target; // Object
      }

      for (var i = 1, l = arguments.length; i < l; i++) {
        mix(obj || {}, arguments[i]);
      }
      return obj; // Object
    }

    // Helper function
    function mergeArrayPropsToObjProps(objProperties, arrayProperties) {
      for (var i = 0; i < arrayProperties.length; i++) {
        var prop = arrayProperties[i];

        var name = null;
        var value = null;

        if (prop.name) {
          name = prop.name;
          value = prop.text || prop.value || "";
        } else {
          for (var field in prop) {
            if (prop.hasOwnProperty(field)) {
              name = field;
              value = prop[field] || "";
              break;
            }
          }
        }

        if (name !== null && value !== null) {
          objProperties[name] = {
            value: value,
            type: prop.type || "xs:string",
            access: prop.access === "ro" ? "ro" : "rw"
          };
        }
      }
    }

    /******************
     * XML Conversion Functions
     ******************/

    var DOMParser = aWindow.DOMParser ? new aWindow.DOMParser() : null;

    function wrapCData(input) {
      return "<![CDATA[" + unwrapCData(input) + "\]\]\>";
    }

    function unwrapCData(input) {
      var re = /<\!\[CDATA\[|\]\]>/gi;
      return input.replace(re, '');
    }

    function _present(v, t) {
      if (v === undefined || v !== v) { // if v is undefined or NaN
        return false;
      } else if (t == "array") {
        return Array.isArray(v);
      } else {
        return (typeof v === t);
      }
    }

    function _req(item, propNames, type) {
      if (!Array.isArray(propNames)) {
        propNames = [propNames];
      }

      propNames.forEach(function (p) {
        if (!_present(item[p], type))
          throw new Error(p + " field is not specified!");
      });
    }

    function _reqa(item, propNames, min, max) {
      if (min === undefined || min === null) {
        min = 1;
      }
      if (max === undefined || max === null) {
        max = Number.POSITIVE_INFINITY;
      }
      if (!Array.isArray(propNames)) {
        propNames = [propNames];
      }

      propNames.forEach(function (p) {
        if (_present(item[p], "array")) {
          if (item[p].length < min || item[p].length > max) {
            throw new Error("Array " + p + " out of range(" + min + ", " + max + ")");
          }
        }
      });
    }

    function _oneOf(v, values) {
      for (var i = 0; i < values.length; i++) {
        if (v == values[i])
          return;
      }
      throw new Error("Field has invalid value: " + v);
    }

    function _escapeString(/*String*/ str) {
      if (str.indexOf("<![CDATA[") > -1) return str;
      // summary: function escapeString. author: David Joham djoham@yahoo.com
      // str: string
      //      The string to be escaped
      // returns: The escaped string
      str = str.replace(/&/g, "&amp;");
      str = str.replace(/</g, "&lt;");
      str = str.replace(/>/g, "&gt;");
      str = str.replace(/"/g, "&quot;");
      str = str.replace(/'/g, "&apos;");
      return str; // String
    }

    function _getLocationXML(nodeName, locationData) {
      var xml = "<" + nodeName;
      if (_present(locationData.x, "number"))
        xml += " x=\"" + locationData.x + "\"";
      if (_present(locationData.y, "number"))
        xml += " y=\"" + locationData.y + "\"";
      if (_present(locationData.width, "number"))
        xml += " width=\"" + locationData.width + "\"";
      if (_present(locationData.height, "number"))
        xml += " height=\"" + locationData.height + "\"";
      if (_present(locationData.relative, "boolean"))
        xml += " relative=\"" + locationData.relative + "\"";
      xml += " />";
      return xml;
    }

    function _getEventDataXML(nodeName, data) {
      var xml = "<" + nodeName + ">";
      if (data.aUrl)
        xml += ("<Anchor url=\"" + _escapeString(data.aUrl) + "\" />");
      if (data.imgUrl)
        xml += ("<Image url=\"" + _escapeString(data.imgUrl) + "\" />");
      if (data.params)
        xml += _getParamsXML("Params", data.params);
      if (data.mouse)
        xml += _getMouseXML("Mouse", data.mouse);
      if (data.entities && data.entities.length)
        xml += _getContextDataXML("Entities", data.entities);
      if (data.menu)
        xml += _getCommandBarsXML("ContextMenu", data.menu);
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getParamsXML(nodeName, params) {
      var xml = "<" + nodeName;
      if (_present(params.noSystemMenu, "boolean"))
        xml += " noSystemMenu=\"" + params.noSystemMenu + "\"";
      return xml + " />";
    }

    function _getMouseXML(nodeName, mouseData) {
      var xml = "<" + nodeName;
      if (_present(mouseData.screenX, "number"))
        xml += " screenx=\"" + mouseData.screenX + "\"";
      if (_present(mouseData.screenY, "number"))
        xml += " screeny=\"" + mouseData.screenY + "\"";
      if (_present(mouseData.clientX, "number"))
        xml += " clientx=\"" + mouseData.clientX + "\"";
      if (_present(mouseData.clientY, "number"))
        xml += " clienty=\"" + mouseData.clientY + "\"";
      return xml + " />";
    }

    function _getEntityXML(nodeName, e) {
      var entity = {};
      mixin(entity, e);
      var xml = "<" + nodeName;
      if (_present(entity.type, "string"))
        xml += (" type=\"" + _escapeString(entity.type) + "\"");
      delete entity.type;
      if (_present(entity.dataSource, "string"))
        xml += (" datasource=\"" + _escapeString(entity.dataSource) + "\"");
      xml += ">";
      delete entity.dataSource;
      if (entity.fields) {
        for (var field in entity.fields) {
          xml += "<Field name=\"" + _escapeString(field) + "\">";
          if (_present(entity.fields[field], "string"))
            xml += _escapeString(entity.fields[field]);
          xml += "</Field>";
        }
      }
      delete entity.fields;
      if (entity.parameters) {
        for (var parameter in entity.parameters) {
          xml += "<Parameter name=\"" + _escapeString(parameter) + "\">";
          if (_present(entity.parameters[parameter], "string"))
            xml += _escapeString(entity.parameters[parameter]);
          xml += "</Parameter>";
        }
      }
      delete entity.parameters;
      if (entity.entities) {
        _req(entity, "entities", "array");
        entity.entities.forEach(function (item) {
          //switch (item.item) {
          //case "Entity":
          xml += _getEntityXML("Entity", item);
          //break;
          //}
        });
      }
      delete entity.entities;
      for (var propName in entity) {
        if (propName in {
            "NavigationNS": "",
            "SelectionNS": "",
            "Transaction": ""
          }) {
          xml += "<Field name=\"" + _escapeString(propName) + "\">";
          if (_present(entity[propName], "string"))
            xml += _escapeString(entity[propName]);
          xml += "</Field>";
        } else if (propName !== "IdentifierList" && propName !== "FieldList") {
          xml += "<Identifier namespace=\"" + _escapeString(propName) + "\">";
          if (_present(entity[propName], "string"))
            xml += _escapeString(entity[propName]);
          xml += "</Identifier>";
        }
      }

      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getContextDataXML(nodeName, contextData) {
      var xml = "<" + nodeName + ">";
      if (contextData.length > 0) {
        contextData.forEach(function (e) {
          xml += _getEntityXML("Entity", e);
        });
      }
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getCommandBarDefaultAttrsXML(item) {
      _req(item, "id", "string");
      var xml = " ID=\"" + _escapeString(item.id) + "\"";

      if (_present(item.tooltip, "string")) {
        xml += " tooltip=\"" + _escapeString(item.tooltip) + "\"";
      }

      if (_present(item.icon, "string")) {
        xml += " icon=\"" + _escapeString(item.icon) + "\"";
      }

      if (_present(item.enabled, "boolean")) {
        xml += " enabled=\"" + item.enabled + "\"";
      }

      if (_present(item.description, "string")) {
        xml += " description=\"" + _escapeString(item.description) + "\"";
      }

      if (_present(item.iconURL, "string")) {
        xml += " iconURL=\"" + _escapeString(item.iconURL) + "\"";
      }

      if (_present(item.beginGroup, "boolean")) {
        xml += " beginGroup=\"" + item.beginGroup + "\"";
      }

      if (_present(item.groupName, "string")) {
        xml += " groupName=\"" + _escapeString(item.groupName) + "\"";
      }

      return xml;
    }

    function _getButtonAttrsXML(item) {
      var xml = _getCommandBarDefaultAttrsXML(item);
      _req(item, "caption", "string");
      xml += " caption=\"" + _escapeString(item.caption) + "\"";
      if (_present(item.checked, "boolean"))
        xml += " checked=\"" + item.checked + "\"";
      if (_present(item.multiSelect, "boolean"))
        xml += " multiSelect=\"" + item.multiSelect + "\"";
      if (_present(item.type, "string")) {
        _oneOf(item.type, ["iconOnly", "iconAndCaption", "captionOnly"]);
        xml += " type=\"" + item.type + "\"";
      }
      return xml;
    }

    function _getControlAttrsXML(control) {
      var xml = _getCommandBarDefaultAttrsXML(control);
      if (_present(control.caption, "string"))
        xml += " caption=\"" + _escapeString(control.caption) + "\"";
      return xml;
    }

    function _getButtonXML(button) {
      var xml = "<Button" + _getButtonAttrsXML(button) + " />";
      return xml;
    }

    function _getSystemMenuXML(button) {
      var xml = "<SystemMenu" + _getSystemMenuAttrsXML(button) + " />";
      return xml;
    }

    function _getComboBoxXML(comboBox) {
      var xml = "<ComboBox" + _getControlAttrsXML(comboBox);
      if (_present(comboBox.type, "string")) {
        _oneOf(comboBox.type, ["dropDown", "comboBox"]);
        xml += " type=\"" + comboBox.type + "\"";
      }
      if (_present(comboBox.dropDownWidth, "number"))
        xml += " maxlength=\"" + comboBox.dropDownWidth + "\"";
      xml += " >";
      if (comboBox.items) {
        comboBox.items.forEach(function (item) {
          _req(item, "caption", "string");
          xml += "<ListItem caption=\"" + _escapeString(item.caption) + "\"";
          if (_present(item.selected, "boolean"))
            xml += " selected=\"" + item.selected + "\"";
          if (_present(item.id, "string"))
            xml += " ID=\"" + _escapeString(item.id) + "\"";
          xml += " />";
        });
      }
      xml += "</ComboBox>";
      return xml;
    }

    function _getMenuControlsXML(menuItem) {
      var xml = "";
      if (menuItem.items) {
        menuItem.items.forEach(function (item) {
          _req(item, "item", "string");
          switch (item.item) {
            case "Button":
              xml += _getButtonXML(item);
              break;
            case "SplitButton":
              xml += _getSplitButtonXML(item);
              break;
            case "Menu":
              xml += _getMenuXML(item);
              break;
            case "Separator":
              xml += _getSeparatorXML(item);
              break;
            default:
              throw new Error("Invalid item type specified: " + item.item);
          }
        });
      }
      return xml;
    }

    function _getSplitButtonXML(splitButton) {
      var xml = "<SplitButton" + _getButtonAttrsXML(splitButton) + ">";
      _req(splitButton, "items", "array");
      _reqa(splitButton, "items");
      if (splitButton.items)
        xml += _getMenuControlsXML(splitButton);
      xml += "</SplitButton>";
      return xml;
    }

    function _getMenuXML(menu) {
      var xml = "<Menu" + _getButtonAttrsXML(menu) + ">";
      _req(menu, "items", "array");
      _reqa(menu, "items");
      if (menu.items)
        xml += _getMenuControlsXML(menu);
      xml += "</Menu>";
      return xml;
    }

    function _getSystemMenuAttrsXML(menu) {

      _req(menu, "id", "string");
      var xml = " ID=\"" + _escapeString(menu.id) + "\"";

      if (_present(menu.enabled, "boolean"))
        xml += " enabled=\"" + menu.enabled + "\"";

      if (_present(menu.visible, "boolean"))
        xml += " visible=\"" + menu.visible + "\"";

      _reqa(menu, "items");
      if (menu.items)
        xml += _getSystemMenuXML(menu);

      return xml;
    }


    function _getSearchXML(search) {
      var xml = "<Search";
      if (_present(search.id, "string"))
        xml += " ID=\"" + _escapeString(search.id) + "\"";
      xml += "/>";
      return xml;
    }

    function _getSeparatorXML(separator) {
      var xml = "<Separator />";
      return xml;
    }

    function _getEditXML(edit) {
      var xml = "<Edit" + _getControlAttrsXML(edit);
      if (_present(edit.text, "string"))
        xml += " text=\"" + _escapeString(edit.text) + "\"";
      if (_present(edit.maxLength, "number")) {
        if (edit.maxLength < 0)
          throw new Error("Negative value was used for maxLength: " + edit.maxLength);
        xml += " maxlength=\"" + edit.maxLength + "\"";
      }
      xml += " />";
      return xml;
    }

    function _getPrintXML(print) {
      _req(print, "id", "string");
      _oneOf(print.id, ["PRINT"]);
      var xml = "<Print ID=\"PRINT\" />";
      return xml;
    }

    function _getCommandBarXML(nodeName, commandBar) {
      var xml = "<" + nodeName;
      if (_present(commandBar.caption, "string"))
        xml += " caption=\"" + _escapeString(commandBar.caption) + "\"";
      if (_present(commandBar.category, "number"))
        xml += " category=\"" + commandBar.category + "\"";
      xml += ">";
      _req(commandBar, "items", "array");
      _reqa(commandBar, "items");
      if (commandBar.items) {
        commandBar.items.forEach(function (item) {
          _req(item, "item", "string");
          switch (item.item) {
            case "Button":
              xml += _getButtonXML(item);
              break;
            case "SplitButton":
              xml += _getSplitButtonXML(item);
              break;
            case "Menu":
              xml += _getMenuXML(item);
              break;
            case "Separator":
              xml += _getSeparatorXML(item);
              break;
            case "Search":
              xml += _getSearchXML(item);
              break;
            case "Edit":
              xml += _getEditXML(item);
              break;
            case "ComboBox":
              xml += _getComboBoxXML(item);
              break;
            case "Print":
              xml += _getPrintXML(item);
              break;
            case "System":
              xml += _getSystemMenuXML(item);
              break;
            case "Header":
              break;
            default:
              throw new Error("Invalid item type specified: " + item.item);
          }
        });
      }
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getUpdateCommandBarsXML(nodeName, data) {
      var xml = "<" + nodeName + ">";

      if (data.commandBarType && Array.isArray(data.commandBars)) {
        xml += _getCommandBarsXML(data.commandBarType, data.commandBars);
      }

      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getCommandBarsXML(nodeName, commandBars) {
      var xml = "<" + nodeName + ">";

      if (Array.isArray(commandBars)) {
        commandBars.forEach(function (cb) {
          xml += _getCommandBarXML("CommandBar", cb);
        });
      }

      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getPropertyXML(propName, propObj) {
      var xml = "<Property";

      if (propObj.type) {
        xml += " type=\"" + _escapeString(propObj.type) + "\"";
      }

      if (propObj.access) {
        xml += " access=\"" + propObj.access + "\"";
      }

      if (propName) {
        xml += " name=\"" + _escapeString(propName) + "\">";
      }

      if (propObj.value) {
        xml += _escapeString(propObj.value);
      }

      xml += "</Property>";
      return xml;
    }

    function _getPropertiesXML(nodeName, properties) {
      var xml = "<" + nodeName + ">";

      for (var prop in properties) {
        if (properties.hasOwnProperty(prop)) {
          xml += _getPropertyXML(prop, properties[prop]);
        }
      }

      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getPDataXML(data) {
      var xml = "<PData";
      if (_present(data.name, "string"))
        xml += " name=\"" + _escapeString(data.name) + "\"";
      xml += ">";
      if (_present(data.value, "string"))
        xml += wrapCData(data.value);
      xml += "</PData>";
      return xml;
    }

    function _getArchiveDataXML(nodeName, data) {
      data = data || [];
      var xml = "<" + nodeName,
        pData = Array.isArray(data) ? data : data.data;

      if (_present(data.service, "string"))
        xml += " service=\"" + _escapeString(data.service) + "\"";
      if (_present(data.title, "string"))
        xml += " title=\"" + _escapeString(data.title) + "\"";
      if (_present(data.extension, "string"))
        xml += " extension=\"" + _escapeString(data.extension) + "\"";

      xml += ">";
      if (_present(data.cpurl, "string")) {
        xml += "<Url cpurl=\"" + _escapeString(data.cpurl) + "\">";
        xml += _escapeString(_present(data.url, "string") ? data.url : data.cpurl);
        xml += "</Url>";
      } else if (_present(data.url, "string")) {
        xml += "<Url>" + _escapeString(data.url) + "</Url>";
      }

      if (pData) {
        pData.forEach(function (p) {
          xml += _getPDataXML(p);
        });
      }
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getNavigationXML(nodeName, nav) {
      // In order to restore an archived object, it's possible to navigate with the archive data
      // this doesn't make sense... (data handling) => FALSE! It makes sense!
      if (nav.data)
          return _getArchiveDataXML("PersistData", nav);

      var xml = "<" + nodeName;
      if (_present(nav.url, "string"))
        xml += " url=\"" + _escapeString(nav.url) + "\"";
      if (_present(nav.name, "string"))
        xml += " name=\"" + _escapeString(nav.name) + "\"";
      if (_present(nav.target, "string")) {
        xml += " target=\"" + nav.target + "\"";
      }
      xml += ">";
      if (nav.entities)
        xml += _getContextDataXML("Entities", nav.entities);
      if (nav.archive)
        xml += _getArchiveDataXML("PersistData", nav.archive);
      if (nav.location)
        xml += _getLocationXML("Location", nav.location);
      if (nav.properties) {
        var properties = {};
        mergeArrayPropsToObjProps(properties, nav.properties);
        xml += _getPropertiesXML("Properties", properties);
      }

      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getTransferDataXML(nodeName, data) {
      var xml = "<" + nodeName;
      if (data.mouse) {
        if (_present(data.mouse.screenX, "number"))
          xml += " screenx=\"" + data.mouse.screenX + "\"";
        if (_present(data.mouse.screenY, "number"))
          xml += " screeny=\"" + data.mouse.screenY + "\"";
      }
      xml += ">";
      if (data.entities && data.entities.length) {
        xml += "<Data type=\"ContextData\">";
        xml += _getContextDataXML("Entities", data.entities);
        xml += "</Data>";
      }
      if (data.archive) {
        xml += "<Data type=\"PersistData\">";
        xml += _getArchiveDataXML("PersistData", data.archive);
        xml += "</Data>";
      }
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getSearchTargetXML(nodeName, data) {
      var xml = "<" + nodeName;
      if (_present(data.multipleMatches, "boolean"))
        xml += " multipleMatches=\"" + data.multipleMatches + "\"";
      if (_present(data.contextResolution, "boolean"))
        xml += " contextResolution=\"" + data.contextResolution + "\"";
      xml += ">";
      if (data.constraints) {
        data.constraints.forEach(function (c) {
          _req(c, "namespace", "string");
          xml += "<Constraint namespace=\"" + c.namespace + "\">";
          if (c.contextDataTransformation !== null && c.contextDataTransformation !== undefined) {
            xml += "<ContextDataTransformation>" + c.contextDataTransformation + "</ContextDataTransformation>";
          }

          if (c.definitions) {
            c.definitions.forEach(function (d) {
              xml += "<Definition";
              if (_present(d.name, "string"))
                xml += " name=\"" + _escapeString(d.name) + "\"";
              xml += ">";
              if (_present(d.value, "string"))
                xml += _escapeString(d.value);
              xml += "</Definition>";
            });
          }
          xml += "</Constraint>";
        });
      }
      return xml;
    }

    function _getDescriptionXML(nodeName, data) {
      var xml = "<" + nodeName + ">";
      if (_present(data.summary, "string"))
        xml += "<Summary>" + _escapeString(data.summary) + "</Summary>";
      if (data.toolbar)
        xml += _getCommandBarsXML("Toolbar", data.toolbar.commandBars);
      if (data.serviceMenu)
        xml += _getCommandBarsXML("ServiceMenu", data.serviceMenu);
      if (data.apptitlebar)
        xml += _getCommandBarsXML("AppTitleBar", data.apptitlebar);
      if (data.appmenu)
        xml += _getCommandBarsXML("AppMenu", data.appmenu);

      if (data.entities && data.entities.length)
        xml += _getContextDataXML("Context", data.entities);
      if (data.searchTarget)
        xml += _getSearchTargetXML("SearchTarget", data.searchTarget);
      if (data.properties)
        xml += _getPropertiesXML("Properties", data.properties);
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getLogXML(nodeName, data) {
      var xml = "<" + nodeName + ">";
      _req(data, "messages", "array");
      _reqa(data, "messages");
      data.messages.forEach(function (m) {
        _req(m, "text", "string");
        xml += "<Message";
        if (_present(m.severity, "string")) {
          _oneOf(m.severity, ["critical", "high", "warning", "information", "debug"]);
          xml += " severity=\"" + m.severity + "\"";
        }
        if (_present(m.text, "string"))
          xml += " text=\"" + _escapeString(m.text) + "\"";
        if (_present(m.source, "string"))
          xml += " source=\"" + _escapeString(m.source) + "\"";
        xml += " />";
      });
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getClipboardDataXML(nodeName, data) {
      var xml = "<" + nodeName + ">";
      data.entries.forEach(function (e) {
        _req(e, "name", "string");
        xml += "<Entry name=\"" + _escapeString(e.name) + "\">";
        if (_present(e.value, "string"))
          xml += _escapeString(e.value);
        xml += "</Entry>";
      });
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getToastXML(nodeName, data) {
      _req(data, "id", "string");
      var xml = "<" + nodeName + " ID=\"" + _escapeString(data.id) + "\"";
      if (_present(data.value, "string"))
        xml += " value=\"" + _escapeString(data.value) + "\"";
      if (_present(data.duration, "number"))
        xml += " duration=\"" + data.duration + "\"";
      xml += ">";
      _req(data, "title", "string");
      xml += "<Title>" + _escapeString(data.title) + "</Title>";
      if (_present(data.message, "string"))
        xml += "<Message>" + _escapeString(data.message) + "</Message>";
      if (_present(data.icon, "string"))
        xml += "<Icon>" + _escapeString(data.icon) + "</Icon>";
      if (_present(data.iconUrl, "string"))
        xml += "<IconUrl>" + _escapeString(data.iconUrl) + "</IconUrl>";
      xml += "</" + nodeName + ">";
      return xml;
    }

    function _getSessionXML(nodeName, data) {
      _req(data, "version", "string");
      _req(data, "id", "string");
      var r = new RegExp("^[0-9]+\\.[0-9]+\\.[0-9]+$");
      if (!r.test(data.version))
        throw new Error("Invalid version was specified: " + data.version);
      var xml = "<" + nodeName + " version=\"" + data.version + "\" ID=\"" + _escapeString(data.id) + "\"/>";
      return xml;
    }

    function _getStoreDataXML(nodeName, data) {
      var xml = "<" + nodeName;

      if (data.title) {
        xml += " title=\"" + _escapeString(data.title) + "\"";
      }

      if (data.category) {
        xml += " category=\"" + _escapeString(data.category) + "\"";
      }

      if (data.path) {
        xml += " path=\"" + _escapeString(data.path) + "\"";
      }

      xml += ">";
      xml += "<PData>";
      xml += "<SearchTarget xmlns=\"http://www.reuters.com/ns/2005/06/21/ccf\">";

      if (data.constraints && Array.isArray(data.constraints)) {
        data.constraints.forEach(function (constraint) {
          xml += "<Constraint";

          if (constraint.namespace) {
            xml += " namespace=\"" + _escapeString(constraint.namespace) + "\"";
          }

          xml += ">";

          if (constraint.definitions && Array.isArray(constraint.definitions)) {
            constraint.definitions.forEach(function (definition) {
              xml += "<Definition";

              if (definition.viewId) {
                xml += " viewId=\"" + _escapeString(definition.viewId) + "\"";
              }
              xml += ">";

              var _getPropertiesXML = function (properties) {
                var propertiesXML = "";

                if (Array.isArray(properties)) {
                  properties.forEach(function (property) {
                    propertiesXML += "<Property";

                    if (property.name) {
                      propertiesXML += " name=\"" + _escapeString(property.name) + "\"";
                    }

                    propertiesXML += ">";

                    if (property.properties) {
                      propertiesXML += _getPropertiesXML(property.properties);
                    } else if (property.value) {
                      propertiesXML += _escapeString(property.value);
                    }

                    propertiesXML += "</Property>";
                  });
                }

                return propertiesXML;
              };

              if (definition.properties) {
                xml += _getPropertiesXML(definition.properties);
              }

              xml += "</Definition>";
            });
          }

          xml += "</Constraint>";
        });
      }

      xml += "</SearchTarget>";
      xml += "</PData>";
      xml += "</" + nodeName + ">";
      return xml;
    }

    /*
     XML 2 JSON transformation
     */
    function _stringToRootNode(str) {
      var r;
      try {
        r = DOMParser.parseFromString(str, "text/xml");
      } catch (er) {
        throw new Error("invalid xml!");
      }
      return r && r.documentElement ? r.documentElement : null;
    }

    function _loadAttr(node, get, req, object, set) {
      get = Array.isArray(get) ? get : [get]; //force to array
      set = set ? Array.isArray(set) ? set : [set] : get; //if there is no name value to set, use the set name value by default (i.e. no mapping)

      get.forEach(function (g, i) {
        var v = node.getAttribute(g);
        if (v) {
          object[set[i]] = v;
        }
      });
      return object;
    }

    function _getEntityJson(entityNode) {
      var entity = {};
      _loadAttr(entityNode, ["type", "datasource"], false, entity, ["type", "dataSource"]);
      entity.IdentifierList = [];
      entity.FieldList = [];
      entity.ParameterList = [];
      for (var i = 0; i < entityNode.childNodes.length; i++) {
        var node = entityNode.childNodes.item(i),
          item;
        switch (node.tagName) {
          case "Identifier":
            item = {};
            _loadAttr(node, "namespace", true, item);
            entity[item.namespace] = node.text || node.textContent || "";
            entity.IdentifierList.push(item.namespace);
            break;
          case "Field":
            if (entity.fields === undefined || entity.fields === null) {
              entity.fields = {};
            }

            item = {};
            _loadAttr(node, "name", true, item);
            var name = item.name;
            delete item.name;
            item[name] = node.text || node.textContent || "";
            entity.FieldList.push(name);
            switch (name) {
              case "SelectionNS":
              case "NavigationNS":
              case "Transaction":
                entity[name] = item[name];
                break;
              default:
                entity.fields[name] = item[name];
            }
            break;
          case "Parameter":
            if (entity.parameters === undefined || entity.parameters === null) {
              entity.parameters = {};
            }

            item = {};
            _loadAttr(node, "name", true, item);
            var pName = item.name;
            delete item.name;
            item[pName] = node.text || node.textContent || "";
            entity.ParameterList.push(pName);
            entity.parameters[pName] = item[pName];
            break;
          case "Entity":
            if (entity.entities === undefined || entity.entities === null) {
              entity.entities = [];
            }

            entity.entities.push(_getEntityJson(node));
            break;
          default:
            break;
        }
      }
      return entity;
    }

    function _getContextDataJson(root) {
      var data = [];
      for (var i = 0; i < root.childNodes.length; i++) {
        var node = root.childNodes.item(i);
        switch (node.nodeName) {
          case "Entity":
            data.push(_getEntityJson(node));
            break;
        }
      }
      return data;
    }

    function _getCommandDataJson(root) {
      return _loadAttr(root, ["itemID", "value", "ID"], false, {}, ["itemId", "value", "id"]);
    }

    function _getPropertiesJson(root) {
      var data = [];
      if (root.tagName != "Properties")
        throw new Error("Properties node expected!");
      var props = [];
      for (var i = 0; i < root.childNodes.length; i++) {
        var node = root.childNodes.item(i);
        switch (node.nodeName) {
          case "Property":
            var name = node.getAttribute("name"),
              property = _loadAttr(node, ["type", "access"], false, {});
            property[name] = node.text || node.textContent || "";
            props.push(property);
            break;
        }

      }

      if (props.length > 0)
        data = props;
      return data;
    }

    function _getArchiveDataJson(root) {
      var data = _loadAttr(root, ["url", "service", "title", "extension"], false, {}),
        pdatas = [];

      for (var n = 0; n < root.childNodes.length; n++) {
        var node = root.childNodes.item(n);
        switch (node.nodeName) {
          case "Url":
            data.url = node.text || node.textContent || "";
            _loadAttr(node, "cpurl", false, data);
            break;
          case "PData":
            var pdata = _loadAttr(node, "name", false, {});
            var fc = node.firstChild;
            if (pdata.name == "context") {
              if (fc !== null && fc !== undefined && fc.xml) {
                pdata.value = fc.xml;
              } else {
                pdata.value = "";
                var s = new XMLSerializer();
                for (var i = 0; i < node.childNodes.length; i++) {
                  var childNode = node.childNodes.item(i);
                  pdata.value += s.serializeToString(childNode);
                }
              }
            } else {
              if (fc !== null && fc !== undefined) {
                pdata.value = node.text || node.textContent || "";
              } else {
                pdata.value = "";
              }
            }
            pdatas.push(pdata);
            break;
        }
      }
      if (pdatas.length > 0)
        data.data = pdatas;
      return data;
    }

    function _getDescriptionJson(root) {
      var data = _loadAttr(root, ["name", "logLevel", "version", "containerVersion", "productVersionInfo", "userAgent", "guid"], false, {}),
        nodeMap = {
          "Capabilities": function (node, data) {
            for (var c = 0; c < node.childNodes.length; c++) {
              var n = node.childNodes.item(c);
              var capability = _loadAttr(n, ["name", "namespace", "type"], false, {});
              if (_present(capability.type, "string"))
                _oneOf(capability.type, ["SYNC", "ASYNC", "BOTH"]);
              var value = n.text || n.textContent || "";
              if (value.length > 0)
                capability.value = value;
              data.capabilities[capability.name] = capability;
              delete capability.name;
            }
          },
          "JetPluginInfo": function (node, data) {
            var plugin = {};
            _loadAttr(node, "ChannelName", true, plugin, "channel");
            data.plugin = plugin;
          },
          "HttpHandler": function (node, data) {
            var capability = {};
            _loadAttr(node, "url", true, capability, "value");
            data.GUID = capability.value.substring(capability.value.indexOf("id=") + 3);
            capability.value = capability.value.substring(0, capability.value.indexOf("?id="));
            data.capabilities.httpGetData = capability;
          },
          "Properties": function (node, data) {
            data.properties = _getPropertiesJson(node);
          },
          "WindowInfo": function (node, data) {
            var windowInfo = _loadAttr(node, ["windowId", "isFlexViewer"], true, {});
            windowInfo.isFlexViewer = (windowInfo.isFlexViewer === "true");
            windowInfo.windowId = windowInfo.windowId;

            if (windowInfo.windowId) {
              // In EikonDesktop, windowId is wrapped in "{}", remove it.
              if (windowInfo.windowId.charAt(0) === "{") {
                windowInfo.windowId = windowInfo.windowId.replace("{", "");
              }

              if (windowInfo.windowId.charAt(windowInfo.windowId.length - 1) === "}") {
                windowInfo.windowId = windowInfo.windowId.replace("}", "");
              }

              windowInfo.windowId = windowInfo.windowId.toLowerCase();
            }

            data.windowInfo = windowInfo;
          }

        };

      if (data.guid) {
        data.GUID = data.guid.replace(/-/g, "");
        delete data.guid;
      }
      data.capabilities = {};
      for (var i = 0; i < root.childNodes.length; i++) {
        var node = root.childNodes.item(i);
        if (node.nodeType != 3) nodeMap[node.nodeName].call(null, node, data);
      }
      return data;
    }

    function _getUserInfoJson(root) {
      var data = {};
      if (root.tagName != "UserInfo")
        throw new Error("UserInfo node expected!");
      for (var i = 0; i < root.childNodes.length; i++) {
        var node = root.childNodes.item(i);
        // process element nodes
        if (node.nodeType == 1) {
          var value = node.text || node.textContent || "";
          data[node.tagName] = value;
        }
      }
      return data;
    }

    function _getContainerInformationJson(root) {
      var data = {};
      if (root.tagName != "ContainerInfo")
        throw new Error("ContainerInfo node expected!");
      _loadAttr(root, "GUID", true, data);
      _loadAttr(root, "RootPersistencyFolder", true, data);
      return data;
    }

    function _getTransferDataJson(root) {
      var data = {};
      if (root.tagName != "TransferData")
        throw new Error("TransferData node expected!");
      var mouse = {};
      _loadAttr(root, "screenx", false, mouse, "screenX");
      _loadAttr(root, "screeny", false, mouse, "screenY");
      var xPresent = _present(mouse.screenX, "string") && mouse.screenX;
      var yPresent = _present(mouse.screenY, "string") && mouse.screenY;
      if (xPresent || yPresent) {
        if (xPresent) mouse.screenX = JSON.parse(mouse.screenX);
        if (yPresent) mouse.screenY = JSON.parse(mouse.screenY);
        data.mouse = mouse;
      }
      for (var i = 0; i < root.childNodes.length; i++) {
        var node = root.childNodes.item(i);
        switch (node.nodeName) {
          case "Data":
            var dataType = node.getAttribute("type");
            if (dataType == "ContextData" || dataType == "PersistData") {
              for (var _n = 0; _n < node.childNodes.length; _n++) {
                var n = node.childNodes.item(_n);
                switch (n.nodeName) {
                  case "Entities":
                    if (dataType != "ContextData")
                      throw new Error("ContextData data type expected instead of " + dataType);
                    data.entities = _getContextDataJson(n);
                    break;
                  case "PersistData":
                    if (dataType != "PersistData")
                      throw new Error("PersistData data type expected instead of " + dataType);
                    data.archive = _getArchiveDataJson(n);
                    break;
                  //default:
                  //  throw new Error("Unknown data type specified " + data.type);
                }
              }
            }
            break;
          case "Entities":
            data.entities = _getContextDataJson(node);
            break;
        }
      }
      return data;
    }

    function _getBoolean(str) {
      var res = JSON.parse(str);
      if ("boolean" != typeof res)
        throw new Error("Invalid boolean value specified: " + str);
      return res;
    }

    function _getXYJson(str) {
      var data = {};
      var coords = str.split(",");
      var regex = new RegExp("^[0-9]+,[0-9]+$");
      if (!regex.test(str) || coords.length != 2)
        throw new Error("Invalid data format used!");
      data.x = JSON.parse(coords[0]);
      data.y = JSON.parse(coords[1]);
      return data;
    }


    /************************
     End XML Conversion Functions
     ************************/
    return {
      toMap: {
        "onLog": {
          format: function (d) {
            return _getLogXML("Log", d);
          }
        },
        "onContextMenu": {
          format: function (d) {
            return _getEventDataXML("EventData", d);
          }
        },
        "onContextChange": {
          format: function (d) {
            return _getContextDataXML("Entities", d);
          }
        },
        "onContextDragStart": {
          format: function (d) {
            return _getContextDataXML("Entities", d);
          }
        },       // Obsolete
        "onDragStart": {
          format: function (d) {
            return _getTransferDataXML("TransferData", d);
          }
        },
        "onNavigate": {
          format: function (d) {
            return _getNavigationXML("Navigation", d);
          }
        },
        "onCopy": {
          format: function (d) {
            return _getTransferDataXML("TransferData", d);
          }
        },
        "onToast": {
          format: function (d) {
            return _getToastXML("Toast", d);
          }
        },
        "onPropertyChange": {
          format: function (d) {
            return _getPropertiesXML("Properties", d);
          }
        },
        "onLoad": {
          format: function (d) {
            return _getDescriptionXML("Description", d);
          }
        },
        "onUnload": {
          format: function (d) {
            return _getArchiveDataXML("PersistData", d);
          }
        },
        "persistdata": {
          format: function (d) {
            return _getArchiveDataXML("PersistData", d);
          }
        },
        "onUpdateCommandBars": {
          format: function (d) {
            return _getUpdateCommandBarsXML("UpdateCommandBars", d);
          }
        },
        "onCopyToClipboard": {
          format: function (d) {
            return _getClipboardDataXML("ClipboardData", d);
          }
        },
        "onSaveToStore": {
          format: function (d) {
            return _getStoreDataXML("StoreData", d);
          }
        },
        "onResize": {
          format: function (d) {
            return _getLocationXML("Location", d);
          }
        },
        "Session": {
          format: function (d) {
            return _getSessionXML("Session", d);
          }
        },
        "toTransferData": {
          format: function (d) {
            return _getTransferDataXML("TransferData", d);
          }
        }
      },

      fromMap: {
        "onContextChange": {
          format: function (d) {
            return _getContextDataJson(_stringToRootNode(d));
          }
        },
        "onPropertyChange": {
          format: function (d) {
            return _getPropertiesJson(_stringToRootNode(d));
          }
        },
        "onCommand": {
          format: function (d) {
            return _getCommandDataJson(_stringToRootNode(d));
          }
        },
        "ActiveSymbol": {
          format: function (d) {
            return _getContextDataJson(_stringToRootNode(d));
          }
        },
        "Description": {
          format: function (d) {
            return _getDescriptionJson(_stringToRootNode(d));
          }
        },
        "UserInfo": {
          format: function (d) {
            return _getUserInfoJson(_stringToRootNode(d));
          }
        },
        "x,y": {
          format: function (d) {
            return _getXYJson(d);
          }
        },
        "Boolean": {
          format: function (d) {
            return _getBoolean(d);
          }
        },
        "ContainerInformation": {
          format: function (d) {
            return _getContainerInformationJson(_stringToRootNode(d));
          }
        },
        "persistdata": {
          format: function (d) {
            return _getArchiveDataJson(_stringToRootNode(d));
          }
        },
        "fromTransferData": {
          format: function (d) {
            return _getTransferDataJson(_stringToRootNode(d));
          }
        }
      }
    };
  }

  module.exports = DesktopXML;
})();
