(function () {
  'use strict';
  // makes deferred
  module.exports = function (async, cb, err) {
    var dfd = {};
    var promise = new Promise(function (resolve, reject) {
      dfd.resolve = resolve;
      dfd.reject = reject;
    });

    // the async call
    async().then(function (data) {
      if (cb) dfd.resolve(cb(data));
      else dfd.resolve(data);
    }, function (data) {
      if (err) dfd.reject(err(data));
      else dfd.reject(data);
    });

    return promise;
  };
})();