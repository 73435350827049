(function () {
    'use strict';
    var version = require('../package.json').version;
    var router = require('./router');
    var MessageManager = require('./MessageManager');
    var mixin = require('./shared/mixin');
    var logger = require('./UtilLogger');
    var util = require('./Util');
    var properties = require('./JETProperties');
    var dataProviders = require('./JETDataProviders');
    var _container = require('./stubs/container');
    var _top = require('./shared/_top');
    var useTop = false;
    var callbacksExecutor = require('./shared/callbacksExecutor');
    var _topLevel = false; // is this the top level app?
    var JET;
    var mm;
    var api;

    var _onLoadedCallbacks = callbacksExecutor();
    var _onLoadFailedCallbacks = callbacksExecutor();
    var _onUnloadCallbacks = callbacksExecutor();

    //Minimal archive
    var _archiveLoaded = false;
    var _failMessage = '';

    // info level
    logger.setLogOptions(2);

    /**
    * @class JET
    * @namespace JET
    * @description Core JET API Functions
    */
    window.JET = function () {
        var _dataProviders = dataProviders(window.JET);

        api = {
            // return the data providers of the JET instance
            getDataProviders: function () {
                return _dataProviders;
            },

            /**
            * Returns the general device type of the JET container.  This method can be called before JET.init is completed.
            * @function containerType
            * @memberof JET
            * @returns {string} either "desktop", "mobile", or "web"
            */
            containerType: function () {
                //support "desktop", "web", and "mobile"
                var ua = window.navigator.userAgent;

                if (ua.indexOf("EikonViewer") > -1) {
                    return "desktop";
                } else if (ua.indexOf("Mobile") > -1 || ua.indexOf("Mobi/") > -1 || ua.indexOf("Tablet") > -1) {
                    return "mobile";
                } else {
                    return "web";
                }
            },

            /**
            * Extends the JET namespace
            * @function extend
            * @memberof JET
            * @deprecated
            * @param {Number} version  - (version # required) NOT IN USE
            * @param {String} namespace  - the new namespace to create under JET
            * @param {Function} declaration - returns an object with API to add to new JET namespace the function will be
            * passed the JET util object as an argument
            **/
            extend: function (version, namespace, declaration) {
                if (!JET[namespace]) {
                    try {
                        JET[namespace] = declaration(util);
                    } catch (e) {
                        logger.warn("Fail to load plugin: " + namespace + " due to " + e.toString());
                    }
                }
            },

            /**
            * The navigate method is used to open many Eikon Desktop Object by navigating to them with some provided data
            * @function navigate
            * @memberof JET
            * @param {JET~NavigationData} data - An object describing information of object being navigated
            *
            * @example
            * // Open Quote object
            * JET.navigate({
            *   name: "Quote Object",
            *   entities: [{
            *     RIC: "TRI.N"
            *   }],
            *   target: "tab"
            * })
            *
            * @example
            * // Open QuoteList object
            * JET.navigate({
            *   name: "Quote List Object",
            *   entities: [
            *     { RIC: "TRI.N" },
            *     { RIC: "XON.N" },
            *     { RIC: "HPQ.N" }
            *   ],
            *   location: {
            *     x: 10,
            *     y: 10,
            *     width: 300,
            *     height: 300
            *   }
            * })
            */
            navigate: function (data) {
                var name = data && data.name && data.name.toLowerCase();
                if (name === 'news') {
                    data.entities = data.entities || [];
                    data.entities[0] = data.entities[0] || {};
                    // NEWS-13301 - pass current app JET.ID as referrerId for News consumers tracking
                    data.entities[0].referrerId = JET.ID || 'Unknown';
                }

                router.processEvent({
                    name: "onNavigate",
                    data: data
                });
            },

            /**
            * Use for register a callback function to be called as soon as JET is fully loaded
            * @function onLoad
            * @memberof JET
            * @param {function} handler - A callback function to be called as soon as JET is fully loaded
            * @param {array} [plugins]  An array of plugin names.
            * @example
            * JET.onLoad(function () {
            *   console.log("JET is loaded and ready to be used")
            * });
            */
            onLoad: function (handler, plugins) {
                var newHandler = plugins ? function () {
                    //...set onPluginLoaded..
                    if (JET.Plugins.loaded(plugins)) {
                        handler.call(window, _archiveLoaded);
                    } else {
                        JET.onPluginLoaded(function () {
                            if (JET.Plugins.loaded(plugins)) {
                                handler.call(window, _archiveLoaded);
                            }
                        });
                    }
                } : handler;

                if (JET.Loaded) {
                    newHandler.call(window, _archiveLoaded);
                } else {
                    _onLoadedCallbacks.push(newHandler);
                }
            },

            /**
            * Use for register a callback function to be called if JET fails loading
            * @function onLoadFailed
            * @memberof JET
            * @param {function} handler - A callback function to be called if JET fails loading
            * @example
            * JET.onLoadFailed(function (message) {
            *   console.log("JET failed with message :'"+message+"'")
            * });
            */
            onLoadFailed: function (handler) {
                if (JET.LoadFailed) {
                    handler.call(window, _failMessage);
                } else {
                    _onLoadFailedCallbacks.push(handler);
                }
            },

            /**
            * Use for register a callback function to be called as soon as JET is fully unloaded
            * @function onUnload
            * @memberof JET
            * @param {function} onUnloadCallback - A callback function to be called upon completion of JET unloading
            *
            * @example
            * JET.onUnload(function () {
            *   console.log("JET is completely unloaded")
            * });
            */
            onUnload: function (onUnloadCallback) {
                // cannot support because async, the acs will handle unload
                // but can do functions
                _onUnloadCallbacks.push(onUnloadCallback);
            },

            /**
            * Use for register a callback to be notified of any change of context for the web page. Such changes can be
            * either initiated by typing a value in the web page command line; or context can be modified on an
            * Eikon Desktop Object and the web page being notified by Link Mode of the change of context
            * @function onContextChange
            * @memberof JET
            * @param {function} onContextChangeCallback - A callback function to be called every time the web page context is changed
            *
            * @example
            * JET.onContextChange(function (contextData) {
            *   console.log(contextData);
            * })
            */
            onContextChange: function (onContextChangeCallback) {
                router.addEventHandler("onContextChange", onContextChangeCallback, true);
            },

            /**
            * The unload method is used to force unloading of JET integration with the Eikon Desktop.
            * Such process ensures releasing of all involved resources
            * @function unload
            * @memberof JET
            * @example
            *
            * JET.unload();
            */
            unload: function () {
                util.updatePersistData();

                logger.debug('Running onUnloadHandlers');
                _onUnloadCallbacks.run();

                _onUnloadCallbacks = callbacksExecutor();
                _onLoadedCallbacks = callbacksExecutor();
                _onLoadFailedCallbacks = callbacksExecutor();

                util.unsubscribeAll();
                if (_topLevel) {
                    // unsubscribe all
                    // TODO improve do not call top... make ACS manage itself
                    // TODO improve make the router unsubscribe all itself
                    logger.debug('Unregistering ACS');
                    try {
                        _top = (useTop) ? top : window;
                        _top.__unRegisterACS__();
                        delete _top.__initACS__;
                    } catch (e) {
                        // noop - couldnt access top because of cross origin issue
                    }

                }

                // clean up mm for this particular JET app on this window to avoid memory leaks
                if (mm) {
                    logger.debug('Unloading message manager');
                    mm.unload();
                }
                router.setBus(void 0);
                mm = void 0;
                // todo need an initial state
                JET.Loaded = false;
                JET.Initialized = false;
                JET.ContainerDescription = void 0;
                JET.Context = void 0;

                logger.debug('Releasing all event handlers');
                util.unsubscribeAll();
            },

            /**
            * Initialization object for JET.init()
            * @typedef {object} JET~InitObj
            * @property {string} ID - The unique id for your application
            * @property {string} [Title] - It will be used at the application title bar
            * @property {JET~ContextData} [Context] - Default context of the page
            * @property {JET~NavigationSupport} [NavigationSupport] - Navigation support flags
            * @property {Array.<JET~CommandBar>} [AppMenu] - Array of commandBar that will be used for customize the AppMenu
            */

            /**
            * A collection of entities being used as a context
            * @typedef {Array.<JET~Entity>} JET~ContextData
            */

            /**
            * A type which represents a business entity
            * @typedef {object} JET~Entity
            * @property {string} RIC - Reuters Instrument Code e.g. "TRI.N"
            */

            /**
            * The init method is called to initialize JET integration with a JET Container
            * @function init
            * @memberof JET
            * @param {JET~InitObj} initObj - Initialization object
            *
            * @example
            * JET.init({
            *   ID: "JETSample",
            *   Context: [{
            *     RIC: "IBM.N"
            *   }]
            * });
            */
            init: function (initObj) {
                initObj = initObj || {};
                var context = this;
                var shouldWaitForEikonNowReady = waitForEikonNowReady();
                if (shouldWaitForEikonNowReady) {
                    shouldWaitForEikonNowReady.then(function () {
                        jetinit(initObj);
                    });
                } else {
                    jetinit(initObj);
                }
            },

            /**
             * Use to resolve cpurl into a url.
             * @function getUrl
             * @memberof JET
             * @param {string} cpurl - A string with a `cpurl` format, like `cpurl://apps.cp./apps/jetsampleapp`
             * 
             * @example
             * JET.getUrl('cpurl://apps.cp./apps/jetsamplesapp');
             */
            getUrl: function getUrl(cpurl) {
                var href = window.location.href;

                var cpurlRegex = /^(?:cpurl?:\/\/)([\w.-]+)\.cp\.\/([\w\-\._~:\/?#[\]@!\$&'\(\)\*\+,;=.]+)$/;
                var hrefRegex = /^(?:http(?:s?):\/\/)([\w-]+)\.(?:[\w]+)\.cp((?:\.[\w\.-]+)+)\/[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

                var isValidCpurl = cpurlRegex.test(cpurl);

                if (!isValidCpurl) {
                    throw new Error('The cpurl argument does not satisfy the expected format');
                }

                var isValidHref = hrefRegex.test(href);

                if (!isValidHref) {
                    throw new Error('Your window.location.href does not satisfy the expected format.');
                }

                var cpurlExec = cpurlRegex.exec(cpurl);
                var app = cpurlExec[1];
                var path = cpurlExec[2];

                var hrefExec = hrefRegex.exec(href);
                var userDataCenter = hrefExec[1];
                var userConnection = hrefExec[2];

                var urlTemplate = '<protocol>//<userdatacenter>.<app>.cp<userconnectionmode>/<path>';

                var url = urlTemplate
                    .replace('<protocol>', window.location.protocol)
                    .replace('<userdatacenter>', userDataCenter)
                    .replace('<userconnectionmode>', userConnection)
                    .replace('<app>', app)
                    .replace('<path>', path);

                return url;
            }
        };

        return api;
    } ();

    // Called in after initialization
    function _init(initObj) {
        logger.setLogLevel(initObj.LogLevel);

        //defer until all base and core plugins are loaded...
        if (initObj.required && !JET.Plugins.loaded(initObj.required) && !JET.Plugins.loaded()) {
            logger.info("required plugins loading...");
            setTimeout(function () {
                JET.init(initObj);
            }, 10);
            return;
        }

        properties.init(initObj);
        mixin(JET, initObj);

        JET.LoadFailed = false;
        var topUrl = null;
        if (useTop && _top) topUrl = top.location.href;
        JET.PersistURL = JET.PersistURL || topUrl;

        router.addEventHandler("Publish", _publishHandler);

        var registerPromise = router.JETRegisterRequest(initObj);
        return registerPromise.then(function (data) {
            if (!data.subApp) {

                loadBindContainerInformation(data, JET);

                // pass persisted data to JET's onRegister callback
                // pop all messages
                _container.getDataQueue.forEach(function (msg) {
                    router.getContainerData(msg);
                });
                _container.processEventQueue.forEach(function (msg) {
                    router.processEvent(msg);
                });

                logger.debug("JET successfully registered with container finished");
                logger.debug("Requesting archive data from container.");

                //deal with any persist data
                _archiveLoaded = getPersistData(data);
                util.updatePersistData();

                //call JET onLoad (this should only be called once)
                var descData = util.getComponentDescription(JET);
                logger.debug("Sending onLoad. Data:", descData);
                router.processEvent({
                    name: 'onLoad',
                    data: descData
                });

                initializeLoadJET(JET);

                // send data back to ACS for other sub apps to register.
                router._setRegisterData(data);
            }
        }, function (error) {
            _failMessage = 'Could not register JET. Error: "' + error + '"';
            console.log(_failMessage);
            loadFailed(JET, _failMessage);
        });
    }

    function _publishHandler(eventObj) {
        var res = router.callSubscriptions(eventObj, [eventObj.data, eventObj.channel]);
        return "undefined" != typeof res ? res : false;
    }

    function loadBindContainerInformation(data, jet) {
        if (data.containerDescription) {
            // handle container description
            // Decode Eikon Version
            var userAgent = data.containerDescription.userAgent;
            if (userAgent !== undefined) {
                var g = /EIKON([0-9]*)\.([0-9]*)\.([0-9]*),/i.exec(userAgent);
                if (g && g.length == 4) {
                    data.containerDescription.major = parseInt(g[1]);
                    data.containerDescription.minor = parseInt(g[2]);
                    data.containerDescription.build = parseInt(g[3]);
                }
            }
            jet.ContainerDescription = data.containerDescription;
        }

        if (data.ContainerType) jet.ContainerType = data.ContainerType;
    }

    function getPersistData(data) {
        data = data || { persistData: {} };
        data = data.persistData;
        return util.onPersistData(data);
    }

    function initializeLoadJET(jet) {
        jet.Loaded = true;
        jet.Initialized = true;

        if (jet.Context && jet.Context.length) {
            // set context if we have it
            jet.contextChange(jet.Context);
        }

        // call unload when the window unloads
        util.addEvent(window, 'unload', jet.unload.bind(jet));

        var hooks = util.getInternalLoadHooks();
        for (var i = 0; i < hooks.length; i++) {
            hooks[i].apply(jet);
        }

        _onLoadedCallbacks.run(window, _archiveLoaded);
        _onLoadedCallbacks = callbacksExecutor();
    }

    function loadFailed(jet, message) {
        jet.Loaded = false;
        jet.Initialized = false;
        jet.LoadFailed = true;

        _onLoadFailedCallbacks.run(window, message);
        _onLoadFailedCallbacks = callbacksExecutor();
    }


    function waitForEikonNowReady() {
        // isEnabled only return true if init() was called, not desktop, and /web/ in URL
        if ('EikonNow' in window) {
            if (window.EikonNow.isEnabled()) {
                var dfd = {};
                var promise = new Promise(function (resolve, reject) {
                    dfd.resolve = resolve;
                    dfd.reject = reject;
                });

                window.EikonNow.ready(function () {
                    dfd.resolve();
                });

                return promise;
            }
        }
        // either eikonnow is not provided or is it not enabled
        return false;
    }

    function jetinit(initObj) {

        var _jet = JET;

        var containerType = _jet.containerType();
        var container;
        if (containerType === 'desktop') {
            try {
                useTop = true;
                container = window.top;
            } catch (ex) {
                // cors issue
                useTop = false;
                container = window;
            }

        }
        else container = window;

        if (container.__initACS__) {
            var initACS = container.__initACS__;
            new Promise(function (resolve, reject) {
                initACS(resolve, reject);
            }).then(function (response) {

                mm = new MessageManager({
                    sameWindowStrategy: containerType !== 'desktop'
                });

                router.setBus(mm);
                mm.registerNamespace(router);

                if (response.register) {
                    // top level jet app
                    router.ACShandshake().then(function () {
                        _init(initObj);
                    });
                    _topLevel = true;
                } else {
                    // sub app case
                    var rejectCount = 0; // number of failed attempts to register
                    var askForRegData = function () {
                        setTimeout(function () {
                            router.ACSpollForInitData().then(function (data) {
                                logger.debug('JET loading a sub app.');
                                // persist data
                                var persistdata;

                                try {
                                    data = data[0];
                                    persistdata = data.persistData;
                                } catch (e) {
                                    data = {};
                                    persistdata = '';
                                }

                                loadBindContainerInformation(data, _jet);
                                _archiveLoaded = getPersistData(persistdata);
                                router.addEventHandler('Publish', _publishHandler);
                                initializeLoadJET(_jet);

                            }, function (reject) {

                                // ask for data at a later time
                                if (rejectCount < 10) {
                                    setTimeout(function () {
                                        askForRegData();
                                        rejectCount++;
                                    }, 40);
                                } else {
                                    _failMessage = 'Failed to load JET as a sub app. Error: "' + reject + '"';
                                    logger.debug(_failMessage);
                                    loadFailed(_jet, _failMessage);
                                }
                            }, 40);
                        });
                    };

                    askForRegData();
                }
            });
        } else {
            throw new Error('JET could not find the container');
        }
    }

    JET = window.JET;
    JET.version = version;
    module.exports = JET;
})();
