(function () {
  'use strict';
  module.exports = function () {
    var funcs = [];
    var size = 0;

    function reset() {
      funcs = [];
      size = 0;
    }

    return {
      push: function (func) {
        funcs[size] = func;
        size++;
      },

      run: function (context) {
        // take the arguments and apply them to function
        context = context || window;
        var args = Array.prototype.slice.call(arguments, 1);
        for (var i = 0; i < size; i++) {
          var func = funcs[i];
          func.call(context, args);
        }

        reset();
      }
    };
  };
})();