(function () {
  'use strict';

// Handles subscriptions, services, requests, sending, and publishing
  var JET = require('./JETCore');
  var router = require('./router');
  var mixin = require('./shared/mixin');
  var jsonschema = require('jsonschema');
  var handlerId = 1010;

  var pubsub = {
    /**
     * Used for send a message to a given a channel
     * @function send
     * @memberof JET
     * @param {string} [target] - a predefined name or the GUID of the target app
     * @param {string} channel - The name of a channel which messages will be sent to
     * @param {string} data - The message to send.
     *
     * @example
     * JET.send("!data", "/timeseries", JSON.stringify({ ric : "GOOG.O" }));
     *
     * @example
     * JET.send("/timeseries", JSON.stringify({ ric : "IBM" }))
     */
    send: function (target, channel, data) {
      if (arguments.length === 2) {
        // the first argument will be the channel and the second will be the data
        router.publishWithPipeTarget(arguments[0], arguments[1]);
      } else {
        router.publishWithPipeTarget(channel, data, target);
      }
    },

    /**
     * A subscription returned from JET.subscription
     * @function subscription
     * @memberof JET
     * @param {string} [target] - a predefined name or the GUID of the target app
     * @param {string} channel - The name of a channel which messages will be sent to
     * @param {object} data - The message to send or the context
     *
     * @example
     * var sub = JET.subscription("!data", "/timeseries", {ric: "GOOG.O"});
     * sub.onUpdate(updateHandler);
     */
    subscription: function (target, channel, data) {
      var subId = handlerId++ + Date.now();  // need to revisit

      // If there is no target, it will function like subsribe, but using eikonpipe
      if (arguments.length === 2) {
        var channelArg = arguments[0];
        var dataArg = arguments[1];
        return {
          id: subId,
          onUpdate: function (handler) {
            router.subscriptionWithPipeTarget({
              channel: channelArg,
              context: dataArg,
              handler: handler,
              pipe: true
            });
            return this;
          }
        };
      }

      // todo potentially a set guid fn
      var guid = JET.ContainerDescription.GUID;
      var subscribeChannel = channel + '/' + guid + ':' + subId;
      var errorChannel = subscribeChannel + '/error';

      return {
        id: subId,
        onUpdate: function (handler) {
          router.subscriptionWithPipeTarget({
            channel: subscribeChannel,
            data: data,
            target: target,
            handler: handler,
            requestSubscribeWithPipe: {
              channel: channel + '/onSubscribe',
              pipe: true
            }
          });

          return this;
        },

        onError: function (handler) {
          router.subscriptionWithPipeTarget({
            channel: errorChannel,
            data: data,
            target: target,
            handler: handler
          });
          return this;
        },

        unsubscribe: function () {
          // unsubscribe data channel
          router.unsubscribeChannel({channel: subscribeChannel});

          // unsubscribe error channel too
          router.unsubscribeChannel({channel: errorChannel});

          // tell service module to unsubscribe the data channel
          var subData = {
            channel: subscribeChannel,
            target: target,
            data: data
          };
          router.processEvent({
            name: 'Publish',
            data: JSON.stringify(subData),
            target: target,
            channel: channel + '/onUnsubscribe',
            pipe: true
          });

          return this;
        }
      };
    },


    /**
     * A service returned from JET.service
     * @function service
     * @memberof JET
     * @param {string} channel - The name of a channel which messages will be sent to
     *
     * @example
     * var service = JET.service("/timeseries");
     * service.onSubscribe(function(sub){// do something});
     *
     */
    service: function (channel) {
      var requestChannel = channel + '/onRequest';
      var subsribeChannel = channel + '/onSubscribe';
      var unSubsribeChannel = channel + '/onUnsubscribe';
      var onsub = router.subscriptionWithPipeTarget({channel: subsribeChannel});
      var onunsub = router.subscriptionWithPipeTarget({channel: unSubsribeChannel});
      var onreq = router.subscriptionWithPipeTarget({channel: requestChannel});

      var service = {
        onSubscribe: function (handler) {
          var original = handler;
          var that = this;

          handler = function () {
            var sub = JSON.parse(arguments[0]);
            return original.apply(this, arguments);
          };

          router.addSubscriptionHandler({
            channel: subsribeChannel,
            handler: handler
          });
          return service;
        },

        onRequest: function (handler) {
          router.addSubscriptionHandler({
            channel: requestChannel,
            handler: handler
          });
          return service;
        },

        onUnsubscribe: function (handler) { // unsubscribe data channel
          router.addSubscriptionHandler({
            channel: unSubsribeChannel,
            handler: handler
          });

          return service;
        },

        unsubscribe: function () { // unsubscribe service channel
          onsub.unsubscribe();
          onunsub.unsubscribe();
          onreq.unsubscribe();
        }
      };
      return service;
    },


    /**
     * Request data from service module
     * @function request
     * @memberof JET
     * @param {string} target - a predefined name or the GUID of the target app
     * @param {string} channel - The name of a channel which messages will be sent to
     * @param {object} data - The message to send.
     * @return {Promise}  Promise object accepting resolve and reject callbacks
     * @example
     * var aPromise = JET.request("!data", "/timeseries", {ric: "GOOG.O"});
     * aPromise.then(resolve, reject);
     */
    request: function (target, channel, data) {
      // todo set guid on export?
      var guid = JET.ContainerDescription.GUID;
      var subId = handlerId++ + Date.now();
      var subscribeChannel = channel + '/' + guid + ':' + subId;
      var errorChannel = subscribeChannel + '/error';
      var dfd;
      var prom = new Promise(function (res, rej) {
        dfd = {
          resolve: res,
          reject: rej
        };
      });

      var sub = router.subscriptionWithPipeTarget({
        channel: subscribeChannel,
        data: data,
        target: target,
        handler: function (value) {
          router.unsubscribeChannel({channel: subscribeChannel});
          router.unsubscribeChannel({channel: errorChannel});
          dfd.resolve(value);
        },
        requestSubscribeWithPipe: {
          channel: channel + '/onRequest',
          pipe: true
        }
      });

      var err = router.subscriptionWithPipeTarget({
        channel: errorChannel,
        target: target,
        handler: function (value) {
          router.unsubscribeChannel({channel: subscribeChannel});
          router.unsubscribeChannel({channel: errorChannel});
          dfd.reject(value);
        }
      });

      return prom;
    },

    /**
     * Request a service based on a service name
     * @function Data
     * @memberof JET
     * @param {string} serviceName - a service name
     * @return {Promise}  Promise object accepting resolve and reject callbacks
     * @example
     * var aPromise = JET.Data("Symbology");
     * aPromise.then(resolve, reject);
     */
    Data: function (serviceName) {
      var dfd;
      var prom = new Promise(function (res, rej) {
        dfd = {
          resolve: res,
          reject: rej
        };
      });

      JET.request("!data", "/data-service/info", {name: serviceName}).then(function (desc) {
        // serialize response
        var descriptor = JSON.parse(desc);
        if (descriptor.error) {
          dfd.reject(descriptor.error);
        }
        if (descriptor) {
          var service = {};
          if (descriptor.verbs && descriptor.verbs.indexOf("request") > -1) {
            service.request = function (requestObj) {
              // validate request object against schema
              var isValidRequest = JET.validateJSON(requestObj, descriptor.schema);
              if (!isValidRequest) {
                return Promise.reject(new Error("Invalid request"));
              }
              else {
                return JET.request("!data", "/" + serviceName, requestObj);
              }
            };
          }
          if (descriptor.verbs && descriptor.verbs.indexOf("subscribe") > -1) {
            service.subscribe = function (requestObj) {
              // validate request object against schema
              var isValidRequest = JET.validateJSON(requestObj, descriptor.schema);
              if (!isValidRequest) {
                return Promise.reject(new Error("Invalid request"));
              }
              else {
                return JET.subscription("!data", "/" + serviceName, requestObj);
              }
            };
          }
          dfd.resolve(service);
        }
        else {
          dfd.reject("Description object not returned");
        }
      }, function (err) {
        dfd.reject("couldn't access " + serviceName + " service");
      });
      return prom;
    },

    /**
     * Used for validate json schema for request object
     * @function validateJSON
     * @memberof JET
     * @param {object} request - data request object
     * @param {object} schema - schema object
     */
    validateJSON: function (request, schema) {
      var Validator = jsonschema.Validator;
      var v = new Validator();
      var result = v.validate(request, schema);
      if (result.errors && result.errors.length > 0) {
        console.log(result.errors);
        return false;
      }
      else
        return true;
    },

    /**
     * Used for publish a message to a given a channel
     * @function publish
     * @memberof JET
     * @param {string} channel - The name of a channel which messages will be published to
     * @param {string} data - The message to publish.
     *
     * @example
     * JET.publish('/country/city', 'Paris');
     */
    publish: function (channel, data) {
      router.publish(channel, data);
    },


    /**
     * A callback function to be called when there is a publish on subscribed channel
     * @callback JET~publishCallback
     * @param {string} message - The message that is published
     * @param {string} channel - The channel that is published
     */

    /**
     * A subscription returned from JET.subscribe();
     * @typedef {object} JET~Subscription
     * @property {function} unsubscribe - Used for stop listening to a given channel
     */

    /**
     * Used for subscribe to all communications published onto a given channel
     * @function subscribe
     * @memberof JET
     * @param {string} channel - The name of a channel to subscribe to all published communications.
     * @param {JET~publishCallback} handler - a callback function to be called when there is a publish on subscribed channel
     * @param {object} context - A context to be passed as the scope of the callback function
     * @return {JET~Subscription} returns a subscription
     *
     * @example
     * function publishCallback (message, channel) {
    *   // Access context via this
    *   var cities = this.cities;
    * }
     *
     * // Subscribe
     * var subscription = JET.subscribe("/country/city", publishCallback, {cities: ['Bangkok', 'London', 'Paris']});
     *
     * // Unsubscribe
     * $("#unsubscribe").click(function() {
    *   subscription.unsubscribe();
    * });
     */
    subscribe: function (channel, handler, context) {
      var options = {
        channel: channel,
        handler: handler,
        context: context
      };
      return router.subscribe(options);
    },


    /**
     * Used for cancel all existing subscriptions
     * @function unsubscribeAll
     * @memberof JET
     * @example
     * JET.unsubscribeAll();
     */
    unsubscribeAll: function () {
      router.unsubscribeAll();
    },


    /**
     * Used for cancel all subscriptions on a specific channel
     * @function unsubscribe
     * @memberof JET
     * @param {string} channel - The name of a channel to unsubscribe from.
     * @example
     * JET.unsubscribe("/myChannel");
     */
    unsubscribe: function (channelName) {
      return router.unsubscribeChannel({channel: channelName});
    }

  };

  mixin(JET, pubsub);
})();