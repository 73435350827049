(function () {
  'use strict';
  var _JET = require('./JETCore');
  var _router = require('./router');
  var logger = require('./UtilLogger');
  var util = require('./Util');
  var mixin = require('./shared/mixin');
  var loaded = false;
  var hash = {};
  var boundObjects = require('./shared/boundObjects');

  var archiveFunctions = {
    put: function (name, value) {
      var index, item;

      if (!hash.hasOwnProperty(name)) {
        index = _getNonHashItemIndex(name);
        if (index >= 0) {
          item = _JET.Archive[index];
          if (item && item.value !== value) {
            item.value = value;
            update();

            return true;
          }
        }
      }

      if (hash[name] !== value) {
        hash[name] = value;
        update();

        return true;
      }

      return false;
    },

    get: function (name, def) {
      if (!hash.hasOwnProperty(name)) {
        return _getNonHashValue(name, def);
      }

      return hash[name] || def;
    },

    clear: function () {
      hash = {};
      while (_JET.Archive.length) {
        _JET.Archive.shift();
      }
      update();
    },

    getAllKeys: function () {
      var hashKeys = Object.keys(hash);
      var nonHashKeys = _getNonHashKeys();

      return hashKeys.concat(nonHashKeys.filter(function (key) {
        return hashKeys.indexOf(key) < 0;
      }));
    },

    save: function () {
      _getArchiveData().then(function (data) {
        _router.processEvent({
          name: 'persistdata',
          data: data
        });
      });
    }
  };

  function _getNonHashKeys() {
    return (_JET.Archive || []).map(function (data) {
        return data.name;
    });
  }

  function _getNonHashValue(name, def) {
    var index = _getNonHashItemIndex(name);

    return index >= 0 ? (_JET.Archive[index] && _JET.Archive[index].value || def) : def;
  }

  function _getNonHashItemIndex(name) {
    var length = _JET.Archive && _JET.Archive.length;

    if (!length) {
        return -1;
    }

    for (var i = 0; i < length; i++) {
        if (_JET.Archive[i].name === name) {
            return i;
        }
    }

    return -1;
  }

  function update() {
    _JET.Archive.save();
  }

  function getPersistCpURL(url, cpUrl) {
    return url.replace(/^(?:[^:\/\\?#]+:(?=\/\/))?(?:\/\/)?(?:[^:\/\\?#]*(?::[^\/\\?#]*)?)?/, "cpurl://" + cpUrl);
  }

  function bindArchiveToJET() {
    _JET.Archive = _JET.Archive || [];
    var archiveKeys = Object.keys(archiveFunctions);
    var numberKeys = archiveKeys.length;
    for (var i = 0; i < numberKeys; i++) {
      var key = archiveKeys[i];
      _JET.Archive[key] = archiveFunctions[key];
    }
  }

  function onPersistData(persist) {
    var data;
    logger.debug('Received archive data: ', persist);

    if (persist && persist.data) {
      if (!Array.isArray(persist.data)) {
        boundObjects.persistdata = persist.data;
        data = persist.data.data;
      } else {
        boundObjects.persistdata = persist;
        data = persist.data;
      }
    }

    if (data) {
      loaded = true;
      _JET.Archive = [];
      for (var i = 0; i < data.length; ++i) {
        var _item = data[i];
        if (_item.name === '_archiveHash') {
          try {
            hash = JSON.parse(_item.value);
          } catch (e) {
            logger.debug('Received bad _archiveHash data: ', _item.value);
          }
        } else {
          _JET.Archive.push({
            name: _item.name,
            value: _item.value
          });
        }
      }
    }
  }

  function _getArchiveData() {
    var dfd;
    var promise = new Promise(function (resolve, reject) {
      dfd = {resolve: resolve};
    });
    var url = _JET.PersistURL || (_JET.containerType() === 'desktop' && _top ? top.location.href : undefined);
    var data = {
      data: []
    };

    if (url !== null) {
      data.url = url;
    }

    if (_JET.SAP) {
      data.cpurl = getPersistCpURL(url, _JET.SAP);
    }

    if (_JET.Archive) {
      data.data = [];
      for (var i = 0; i < _JET.Archive.length; ++i)
        data.data.push({
          name: _JET.Archive[i].name,
          value: _JET.Archive[i].value
        });

      if (hash !== null) {
        data.data.push({
          name: '_archiveHash',
          value: JSON.stringify(hash)
        });
      }
    }
    //to do, remove this "PData" wrapper once fix is done on container side!
    if (_JET.Context && _JET.Context.length > 0) {
      _router.toContainerAsync('ContextData', _JET.Context)
        .then(function (value) {
          data.data.push({
            name: 'context',
            value: value
          });
          boundObjects.persistdata = data;
          dfd.resolve(data);
        });
    } else {
      boundObjects.persistdata = data;
      dfd.resolve(data);
    }

    return promise;
  }

  mixin(util, {
    onPersistData: function (persist) {
      onPersistData(persist);
      bindArchiveToJET();
      return loaded;
    },

    getArchiveData: function () {
      return _getArchiveData();
    },

    updatePersistData: function () {
      return update();
    }
  });
})();
