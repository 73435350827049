(function () {
  'use strict';
  var _router = require('./router');
  var _JET = require('./JETCore');
  var mixin = require('./shared/mixin');
  var logger = require('./UtilLogger');
  var _loggingSource;
  var names = [
    'debug',
    'information',
    'warning',
    'high',
    'critical'
  ];

  _loggingSource = _JET.ID || '';

  names.forEach(function (name) {
    _JET[name] = function (message) {
      // the log function is injected by api later in this file
      _JET.log({
        messages: [{
          severity: name,
          text: message,
          source: _loggingSource
        }]
      });
    };
  });


  var api = {
    /**
     * Log message to container with "debug" severity
     * @function debug
     * @memberof JET
     * @param {string} message - A message
     *
     * @example
     * JET.debug("Debug message");
     */

    /**
     * Log message to container with "information" severity
     * @function information
     * @memberof JET
     * @param {string} message - A message
     *
     * @example
     * JET.information("Information message");
     */

    /**
     * Log message to container with "warning" severity
     * @function warning
     * @memberof JET
     * @param {string} message - A message
     *
     * @example
     * JET.warning("Warning message");
     */

    /**
     * Log message to container with "high" severity
     * @function high
     * @memberof JET
     * @param {string} message - A message
     *
     * @example
     * JET.high("High message");
     */

    /**
     * Log message to container with "critical" severity
     * @function critical
     * @memberof JET
     * @param {string} message - A message
     *
     * @example
     * JET.critical("Critical message");
     */

    /**
     * Directs the JET Container to put the information provided in its log.
     * @function log
     * @memberof JET
     * @private
     * @param {Object} Log data
     */
    log: function (data) {
      logger.debug('JET.log called. Data: ', data);
      _router.processEvent({
        name: 'onLog',
        data: data
      });
    },


    /**
     * Sets level of severity to log.
     * @function loggingOptions
     * @memberof JET
     * @param {number} level
     * 0 - error & warning & info & debug & trace <br>
     * 1 - error & warning & info & debug <br>
     * 2 - error & warning & info <br>
     * 3 - error & warning <br>
     * 4 - error
     * @param {*} logger - an object contains error, warning, info, debug, and trace function calls (same interfaces as console).
     *
     * @example
     * JET.loggingOption(4, console);
     */
    loggingOptions: function (logLevel, alogger) {
      // todo investigate, the api never seemed to support this
      logger.setLogOptions(logLevel);
    },


    /**
     *  Sets logging source string.
     * @function loggingSource
     * @memberof JET
     * @param {string} source - A source token to be appeared in Eikon logging source tag.
     *
     * @example
     * JET.loggingSource('APPLICATION A LOG');
     */
    loggingSource: function (source) {
      _loggingSource = source;
    }
  };

  mixin(_JET, api);
})();