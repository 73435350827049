(function () {
  'use strict';
  function toObj(props) {
    if (!Array.isArray(props)) return props;

    var r = {};

    props.forEach(function (p) {
      if (p.name) r[p.name] = p;
    });

    return r;
  }

  module.exports = function (a, b) {
    //ensure a and b are objects
    //merge a and b, guaranteeing values such as type and access
    a = toObj(a);
    b = toObj(b);

    for (var key in b) {

      var prop = b[key];
      var n = null;
      var v = null;

      if (prop && prop.name) {
        n = prop.name;
        v = prop.text || prop.value || '';
      } else {
        for (var field in prop) {
          if (prop.hasOwnProperty(field)) {
            n = field;
            v = prop[field] || '';
            break;
          }
        }
      }

      if (n !== null && v !== null) {
        a[n] = {
          value: v,
          type: prop.type || 'xs:string',
          access: prop.access === 'ro' ? 'ro' : 'rw'
        };
      }
    }
    return a;
  };
})();