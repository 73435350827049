(function () {
  'use strict';
  module.exports = function PromiseHandler(name) {
    var _promiseIndex = 1;
    var _name = name;
    var _promises = {};
    var _deferreds = {};

    return {
      dispose: function () {
        _promises = null;
        _deferreds = null;
      },

      makePromise: function () {
        var index = _promiseIndex++ + '_' + _name;
        _promises[index] = new Promise(function (resolve, reject) {
          _deferreds[index] = {
            resolve: resolve,
            reject: reject
          };
        });

        return index;
      },

      getDeferred: function (id) {
        return _deferreds[id];
      },

      getPromise: function (id) {
        return _promises[id];
      },

      cleanPromise: function (id, type) {
        _deferreds[id] = type;
        _promises[id] = type;
      },

      resolvePromise: function (id, params) {
        var promise = this.getDeferred(id);
        if (promise && promise.resolve) {
          promise.resolve(params);
          this.cleanPromise(id, 'resolved');
        }
      },
      rejectPromise: function (id, params) {
        var promise = this.getDeferred(id);
        if (promise && promise.reject) {
          promise.reject(params);
          this.cleanPromise(id, 'rejected');
        }
      }
    };
  };
})();