(function () {
  'use strict';
  // these objects send messages to the ACS when they are changed.
  var boundProp = require('./boundProp');
  var DATAPROVIDERS = 'dataProviders';
  var spyOn = {};

  boundProp(spyOn, DATAPROVIDERS, 'helpurl');
  boundProp(spyOn, DATAPROVIDERS, 'trainingurl');
  boundProp(spyOn, DATAPROVIDERS, 'context');
  boundProp(spyOn, DATAPROVIDERS, 'properties');
  boundProp(spyOn, DATAPROVIDERS, 'persistdata');
  boundProp(spyOn, DATAPROVIDERS, 'description');
  boundProp(spyOn, DATAPROVIDERS, 'inputowner', false);

  module.exports = spyOn;
})();