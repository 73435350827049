(function () {
  'use strict';
  // gets a reference to top level
  var useFrame = false;

  try {
    if (window.top) useFrame = true;
  } catch (e) {
    useFrame = false;
  }

  module.exports = useFrame;
})();
