(function () {
  'use strict';
  var util = require('./Util');
  var _JET = require('./JETCore');
  var logger = require('./UtilLogger');
  var _router = require('./router');
  var mixin = require('./shared/mixin');


// Drag and Drop must use e.preventDefault or it will not work as expected in the container, specifically the icon and
// to let users know they can drop RICS
  var _dataRequestId = 0;
  var _enterLeaveCounter = 0;
  var NO_DRAG_STARTED = 'noDragStarted';
  var DATA_AVAILABLE = 'dataAvailable';
  var NO_VALID_DRAG_DATA = 'noValidDragData';
  var PENDING = 'pending';
  var GET_DROP_DATA_FAILED = 'getDropDataFailed';
  var _dropData = null;
  var _dndState = NO_DRAG_STARTED;
  var _dropEffect = 'copy';
  var _dndManager = {
    setDropData: function (data) {
      _dropData = data;
      _dndState = DATA_AVAILABLE;
    }
  };

  try {
    window.top.setDragData = function (data) {
      _router.getFromContainer('fromTransferData', data).then(function (dropData) {
        _dropData = dropData.data;
        _dndState = DATA_AVAILABLE;
      });
    };
  } catch (e) {
    logger.warn('setDragData cannot access top frame.');
  }

// todo these could be cleaned up otherwise multiple JET inits could bind this logic to the window more than once?
  util.addEvent(document, 'dragstart', function (e) {
    // If drop is started inside the window, jetComponent should call _manager.setDropData()
    // and pass drop data from DOM node (if it is not done this already)

    // DnD state is set to "noValidDragData" and will remain in this state until it will be set by the
    // jetComponent (if there is some JET info in the node dragged)
    // or it will remain in this state till the end of the drag operation
    if (_dndState !== DATA_AVAILABLE) _dndState = NO_VALID_DRAG_DATA;
  });

  util.addEvent(document, 'dragenter', function (e) {
    if (_dndState === NO_DRAG_STARTED) {

      // If we don't have drop data already, then
      // drag'n'drop is started outside of the window.
      // Send async request to container to obtain the drop data.
      _dndState = PENDING;

      // Requests counter is used to avoid race conditions
      // (when response received from a previous request)
      var dataRequestID = _getDataRequestId();
      var requestID = dataRequestID;
      var coords = getCoords(e);

      if (window.externalHost !== null) {
        getDropData(function (data) {
            if (_dndState === PENDING && requestID === dataRequestID) {
              _dndManager.setDropData(data);
              _dndState = DATA_AVAILABLE;

              // Actual drag enter is fired here, after some delay caused by async operation
              fireJETDragEnter(data, coords);
            }
          }, function (error) {
            logger.error("Error getting drop data: " + error);
            _dndState = GET_DROP_DATA_FAILED;
          },
          getCoords(e)
        );
      }
    } else if (_dndState === DATA_AVAILABLE && _enterLeaveCounter === 0) {
      // Drag'n'drop is started inside the window.
      // The drop data is already set by the jetComponent.
      // Fire dragEnter immidiately (but only once)
      fireJETDragEnter(_dropData, getCoords(e));
    }

    if (_enterLeaveCounter === 0) callDragEnter(getCoords(e));

    _enterLeaveCounter++;

    if (_dndState === DATA_AVAILABLE && (e.dataTransfer || e.fakeDataTransfer)) {
      (e.dataTransfer || e.fakeDataTransfer).dropEffect = _dropEffect;
    } else if (e.dataTransfer || e.fakeDataTransfer) {
      (e.dataTransfer || e.fakeDataTransfer).dropEffect = "none";
    }

    e.preventDefault();
  });

  util.addEvent(document, 'dragleave', function (e) {
    _enterLeaveCounter--;
    if (_enterLeaveCounter === 0) finishDrag(true, e);
    e.preventDefault();
  });

  util.addEvent(document, 'drop', function (e) {
    _enterLeaveCounter = 0;

    if (_dndState === DATA_AVAILABLE) {
      fireJETDrop(_dropData, getCoords(e));
    }

    finishDrag(false, e);
    e.preventDefault();
  });

  util.addEvent(document, 'dragover', function (e) {
    if (_dndState === DATA_AVAILABLE) {
      if (e.dataTransfer || e.fakeDataTransfer) {
        (e.dataTransfer || e.fakeDataTransfer).dropEffect = _dropEffect;
      }
      fireJETDragOver(_dropData, getCoords(e));
    } else {
      (e.dataTransfer || e.fakeDataTransfer).dropEffect = 'none';
    }
    e.preventDefault();
  });

  function getCoords(e) {
    var sx = e.screenX;
    var sy = e.screenY;

    return {
      clientX: e.clientX,
      clientY: e.clientY,
      screenX: sx,
      screenY: sy,
      eventData: sx + ',' + sy,
      srcEvent: e
    };
  }

  function finishDrag(dropAborted, e) {
    var coords = getCoords(e);

    if (dropAborted && _dndState == DATA_AVAILABLE) {
      fireJETDragLeave();
      callDragLeave(coords);
    }

    if (!dropAborted) {
      callResetDragDrop(coords);
    } else {
      callCancelDragDrop(coords);
    }

    _dropData = null;
    _dndState = NO_DRAG_STARTED;
  }


  function _getDataRequestId() {
    var id = _dataRequestId;
    _dataRequestId++;
    return id;
  }


  function getDropData(callback, errorCallback, coords) {
    _router.getContainerData({
      name: 'getDropData',
      xmlData: coords.eventData
    }).then(function (data) {
      if (typeof(data) !== 'undefined' && data !== null) {
        callback(data);
      } else {
        errorCallback('null or undefined received');
      }
    });
  }


// event handlers to fire jet events
  function fireJETDragOver(dropData, coords) {
    _callEvent({
      name: 'onDragOver',
      data: {
        x: coords.clientX,
        y: coords.clientY,
        e: coords.srcEvent,
        dropData: dropData
      }
    });
  }

// todo: do we need dropData here?
  function fireJETDragLeave(dropData) {
    _callEvent({
      name: 'onDragLeave',
      data: null
    });
  }

  function fireJETContextChanged(dropData) {
    _callEvent({
      name: 'onContextChange',
      data: dropData
    });
  }

  function fireJETDrop(dropData, coords) {
    dropData.mouse = coords;
    _callEvent({
      name: 'onDrop',
      data: dropData
    });

    fireJETContextChanged(dropData);
  }

  function fireJETDragEnter(dropData, coords) {
    dropData.mouse = coords;
    _callEvent({
      name: 'onDragEnter',
      data: dropData
    });
  }

  function _callEvent(obj) {
    _router.callEventHandler(obj);
  }


// calls
// todo instead of going through jet call the router
  function callResetDragDrop() {
    if (window.externalHost !== null) _router.getContainerData({
      name: 'resetDragDrop',
      xmlData: null,
      async: function () {
      }
    });
  }

  function callDragEnter(coords) {
    if (window.externalHost !== null) _router.getContainerData({
      name: 'windowDragEnter',
      xmlData: coords.eventData,
      async: function () {
      }
    });
  }

  function callDragLeave(coords) {
    if (window.externalHost !== null) _router.getContainerData({
      name: 'windowDragLeave',
      xmlData: coords.eventData,
      async: function () {
      }
    });
  }

  function callCancelDragDrop(coords) {
    if (window.externalHost !== null) _router.getContainerData({
      name: 'cancelDragDrop',
      xmlData: coords.eventData,
      async: function () {
      }
    });
  }

  function _isContainerVersionEqualOrGreater(major, minor, build) {
    if (!JET.ContainerDescription) return false;
    if (JET.ContainerDescription.major < major) return false;
    if (JET.ContainerDescription.major > major) return true;
    if (JET.ContainerDescription.minor < minor) return false;
    if (JET.ContainerDescription.minor > minor) return true;
    if (JET.ContainerDescription.build < build) return false;
    if (JET.ContainerDescription.build > build) return true;
    return true;
  }

  var api = {
    /**
     * The dragStart method allows a web page to initialize data to be dropped to another Eikon Desktop object
     * @function dragStart
     * @memberof JET
     * @param {JET~TransferData} data - Drag/Drop data
     *
     * @example
     * $("#button1").on("dragstart", onDragStart);
     *
     * function onDragStart(e) {
     *   JET.dragStart({
     *     entities: [{
     *       RIC: "EUR="
     *     }]
     *   })
     * }
     */
    dragStart: function (data) {
      if (_dndManager) _dndManager.setDropData(data);

      if (_isContainerVersionEqualOrGreater(9, 0, 38000)) {
        if (data !== null) {
          _router.processEvent({
            name: "onDragStart",
            data: data
          });
          util.stopBubbling();
          return true;
        }
      } else {        //bug with onDragStart/transferdata right now, hack the data structure and send onContextDragStart instead
        var context = null;
        if (data.entities && data.entities.length) {
          context = data.entities;
        }
        if (context !== null) {
          _router.processEvent({
            name: "onContextDragStart",
            data: context
          });
          util.stopBubbling();
          return true;
        }
      }
      return false;
    }
  };

  mixin(_JET, api);
})();