(function () {
  'use strict';
  // Manages plugins
  var util = require('./util');
  var JET = require('./JETCore');
  var mixin = require('./shared/mixin');
//list of loaded plugins
  var loaded = [];
// track plugins in flight
  var inflight = [];
// track plugins required before JET load
  var core = [];
//list of plugins required for init
  var required = [];
  var registrationQueue = [];
  var onPluginLoadedCallbacks = [];
  var window = window || {};

  var api = {
    /**
     *  Applies a plugin to the JET global.
     * @function plugin
     * @memberof JET
     * @param {function} plug  The plug function will return the plugin as an object.  The plug function is passed the private JET util object.  The return object for the plugin may include any (or none) of these properties:
     name - used to track the load state of the plugin.  Note, if name is not defined, plugin cannot be required for startup.
     toContainer (mixin for jetToContainer) - to extend handlers for events to the container
     fromContainer (extend jetFromContainer handlers)
     containerEvents - array of event names to add hooks for handlers to.  e.g. containerEvents:["onContextChange"] will set up a handler for the 'onContextChange' event from the container and wire up an 'onContextChange' method in the JET namespace that will allow the adding of handlers for this event.
     api (extend the JET public API)
     util (extends the util functions)
     **/
    plugin: function (plug) {
      var r = typeof plug === 'function' ? plug(util) : {};

      if (r.name) {
        var name = r.name.toLowerCase();
        if (loaded.indexOf(name) === -1) loaded.push(name);
        else throw new Error('Tried to a load a plugin with the same name: ', r.name);

        //remove plugin from inflight list
        inflight = inflight.filter(function (d) {
          return d.toLowerCase() !== name;
        });
      }

      mixin(util, r.util);
      mixin(JET, r.api);
      // TODO: this is not called during init... is it still necessary?
      if (r.register) registrationQueue.push(r.register);
      //extend container event handlers?
      if (r.containerEvents) util.addHandlers(r.containerEvents, JET);

      onPluginLoadedCallbacks.forEach(function (f) {
        f.call(window, r.name);
      });
    },

    /**
     * @function onPluginLoaded
     * @memberof JET
     * @param {Function} handler  - function to be called when a plugin is loaded
     **/
    onPluginLoaded: function (handler) {
      onPluginLoadedCallbacks.push(handler);
    },

    /**
     * Utility functions to manage plugins to JET API.
     * @memberof JET
     * @namespace Plugins
     **/
    Plugins: {
      /**
       * Returns either list of loaded plugins, or, if a list is passed to it, a boolean.
       * @function loaded
       * @memberof JET.Plugins
       * @param {array} [list]  A list of plugin names.  If specified, will return a bolean indicating if all plugins on the list are loaded.
       * @returns {array}
       * @returns {boolean}
       **/
      loaded: function (list) {
        //is everything on the list loaded?
        if (list) {
          return list.every(function (p) {
            return loaded.indexOf(p.toLowerCase()) > -1;
          });
        } else {
          //or return the loaded list...
          return loaded;
        }
      },

      /**
       * Indicates if any plugins are inflight.
       * @function inflight
       * @memberof JET.Plugins
       * @param {boolean} [core]   If true, will indicate only if core plugins are inflight.
       * @returns {boolean}
       **/
      inflight: function (core_plugin) {
        if (!inflight || inflight.length < 1) {
          //if nothing is inflight, return false
          return false;
        } else if (core_plugin) {
          //else, if core is true, see if anything inflight is not defer
          return inflight.some(function (d) {
            if (core.indexOf(d) > -1) {
              return true;
            }
          });
        } else {
          //else, return true
          return true;
        }
      },

      /**
       * Loads a specified plugin, if not yet provided
       * @function require
       * @memberof JET.Plugins
       * @param {string} name  Name of plugin to require.
       * @param {string} [src] Source URL to fetch plugin from.
       * @param {boolean} [defer]  Flag indicating that the plugin can be loaded anytime.  Only applied if plugin is NOT required before the JET load event has fired.
       **/
      require: function (name, src, defer) {
        if (!defer && name) {
          //add to list of core plugins...
          if (core.indexOf(name) === -1) core.push(name);
        }
        if (this.loaded([name]) || inflight.indexOf(name) > -1) {
        } else {
          inflight.push(name);
          var scriptElement = document.createElement("script");
          scriptElement.setAttribute("src", src);
          document.querySelector("head").appendChild(scriptElement);
        }
      }
    }
  };

  mixin(JET, api);
})();