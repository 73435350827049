(function () {
  'use strict';
  var util = require('./Util');
  var mixin = require('./shared/mixin');
  var _JET = require('./JETCore');
  var logger = require('./UtilLogger');
  var _router = require('./router');
  var mergeProps = require('./shared/mergeprops');
  var _properties = {};

// override processevent on the router
  if (!_router._peoverride) {
    var oldPE = _router.processEvent.bind(_router);

    _router.processEvent = function (msg) {
      _addEventCommandHandlers(msg);
      oldPE(msg);
    };
    _router._peoverride = true;
  } else {
    _router.processEvent(msg);
  }

// menu control
  function getLinkContextForNode(targetNode) {
    var res = {};
    if (targetNode) {
      var n = targetNode;
      if (n.nodeName == 'IMG') {
        res.imgUrl = n.src;
      }

      while (n) {
        if (n.nodeName === 'A') {
          res.aUrl = n.href;
          break;
        }
        var parent = n.parentNode;
        if (parent && n != parent && !parent.documentElement) {
          n = parent;
        } else break;
      }
    }

    return res;
  }


  function getNodeForAnEvent(ev) {
    var n = null;
    if (!ev) ev = window.event;
    if (!ev) return null;
    if (ev.target) n = ev.target;
    else if (ev.srcElement) n = ev.srcElement;
    if (n.nodeType == 3) { // defeat Safari bug
      n = n.parentNode;
    }

    return n;
  }


// command bars

  function _modifyCommandBarItems(items) {
    if (Array.isArray(items)) {
      items.forEach(function (item, j) {
        //???
        if (item.type && !item.item) {
          item.item = item.type;
          item.type = undefined;
          //?
          try {
            delete item.type;
          } catch (e) {
          }
        }

        if (item.item === "Header" && item.caption) {
          if (items[j + 1]) {
            items[j + 1].beginGroup = true;
            items[j + 1].groupName = item.caption;
          }
        }

        if (item.captionType) {
          item.type = item.captionType;
          item.captionType = undefined;

          try {
            delete item.captionType;
          } catch (e) {
          }
        }

        if (item.items) {
          _modifyCommandBarItems(item.items);
        }
      });
    }
  }

  function _modifyCommandBars(bars) {
    if (bars) {
      // Support the old format of Toolbar object for a case of Enable Command Line
      if (bars.commandBars && Array.isArray(bars.commandBars)) {
        bars = bars.commandBars;
      }

      if (Array.isArray(bars)) {
        bars.forEach(function (commandBar) {
          if (commandBar.items) {
            _modifyCommandBarItems(commandBar.items);
          }
        });
      }

      return bars;
    }
  }

  function _updateCommandBars(data) {
    logger.debug('JET.updateCommandBars called. Data: ', data);
    data.commandBars = _modifyCommandBars(data.commandBars);

    _router.processEvent({
      name: 'onUpdateCommandBars',
      data: data
    });
  }


  var _commandHandlers = {};
  var _addCommandHandler = function (id, handler) {
    _commandHandlers[id] = handler;
  };

  function _onCommand(data) {
    var h = _commandHandlers[data.id];
    if (h) {
      if (typeof(h) === 'string') {
        // todo not sure if eval still exists
        h = util.eval(h);
      }

      // _onCommand is shared between Toasting and onButtonClicked features.
      // For Toasting, data.value is available
      // For onButtonClicked, data.value is not available. So we will return data object that contain button's id
      h.call(window, (data && data.value ? data.value : data));
    }
  }

  //parse event data structure for handlers.
  function _addItemHandlers(it) {
    if (it.item) {
      switch (it.item) {
        case "SplitButton":
        case "Menu":
          if (it.items) {
            var len = it.items.length;
            for (var i = 0; i < len; i++)
              _addItemHandlers(it.items[i]);
          }
          return;
        case "Print":
          if (it.handler)
            _addCommandHandler("PRINT", it.handler);
          return;
      }
    }

    if (typeof(it.id) != "undefined" && it.handler) {
      _addCommandHandler(it.id, it.handler);
    }
  }

  function _addBarHandlers(commandBars) {
    if (commandBars) {
      var numCommandBars = commandBars.length;
      for (var i = 0; i < numCommandBars; i++) {
        var b = commandBars[i];
        if (b.items) {
          var numItems = b.items.length;
          for (var n = 0; n < numItems; n++) {
            _addItemHandlers(b.items[n]);
          }
        }
      }
    }
  }


  function _addEventCommandHandlers(data) {
    switch (data.name) {
      case "onContextMenu":
        if (data.data.menu)
          _addBarHandlers(data.data.menu);
        break;
      case "onToast":
        _addItemHandlers(data.data);
        break;
      case "onLoad":
        if (data.data.toolbar)
          _addBarHandlers(data.data.toolbar);
        if (data.data.serviceMenu)
          _addBarHandlers(data.data.serviceMenu);
        if (data.data.apptitlebar)
          _addBarHandlers(data.data.apptitlebar);
        if (data.data.appmenu)
          _addBarHandlers(data.data.appmenu);
        break;
      case "onUpdateCommandBars":
        if (data.data.commandBars) {
          _addBarHandlers(data.data.commandBars);
        }
        break;
    }
    return data;
  }


  function isFlagOn(value, flag) {
    return ((value & flag) !== 0);
  }


  function formatNavigationFlag(prop) {
    var flag = prop.NavigationSupport.value;
    if (typeof flag !== 'string') {
      if (!isFlagOn(flag, api.NavigationSupport.CanPublishContext) && !isFlagOn(flag, api.NavigationSupport.CanReceiveContext)) {
        //Set special flag to Disable Color Icon if the app doesn't support both
        //Publbishing and Receiving
        flag |= 128;
      }
      prop.NavigationSupport.value = flag.toString();
    }
  }

  _JET.onLoad(function () {
    _router.addEventHandler('onCommand', _onCommand, true);
  });

  var containerEvents = [
    'onSendObject',
    'onPropertyChange',
    'onCommand',
    'onDragEnter',
    'onDragOver',
    'onDragLeave',
    'onDrop',
    'onPaste',
    'onActivate',
    'onDeactivate'
  ];
  util.addHandlers(containerEvents, _JET);

  var api = {
    /**
     * Use for update the AppMenu on runtime
     * @function updateAppMenu
     * @memberof JET
     * @param {Array.<JET~CommandBar>} menu - Array of commandBar that will be used for customize the AppMenu
     * @example
     * JET.updateAppMenu([{
    *   items: [
    *     // Add custom menu and sub-menu
    *     {
    *       type: "Menu",
    *       caption: "CustomMenu1",
    *       id: "CustomMenu1",
    *       checked: false,
    *       enabled: true,
    *       items: [
    *         {
    *           type: "Button",
    *           caption: "SubMenu1.1 (checked)",
    *           id: "SubMenu11",
    *           enabled: true,
    *           checked: true,
    *           handler: onButtonClicked
    *         },
    *         {
    *           type: "Button",
    *           caption: "SubMenu1.2 (enabled)",
    *           id: "SubMenu12",
    *           enabled: true,
    *           checked: false,
    *           handler: onButtonClicked
    *         },
    *         {
    *           type: "Button",
    *           caption: "SubMenu1.3 (disabled)",
    *           id: "SubMenu13",
    *           enabled: false,
    *           checked: false,
    *           handler: onButtonClicked
    *         }
    *       ]
    *     },
    *     {
    *       type: "Button",
    *       caption: "CustomMenu2",
    *       id: "CustomMenu2",
    *       enabled: true,
    *       checked: false,
    *       handler: onButtonClicked
    *     },
    *
    *     // Hiding system menu (Back & Forward)
    *     {type: "System", id: "Back", visible: false},
    *     {type: "System", id: "Forward", visible: false}
    *   ]
    * }])
     */
    updateAppMenu: function (menu) {
      if (menu) {
        var data = {
          commandBarType: 'AppMenu',
          commandBars: menu
        };

        _updateCommandBars(data);
      }
    },


    /**
     * The contextChange method is used to notify Eikon Desktop of a change of context. This can be used to inform the system that the web page context switched to a different company. As an effect, any subsequently opened Eikon Desktop Object will use that context as its default. This can also be used with Eikon Desktop Link Mode to ensure that linked Eikon Desktop Object switch to the same company(context)
     * @function contextChange
     * @memberof JET
     * @param {JET~ContextData} data - A collection of entity that will be used as a context
     *
     * @example
     * JET.contextChange([{
     *   RIC: "TRI.N"
     * }])
     */
    contextChange: function (data) {
      _router.processEvent({
        name: 'onContextChange',
        data: data
      });
    },


    /**
     * @typedef {object} JET~Property
     * @property {string} name  Name of property
     * @property {string} value Value of property
     * @property {string} type  Type of property (defaults to xs:string)
     * @property {string} access Can be "rw" for read/write or "ro" for read only.
     */

    /**
     * Gets the value for an App property exposed to the JET Container
     * @function getProperty
     * @memberof JET
     * @param {string} name Name of property to retrieve
     * @return {JET~Property} JET property object
     */
    getProperty: function (name) {
      return _properties[name];
    },


    /**
     * Sets multiple App properties to be exposed to the JET Container
     * @function setProperties
     * @memberof JET
     * @param {Array} Collection of JET~Property objects.
     */
    setProperties: function (properties) {
      _properties = mergeProps(_properties, properties);
      _router.processEvent({
        name: 'onPropertyChange',
        data: _properties
      });
    },


    NavigationSupport: {
      //Show Related/Trade menu in Toolbar
      ShowRelatedTradeInToolbar: 1,

      //Show Related/Trade menu in Right Click menu
      ShowRelatedTradeOnRightClick: 2,

      //App has the ‘read’ capabilities (can accept the linked in context from another app)
      CanPublishContext: 32,

      //App has the ‘write’ capabilities (can send out its context to another app)
      CanReceiveContext: 64
    },


    /**
     * The menu item
     * @typedef {object} JET~CommandBarItem
     * @property {string} type - Type of item ["Header", "Menu", "Button", "Separator", "System"]
     * @property {string} caption - Caption of item
     * @property {string} id - Unique id of item.
     * @property {boolean} checked - Specify whether to display checked sign at the front of item
     * @property {boolean} enabled - Specify whether to enable or disable the item
     * @property {boolean} [multiSelect] - For Button menu item only, specify item to make multiselect choise
     * @property {function} handler - A callback function to be called when item is clicked
     * @property {string} iconURL - A path of icon to be displayed on item
     * @property {boolean} visible - Specify whether to display the item (use with "System" item)
     */

    /**
     * The object that contains list of menu item
     * @typedef {object} JET~CommandBar
     * @property {Array.<JET~CommandBarItem>} items - Array of CommandBarItem
     */

    /**
     * The parameters object
     * @typedef {object} JET~ParamsData
     * @property {boolean} [noSystemMenu] - Parameter to hide default System menu
     */

    /**
     * Data used with JET.contextMenu()
     * @typedef {object} JET~ContextMenuData
     * @property {JET~MouseData} mouse - Mouse data
     * @property {JET~ParamsData} [params] - Additional params
     * @property {Array.<JET~CommandBar>} menu - Array of commandBar that will be used for customize the contextual menu
     * @property {JET~ContextData} entities - A collection of entity that will be used in context menu
     */

    /**
     * Display context menu that allows users to navigate to other objects via Related & Trade with the passed RIC
     * @function contextMenu
     * @memberof JET
     * @param {JET~ContextMenuData} data - ContextMenu data
     *
     * @example
     * $("#ric").bind("contextmenu", function (e) {
     *   JET.contextMenu({
     *     mouse: {
     *       screenX: e.screenX,
     *       screenY: e.screenY
     *     },
     *     menu: [{
     *       items: [
     *         // Add custom menu and sub-menu
     *         {
     *           type: "Menu",
     *           caption: "CustomMenu1",
     *           id: "CustomMenu1",
     *           checked: false,
     *           enabled: true,
     *           items: [
     *             {
     *               type: "Button",
     *               caption: "SubMenu1.1 (checked)",
     *               id: "SubMenu11",
     *               enabled: true,
     *               checked: true,
     *               handler: onButtonClicked
     *             },
     *             {
     *               type: "Button",
     *               caption: "SubMenu1.2 (enabled)",
     *               id: "SubMenu12",
     *               enabled: true,
     *               checked: false,
     *               handler: onButtonClicked
     *             },
     *             {
     *               type: "Button",
     *               caption: "SubMenu1.3 (disabled)",
     *               id: "SubMenu13",
     *               enabled: false,
     *               checked: false,
     *               handler: onButtonClicked
     *             }
     *           ]
     *         },
     *         {
     *           type: "Button",
     *           caption: "CustomMenu2",
     *           id: "CustomMenu2",
     *           enabled: true,
     *           checked: false,
     *           handler: onButtonClicked
     *         },
     *
     *         // Hiding system menu (Back & Forward)
     *         {type: "System", id: "Back", visible: false},
     *         {type: "System", id: "Forward", visible: false}
     *       ]
     *     }],
     *     entities: [{
     *       RIC: "EUR="
     *     }]
     *   }
     */
    contextMenu: function (data) {
      var res = getLinkContextForNode(getNodeForAnEvent());
      mixin(data, res);
      logger.debug("JET.contextMenu called. Data: ", data);

      if (data.menu) data.menu = _modifyCommandBars(data.menu);

      if (data.mouse) {
        // probably a user event
        if (data.mouse.type) {
          var temp = data.mouse;

          delete data.mouse;
          data.mouse = {
            screenX: temp.screenX,
            screenY: temp.screenY,
            clientX: temp.clientX,
            clientY: temp.clientY
          };
        }
      }

      _router.processEvent({
        name: 'onContextMenu',
        data: data
      });
      util.stopBubbling();
    },

    /**
     * Clipboard entry
     * @typedef {object} JET~ClipboardEntry
     * @property {string} name - Type of data e.g. "TEXT"
     * @property {string} value - Value
     */

    /**
     * Clipboard data
     * @typedef {object} JET~ClipboardData
     * @property {Array.<JET~ClipboardEntry>} entries - An array of clipboard entry
     */

    /**
     * The copyToClipboard method allows a web page to copy data onto the system clipboard to be able to paste it to either Eikon Desktop objects, or to other applications on the user’s system
     * @function copyToClipboard
     * @memberof JET
     * @param {JET~ClipboardData} data - Clipboard data
     *
     * @example
     * JET.copyToClipboard({
     *   entries: [{
     *     name: "TEXT",
     *     value: "Reuters"
     *   }]
     * })
     */
    copyToClipboard: function (data) {
      logger.debug("JET.copyToClipboard called. Data: ", data);
      _router.processEvent({
        name: 'onCopyToClipboard',
        data: data
      });
    },


    /**
     * Toast data
     * @typedef {object} JET~ToastData
     * @property {string} id - Toast's id
     * @property {string} title - Toast's title
     * @property {string} message - Toast's message
     * @property {string} value - A returned value once users click at a toast message
     * @property {number} duration - A duration to display a toast message (In millisecond)
     * @property {string} iconUrl - Url of icon to be displayed on toast message
     * @property {function} handler - A callback function to be called when toast message is clicked
     */

    /**
     * Display 'toast' messages.  These messages will appear as pop-up messages within the JET Container, clicking on the message will provide a callback to the JET App.
     * @function toast
     * @memberof JET
     * @param {JET~ToastData} data - Toast data
     *
     * @example
     * JET.toast({
     *   id: "MyToast",
     *   title: "Toast title",
     *   message: "Toast message",
     *   value: "Reuters",           // A returned value once users click at a toast message
     *   duration: 1000,             // In millisecond
     *   iconUrl: "https://pbs.twimg.com/profile_images/1844071328/tr_logo_twitter_wht_reasonably_small.png",
     *   handler: onToastClicked
     *   })
     *
     * function onToastClicked(value) {
     *   // do something
     * }
     */
    toast: function (data) {
      _router.processEvent({
        name: 'onToast',
        data: data
      });
    },


    /**
     * Used for open a Screen Capture dialog to capture a screenshot
     * @function screenshot
     * @memberof JET
     * @example
     * JET.screenshot();
     */
    screenshot: function () {
      logger.debug("JET.screenshot called.");
      _JET.navigate({
        url: 'lac://TR.Screenshot.PROGID',
        name: 'Eikon Library Object'
      });
      util.stopBubbling();
    },


    /**
     * An email's attachment.
     * @typedef {object} JET~MailAttachment
     * @property {string} Type - File type
     * @property {string} Filename - File name
     * @property {string} content - Content
     */

    /**
     * An email's content using with JET.sendByMail() method.
     * @typedef {object} JET~MailContent
     * @property {string} to - An email address of recipient
     * @property {string} subject - Subject of an email
     * @property {string} body - Boy od an email
     * @property {Array.<object>} attachments - Attachments
     */

    /**
     * Used for open a new email dialog with a snapshot of the JET App
     * @function sendByMail
     * @memberof JET
     * @param {JET~MailContent} data - A content of email
     *
     * @example
     * var data = JSON.stringify({
     *   to: "JET@thomsonreuters.com",
     *   subject: "Send to email via JET",
     *   body: "This is an example of sending an email via JET.",
     *   attachments: [{
     *     Type: "base64",
     *     FileName: "JET.txt",
     *     content: window.btoa("Text in file.")
     *   }]
     * });
     *
     * JET.sendByMail(data);
     */
    sendByMail: function (data) {
      logger.debug("JET.sendByMail called.");
      _router.processEvent({
        name: 'onSendByMail',
        data: data
      });
    }
  };
  mixin(_JET, api);

  var fullAPIUtil = {
    //get description - used in onLoad event
    getComponentDescription: function () {
      var data = {
        properties: util.properties(),
        entities: _JET.Context || []
      };
      formatNavigationFlag(data.properties);

      if (util.commandline()) {
        data.toolbar = {
          commandBars: [{
            items: [{
              item: "Search"
            }]
          }]
        };
      }

      if (util.apptitlebar()) {
        data.apptitlebar = _modifyCommandBars(util.apptitlebar());
      }

      if (util.appmenu()) {
        data.appmenu = _modifyCommandBars(util.appmenu());
      }

      return data;
    }
  };
  mixin(util, fullAPIUtil);
})();