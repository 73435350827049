(function () {
  'use strict';
  // takes in a window and helps manages
  module.exports = function (aWindow, forcedSameWindowStrategy) {

    var uaString = setUAString(aWindow);
    // helper class to detect the top most frame and if its cross origin
    var _top = void 0; // var to mitigate cross origin frame issues
    var _inframe = void 0; // will be mutated by inFrame function

    // set if the top should be referenced or the current window
    inFrame();
    setFrame(_inframe);

    // if the ref. to frame should be top level or the current frame
    function setFrame(inIframe) {
      _top = (inIframe) ? aWindow.top : aWindow;
      return _top;
    }

    function inFrame() {
      var result;



      if (isDesktop(uaString) && !forcedSameWindowStrategy) {
        try {
          result = aWindow.self !== aWindow.top;
        } catch (e) {
          // most likely cross origin issue or ACS is really in the same window
          result = false;
        }
      }
      else { // don't use _top in EikonWeb
        result = false;
      }

      _inframe = result;
    }

    return {
      setOnFrame: function (key, value, force) {
        if (!_top[key] || force) {
          _top[key] = value;
        }
      },

      getFrame: function () {
        return _top;
      },

      getWindow: function () {
        return aWindow;
      },

      // returns true if this is in the desktop and in an iframe
      // will always return false on web
      inFrame: function () {
        return _inframe;
      },

      isDesktop: function () {
        return isDesktop(uaString);
      }
    };
  };

  function setUAString(aWindow) {
    var uaString;

    try {
      uaString = aWindow.navigator.userAgent;
    } catch (e) {
      uaString = '???';
    }

    return uaString;
  }

  function isDesktop(str) {
    return str.indexOf('EikonViewer') > -1;
  }
})();
