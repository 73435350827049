(function () {
  'use strict';
  var boundObjects = require('./shared/boundObjects');
  var util = require('./Util');

  var dataProviders = function (JET) {
    return {
      // returns current values (Properties) of all the Component properties
      properties: function () {
        return boundObjects.properties;
      },
      // returns a copy of Component description (Description)
      description: function () {
        // cannot call the bound obj because this property refreshes the boundObject property
        return util.getComponentDescription(JET);
      },
      // returns a copy of the components current state (ArchiveData)
      persistdata: function () {
        return util.getArchiveData();
      },
      // returns a copy of the help URL that was provided in the HelpURL property
      helpurl: function () {
        return boundObjects.helpurl;
      },
      // returns a copy of the training URL that was provided in the TrainingURL property
      trainingurl: function () {
        return boundObjects.trainingurl;
      },
      // Returns a copy of the current context (JET.ContextData) of the Component to the Container
      context: function () {
        return boundObjects.context;
      },
      // returns who owns of user input - either component or container
      inputowner: function () {
        var inputowner = JET.IsInputOwner ? 'component' : 'container';
        boundObjects.inputowner = inputowner;
        return inputowner;
      }
    };
  };

  module.exports = dataProviders;
})();