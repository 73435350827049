(function () {
  'use strict';
  if (!window.Promise) {
    window.Promise = require('promise-polyfill');
  }

  var aWindow = getWindow();
  // same window strategy can be forced by the app, it's a blocker to have for News (see http://jirag.int.thomsonreuters.com/browse/NEWS-12188)
  var forcedSameWindowStrategy = !!aWindow.__jetForceSameWindowStrategy__;
  var MessageManager = require('./MessageManager');
  var FrameManager = require('./frameManager');
// detect if we are in a Node.js runtime environment, if so add the appropriate shim for testing
  var frameManager = FrameManager(aWindow, forcedSameWindowStrategy);
  frameManager.getFrame();
  var ACS = require('./AppContainerShim');
  var ACSFactory = require('./AppContainerShimFactory');

  ACSFactory({
    MessageManager: MessageManager,
    frameManager: frameManager,
    ACS: ACS,
    aWindow: aWindow,
    eikonNow: aWindow.EikonNow,
    forcedSameWindowStrategy: forcedSameWindowStrategy
  });

  function isNode() {
    return typeof process === 'object' && process + '' === '[object process]';
  }

  function getWindow() {
    if (isNode()) return global.aWindow;
    else return window;
  }
})();
